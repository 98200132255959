// JsFromRoutes CacheKey 986b9a5c4145e83903a37a273f987738
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/photo_contest",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/photo_contest",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/photo_contest/new",
  ),
  edit: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/photo_contest/:id/edit",
  ),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/photo_contest/:id"),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/photo_contest/:id",
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/photo_contest/:id",
  ),
};
