import Card from "@components/ui/Card";
import { useUser } from "@contexts/UserContext";
import { cn } from "@utils/mergeClassNames";
import React from "react";

import { FontPreview } from "./FontPreview";

export const ProgramPreview = ({
  primaryStyle,
  secondaryStyle,
  borderRadiusClass,
  tileBorderRadiusClass,
  inputBorderRadiusClass,
  headerData,
  heroData,
  footerData,
}) => {
  const { user } = useUser();

  return (
    <div className="flex justify-center">
      <div className="bg-background overflow-hidden w-full max-w-sm shadow-lg h-fit rounded-sm border border-muted">
        <div
          className="px-2 py-4 flex items-center justify-between"
          style={{
            backgroundColor: headerData.backgroundColor,
            color: headerData.fontColor,
          }}
        >
          <img className="size-7" src={headerData.logo} />
          <FontPreview
            font={headerData.font}
            label={`Hi! ${user.first_name}`}
          />
        </div>
        <div className="relative">
          <img
            className="w-full"
            src={heroData.background}
            alt="Hero Background"
          />
          <div
            className="absolute inset-0 flex flex-col items-center justify-center px-2"
            style={{
              color: heroData.fontColor,
            }}
          >
            <div className="text-xs text-center">{heroData.title}</div>
            <div className="text-[8px] text-center">{heroData.description}</div>
            <button
              className={cn(
                "mt-2 text-center focus-visible:outline-offset-2 disabled:opacity-40 py-2 px-4",
                borderRadiusClass,
              )}
              style={{
                ...primaryStyle,
              }}
            >
              Get started
            </button>
          </div>
        </div>
        <div className="text-center mt-4">
          <h2 className="text-lg font-bold mb-1">Become a member</h2>
          <p className="text-sm px-4 text-muted-foreground">
            With more ways to unlock exciting perks, this is your all access
            pass to exclusive rewards.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full px-3 py-2 gap-4">
          <Card.Root
            className={cn(
              "bg-gray-50 dark:bg-black shadow-2xl border max-w-none mb-16 mt-3",
              tileBorderRadiusClass,
            )}
          >
            <Card.Header className="gap-1 p-2 space-y-0">
              <h2 className="text-xl text-center font-bold text-foreground">
                Join Now!
              </h2>
              <p className="text-sm text-muted-foreground px-4 mb-2">
                and stay up to date with all offers.
              </p>
            </Card.Header>
            <Card.Content className="px-3 flex gap-2 items-center justify-center">
              <input
                className={cn(
                  "focus-visible:ring-ring flex h-10 border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-2 w-full",
                  inputBorderRadiusClass,
                )}
                placeholder="Enter your name"
              ></input>
              <button
                className={cn(
                  "text-center focus-visible:outline-offset-2 disabled:opacity-40 py-2 px-4",
                  borderRadiusClass,
                )}
                style={primaryStyle}
              >
                Join
              </button>
            </Card.Content>
          </Card.Root>
        </div>

        <div
          className="p-2"
          style={{
            backgroundColor: footerData.backgroundColor,
            color: footerData.fontColor,
          }}
        >
          <FontPreview font={footerData.font} label="Learn more" />
        </div>
      </div>
    </div>
  );
};
