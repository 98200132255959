import { client } from "@utils/api-client";

export const attachmentFetcher = async (
  url,
  { arg }: { arg: { formData: FormData; method: string } },
) => {
  const { formData, method } = arg;

  const data = await client(url, { body: formData, method });
  return data;
};
