import {
  Button,
  Separator,
  Spinner,
  SubmitButton,
  toast,
} from "@bleu.builders/ui";
import Link from "@components/Link";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/Accordion";
import Card from "@components/ui/Card";
import { WysiwygText } from "@components/WysiwygText";
import { formatDate } from "@utils/formatDate";
import React from "react";

import { CloseButton } from "../challenges/components/CloseButton";
import { titleForReward } from "../home/RewardSection";
import { Reward } from "../types";
import { RewardDate } from "./RewardDate";

export function OffersReward({
  data,
  handleSubmit,
}: {
  data: { reward: Reward };
  handleSubmit: (customData?: FormData) => void;
}) {
  const { reward } = data;

  const hasDetails =
    reward.description_details ||
    reward.terms_conditions ||
    reward?.participant_sweepstakes_entry > 0;
  const earnedOn = reward.earned_by_current_participant_on;

  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards">
      <Card.Header>
        <div className="flex justify-between items-center">
          <span className="text-xl font-bold text-perk-primary uppercase">
            {titleForReward(reward?.selection)} Reward
          </span>
          <div className="justify-self-end">
            <CloseButton />
          </div>
        </div>
        {earnedOn && (
          <span className="text-center text-2xl">
            Congrats &#128591; You&apos;ve earned:
          </span>
        )}
        <span className="text-center font-bold text-3xl">{reward.name}</span>
      </Card.Header>
      <Card.Content className="flex flex-col gap-y-6 px-6">
        {earnedOn && reward.redemption_details && (
          <WysiwygText text={reward.redemption_details} />
        )}

        <div className="flex justify-center">
          <img
            src={reward?.image?.public_url}
            alt={reward.name}
            className="size-1/2"
          />
        </div>
        <p className="text-center text-lg">{reward.description}</p>
        {hasDetails && (
          <div className="bg-border rounded-lg flex flex-col items-center px-6 py-3 gap-y-3">
            <span className="text-xl font-bold">Details</span>
            <Separator className="bg-black" />
            {reward?.description_details && (
              <WysiwygText text={reward.description_details} />
            )}
            {reward?.terms_conditions && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger className="hover:no-underline">
                    Terms and Conditions
                  </AccordionTrigger>
                  <AccordionContent>{reward.terms_conditions}</AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        )}
      </Card.Content>
      <Card.Footer className="flex flex-col gap-y-2 w-full justify-between px-6 items-center mt-2 md:flex-row">
        {reward?.selection === "offer" && earnedOn && (
          <Link to="/my-offers">
            <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button w-fit py-3 px-6 focus:outline-none">
              See my offers
            </Button>
          </Link>
        )}
        {reward?.selection === "offer" && !earnedOn && (
          <SubmitButton
            onClick={() => {
              handleSubmit();
            }}
            className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button w-fit py-3 px-6 focus:outline-none"
          >
            Become a member
          </SubmitButton>
        )}
        <div></div>
        <RewardDate earnedOn={earnedOn} endDate={reward.finish} />
      </Card.Footer>
    </Card.Root>
  );
}
