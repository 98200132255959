import React from "react";

const ErrorBoundary = () => {
  return (
    <div className="mt-20 mb-10 pt-10 min-h-[calc(100vh-80px-40px-95px)] flex flex-col items-center justify-center">
      <div className="max-w-lg text-center">
        <div className="bg-red-200 p-2 rounded-full inline-flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-8 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v4m0 4h.01M21.73 18l-8-14a2 2 0 00-3.48 0l-8 14A2 2 0 004 21h16a2 2 0 001.73-3z"
            />
          </svg>
        </div>
        <h1 className="text-3xl font-bold mt-8 mb-4">
          Oops! Something went wrong.
        </h1>
        <div className="text-gray-600 space-y-4">
          <p>
            We're sorry, but the page you were trying to access is currently
            unavailable.
          </p>
          <p>
            We've already been notified and are working on resolving the issue.
          </p>
          <p>
            Please try again later. If this issue persists, contact our team for
            assistance.
          </p>
        </div>
        <a
          href="/"
          className="mt-6 inline-flex items-center justify-center px-6 py-3 bg-perk-primary text-gray-900 rounded-lg text-sm font-medium transition duration-200"
        >
          Go to Homepage
        </a>
        <p className="mt-4">
          Need help? Contact Us at{" "}
          <a href="mailto:support@perksocial.com" className="text-perk-primary">
            support@perksocial.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default ErrorBoundary;
