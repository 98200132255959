import { Form } from "@bleu.builders/ui";
import { cn } from "@utils/mergeClassNames";
import React from "react";
import { useWizard } from "react-use-wizard";

export const WizardFormWrapper = ({
  children,
  steps,
  form,
  onStepChange = (step) => {},
}) => {
  const { goToStep, activeStep } = useWizard();

  const handleGoToStep = (step) => {
    onStepChange(step);
    goToStep(step);
  };

  return (
    <div className="my-10 flex w-full rounded-md border dark:border-2 shadow-md">
      <div className="w-1/5">
        <div className="flex flex-col gap-2">
          {steps.map((step, idx) => (
            <button
              className={cn("p-4", { "bg-muted": idx === activeStep })}
              key={idx}
              onClick={() => handleGoToStep(idx)}
            >
              <div className="flex flex-col items-start justify-start">
                <h1 className="text-sm font-semibold text-foreground">
                  {idx + 1}. {step.title}
                </h1>
                <span className="text-start text-xs text-muted-foreground">
                  {step.description}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className="w-4/5 pr-2">
        <Form {...form} className="size-full" action="/" method="post">
          {children}
        </Form>
      </div>
    </div>
  );
};
