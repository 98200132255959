import { adminV2Aptos, adminV2Programs, adminV2Rewards } from "@api/index";
import { Button, TaggablePopover } from "@bleu.builders/ui";
import Link from "@components/Link";
import Card from "@components/ui/Card";
import { SimpleKpiCard } from "@components/ui/SimpleKpiCard";
import { useFormActionToast } from "@hooks/useFormActionToast";
import { useSendActionData } from "@hooks/useSendActionData";
import {
  AddressElement,
  DateElement,
  InfoList,
  LinkElement,
} from "@pages/admin/(components)/DetailsCardElements";
import { cn } from "@utils/mergeClassNames";
import toTitleCase from "@utils/toTitleCase";
import React from "react";
import {
  json,
  Outlet,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { rewardType } from "../(utils)/rewardTypes";
import { NftCreationDialog } from "./(components)/NftCreationDialog";
import { SyncWinnersDialog } from "./(components)/SyncWinnersDialog";

const paths = ({ reward_id, reward_type, nft_collection }) => {
  const basePaths = [
    {
      name: "Users",
      path: `/admin/v2/rewards/${reward_id}`,
    },
    {
      name: "Coupons",
      path: `/admin/v2/rewards/${reward_id}/coupons`,
    },
  ];

  if (nft_collection) {
    basePaths.push({
      name: "Blockchain Transactions",
      path: `/admin/v2/rewards/${reward_id}/blockchain_transactions`,
    });
  }

  if (reward_type === "sweepstake") {
    basePaths.push({
      name: "Sweepstakes",
      path: `/admin/v2/rewards/${reward_id}/sweepstakes`,
    });
  }

  if (reward_type === "mini-contest") {
    basePaths.push({
      name: "Mini Contest",
      path: `/admin/v2/rewards/${reward_id}/mini_contest`,
    });
  }
  return basePaths;
};

const info = (data) => [
  {
    value:
      status.find((currentStatus) => currentStatus.value === data.status)
        .label ?? data.status,
    label: "Status",
  },
  {
    value: toTitleCase(rewardType(data.selection)),
    label: "Type",
  },
  {
    value: data.points,
    label: "Fallback Points",
  },
  {
    value: data.limit,
    label: "Limit",
  },
  {
    label: "Start",
    element: <DateElement date={data.start} />,
    value: data.start,
  },
  {
    label: "End",
    element: <DateElement date={data.finish} />,
    value: data.finish,
  },
  {
    label: "NFT creation Tx",
    element: <LinkElement url={data.nft_collection_transaction_create_url} />,
    value: data.nft_collection_transaction_create_url,
  },
  {
    label: "NFT address",
    element: <AddressElement address={data.nft_collection_address} />,
    value: data.nft_collection_address,
  },
];

const status = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ended",
    value: "ended",
  },
  {
    label: "Scheduled",
    value: "scheduled",
  },
];

const intents = {
  toggle_tag: adminV2Rewards.toggleTag,
  create_nft_collection: adminV2Rewards.createNftCollection,
  fetch_possible_winners_to_sync: adminV2Rewards.fetchPossibleWinnersToSync,
  sync_winners: adminV2Rewards.syncWinners,
};

const intentToastTitle = {
  toggle_tag: "Tag updated",
  create_nft_collection: "NFT collection created",
  sync_winners: "Winners are being synced",
};

const loader = async ({ params }) => {
  const { reward_id, program_id } = params;
  const [data, tags, aptosEnabled] = await Promise.all([
    adminV2Rewards.show({ id: reward_id }),
    adminV2Programs.tags({ id: program_id }),
    adminV2Aptos.enabled({ id: reward_id }),
  ]);

  return json({
    data,
    tags: tags.tags,
    aptosEnabled: aptosEnabled.enabled,
  });
};

const action = async ({ request }) => {
  const body = await request.json();

  if (!intents[body.intent]) {
    return json({
      success: false,
      message: "Invalid intent",
    });
  }

  const data = await intents[body.intent]({ data: { ...body.data } });

  return json({
    data,
    intent: body.intent,
    success: true,
  });
};

function RewardDetailsPage() {
  const { pathname } = useLocation();
  const { reward_id } = useParams();
  const navigate = useNavigate();
  const actionData = useActionData();
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data, tags, aptosEnabled } = useLoaderData();
  const { submitWithIntent } = useSendActionData();

  useFormActionToast(actionData, intentToastTitle);

  const tagSubmission = async ({ tag }) => {
    await submitWithIntent("toggle_tag", { tag, id: reward_id });
  };

  const nftCollectionSubmission = async () => {
    await submitWithIntent("create_nft_collection", { id: reward_id });
  };

  const fetchPossibleWinnersToSync = async () => {
    await submitWithIntent("fetch_possible_winners_to_sync", { id: reward_id });
  };

  const syncWinnersSubmission = async (send_email) => {
    await submitWithIntent("sync_winners", { send_email, id: reward_id });
  };

  return (
    <div className="container mx-0 pt-5 pl-8">
      <div className="grid grid-cols-10 gap-10">
        <div className="col-span-3">
          <Card.Root className="max-w-none border-2 shadow-sm bg-background">
            <Card.Header className="mb-0 pb-0">
              <div className="border-b-1 flex w-full flex-col items-center justify-center">
                <img
                  src={data.image.url}
                  alt=""
                  className="w-h-28 h-28 rounded-md object-cover"
                />
                <h1 className="mt-1 text-xl font-semibold text-foreground">
                  {data.name}
                </h1>
              </div>
              <Card.Description className="flex flex-col gap-1 py-2 text-sm">
                <Button
                  className="text-sm"
                  size="sm"
                  onClick={() => {
                    navigate(`/admin/v2/rewards/${reward_id}/edit`);
                  }}
                >
                  Edit
                </Button>
                {(data.selection === "achievement" ||
                  data.selection === "milestone") && (
                  <SyncWinnersDialog
                    fetchPossibleWinnersToSync={fetchPossibleWinnersToSync}
                    syncWinnersSubmission={syncWinnersSubmission}
                    actionData={actionData}
                  />
                )}
                {data.nft_collection_transaction_create_url === null &&
                  aptosEnabled &&
                  data.selection === "achievement" && (
                    <NftCreationDialog
                      nftCollectionSubmission={nftCollectionSubmission}
                    />
                  )}
              </Card.Description>
            </Card.Header>
            <div className="border-b dard:border-b-4" />
            <Card.Content className="items-center">
              <div className="mt-2 px-6">
                <InfoList data={info(data)} />
              </div>
            </Card.Content>
            <Card.Footer className="mt-4 flex flex-row justify-center gap-1">
              <TaggablePopover
                tags={tags}
                selectedTags={data.tag_list}
                onSelect={tagSubmission}
              />
            </Card.Footer>
          </Card.Root>
        </div>
        <div className="col-span-7">
          <div className="flex gap-5">
            <SimpleKpiCard title="Total Claims">
              {data.claims} of {data.limit}
            </SimpleKpiCard>
            <SimpleKpiCard title="Rewards Balance">
              {data.limit - data.claims}
            </SimpleKpiCard>
          </div>
          <div className="mt-2 flex gap-3">
            {paths({
              reward_id,
              reward_type: data.selection,
              nft_collection: data.nft_collection,
            }).map((item, idx) => (
              <Link
                key={idx}
                to={item.path}
                className={cn(
                  "text-muted-foreground px-2 py-1 text-sm font-medium",
                  {
                    "bg-accent border-2-primary rounded-md text-foreground":
                      pathname === item.path,
                  },
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="mr-10 mt-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
RewardDetailsPage.loader = loader;
RewardDetailsPage.action = action;
export default RewardDetailsPage;
