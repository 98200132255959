import {
  Button,
  capitalize,
  Form,
  FormField,
  Input,
  SubmitButton,
} from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import { Cross1Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";

export function OtpConfirmation({ handleSubmit, participant, form }) {
  const [countdown, setCountdown] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const maskEmail = (email) => {
    const [username, domain] = email.split("@");
    const maskedUsername = username.charAt(0) + "*".repeat(username.length - 1);
    const maskedDomain =
      domain.charAt(0) + "*".repeat(domain.length - 4) + domain.slice(-3);
    return `${maskedUsername}@${maskedDomain}`;
  };

  useEffect(() => {
    let timer;
    if (isTimerRunning && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsTimerRunning(false);
    }

    return () => clearInterval(timer);
  }, [countdown, isTimerRunning]);

  const handleResendCode = () => {
    if (countdown === 0) {
      form.setValue("otp", "");
      handleSubmit();
      setCountdown(60);
      setIsTimerRunning(true);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const onSubmit = (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("referral_code");

    if (referralCode) {
      data.referral_code = referralCode;
    }

    handleSubmit(data);
  };

  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border pb-6 !rounded-perk-cards">
      <Card.Header>
        <div className="flex w-full justify-end">
          <Button
            className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <Cross1Icon className="size-3" />
          </Button>
        </div>
        <Card.Title className="pl-0 text-xl text-center">
          Hey, {capitalize(participant.first_name)}!
        </Card.Title>
      </Card.Header>
      <Card.Content className="mx-4">
        <div className="text-center mb-6">
          <p className="mb-2"></p>
          <p className="text-gray-600 text-sm">
            An email was sent to{" "}
            <span className="font-medium">{maskEmail(participant.email)}</span>{" "}
            with a 6-digit code. Please enter it below.
          </p>
        </div>

        <Form {...form} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-4">
            <FormField
              control={form.control}
              name="otp"
              render={({ field: formField }) => (
                <div className="space-y-2">
                  <Input
                    className="text-center text-lg tracking-wider placeholder:text-sm placeholder:text-gray-400/50 placeholder:tracking-normal"
                    name={formField.name}
                    value={formField.value}
                    onChange={formField.onChange}
                    maxLength={6}
                    placeholder="Enter 6-digit code"
                  />
                </div>
              )}
            />

            <div className="flex flex-col md:flex-row items-center gap-2">
              <div className="flex text-center text-sm text-gray-500 w-full bg-perk-primary/10 items-center justify-center ">
                {countdown > 0 ? (
                  <p className="mb-0 px-4 py-2">
                    Request a new code in{" "}
                    <span className="font-medium">{formatTime(countdown)}</span>
                  </p>
                ) : (
                  <Button
                    type="button"
                    variant="outline"
                    className="text-perk-primary hover:text-perk-primary/20 w-full rounded-perk-button focus:outline-none"
                    onClick={handleResendCode}
                  >
                    Request new code
                  </Button>
                )}
              </div>

              <SubmitButton
                type="submit"
                className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 w-full rounded-perk-button"
                submittingText="Verifying..."
              >
                Verify Code
              </SubmitButton>
            </div>
          </div>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}
