import { adminV2ProgramAttachments } from "@api/index";
import { SubmitButton } from "@components/SubmitButton";
import { UploadIcon } from "@radix-ui/react-icons";
import { serialize } from "object-to-formdata";
import React, { useCallback, useEffect } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { attachmentFetcher } from "./attachmentFetcher";

export const AttachmentUpload = ({ programId, field, setUploadedFiles }) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const { trigger, isMutating } = useSWRMutation(
    adminV2ProgramAttachments.create.path({ program_id: programId }),
    attachmentFetcher,
  );

  const { mutate } = useSWRConfig();

  useEffect(() => {
    mutate(() => true, undefined, {
      revalidate: true,
    });
  }, [isMutating]);

  const onUpload = async (event) => {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    const formData = serialize({ file });

    const result = await trigger({ formData, method: "POST" });

    if (result?.success) {
      const fileUrl = URL.createObjectURL(file);
      setUploadedFiles((prev) => [
        ...prev,
        {
          id: result?.resource?.id,
          fallback: fileUrl,
        },
      ]);
    }
  };

  const handleUploadClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const accept =
    field.accept ||
    (field.mode === "file" ? "*" : "image/png,image/jpg,image/jpeg");

  return (
    <div>
      <SubmitButton
        type="button"
        className="size-full min-h-10 flex items-center justify-center relative rounded-sm p-0 border-dashed border-2 border-muted-foreground transition-all cursor-pointer bg-transparent text-foreground hover:bg-primary/10"
        onClick={handleUploadClick}
        isSubmitting={isMutating}
      >
        <UploadIcon className="mr-2" />
        Upload New File
      </SubmitButton>
      <input
        type="file"
        onChange={onUpload}
        name={"file"}
        ref={hiddenFileInput}
        accept={accept}
        style={{ display: "none" }}
      />
    </div>
  );
};
