import {
  capitalize,
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@bleu.builders/ui";
import AutoScroll from "embla-carousel-auto-scroll";
import React from "react";

import { LatestActions } from "..";

export function DashboardTopBar({
  data,
}: {
  data: LatestActions["latestActions"];
}) {
  if (data?.data.length < 5) {
    return <></>;
  }

  return (
    <div className="bg-border">
      <Carousel
        plugins={[
          AutoScroll({
            playOnInit: true,
            speed: 1,
            stopOnInteraction: false,
            stopOnFocusIn: false,
          }),
        ]}
        className="size-full py-1"
        opts={{
          align: "start",
          loop: true,
        }}
      >
        <CarouselContent className="gap-3 ml-3 flex items-center">
          {data?.data.map((item, index) => (
            <React.Fragment key={index}>
              <CarouselItem className="max-w-fit border-2">
                <span>
                  {capitalize(item.name)} {item.title}
                </span>
                {item.country && (
                  <span>
                    {` in ${item.city ? capitalize(item.city) + ", " : ""}${capitalize(item.country)}`}
                  </span>
                )}
              </CarouselItem>
              {/* Dot symbol • = &#x2022; */}
              <span className="pl-4">&#x2022;</span>
            </React.Fragment>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
}
