import { buildForm, SelectFieldProps } from "@bleu.builders/ui";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { BrandingOutletContextType } from "./(components)/Layout";

const BORDER_OPTIONS = [
  { value: "none", label: "None", classes: "rounded-tl-none" },
  { value: "small", label: "Small", classes: "rounded-tl-sm" },
  { value: "medium", label: "Medium", classes: "rounded-tl-md" },
  { value: "large", label: "Large", classes: "rounded-tl-3xl" },
  { value: "full", label: "Full", classes: "rounded-tl-full" },
];

const FIELDS = [
  {
    name: "element_css_style[perk-button-border-radius]",
    label: "Button Border Radius",
    description: "This is the button border radius",
    type: "radius_select",
    options: BORDER_OPTIONS,
  },
  {
    name: "element_css_style[perk-input-border-radius]",
    label: "Input Border Radius",
    description: "This is the input border radius",
    type: "radius_select",
    options: BORDER_OPTIONS,
  },
  {
    name: "element_css_style[perk-tiles-border-radius]",
    label: "Tiles Border Radius",
    description: "This is the tiles border radius",
    type: "radius_select",
    options: BORDER_OPTIONS,
  },
  {
    name: "element_css_style[perk-cards-border-radius]",
    label: "Cards Border Radius",
    description: "This is the cards border radius",
    type: "radius_select",
    options: BORDER_OPTIONS,
  },
];

export default function ProgramBordersSettingPage() {
  const { form } = useOutletContext<BrandingOutletContextType>();

  return (
    <div>
      <h2 className="mb-0 pt-8 text-2xl font-bold tracking-tight">Borders</h2>
      <div className="grid lg:grid-cols-1 gap-4">
        {/** @ts-ignore */}
        {buildForm(FIELDS, form)}
      </div>
    </div>
  );
}
