// JsFromRoutes CacheKey 40398d045d34fe14c402559535dfc30c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/program_attachments",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/program_attachments",
  ),
  show: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/program_attachments/:id",
  ),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/program_attachments/:id",
  ),
};
