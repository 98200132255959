import React from "react";

import { Reward } from "../types";
import { RewardLayout } from "./RewardLayout";

export function SweepstakesReward({
  data,
  handleSubmit,
}: {
  data: { reward: Reward };
  handleSubmit?: (customData?: FormData) => void;
}) {
  const { reward } = data;

  const renderSweepstakesDetails = () => {
    if (
      !(reward?.selection === "sweepstake") ||
      !(reward?.participant_sweepstakes_entry > 0)
    ) {
      return null;
    }

    return (
      <div className="text-start w-full">
        You have earned{" "}
        <span className="font-bold">
          {reward.participant_sweepstakes_entry} entries
        </span>{" "}
        into this sweepstakes!
      </div>
    );
  };

  return (
    <RewardLayout reward={reward} renderDetails={renderSweepstakesDetails} />
  );
}
