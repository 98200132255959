import Link from "@components/Link";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "@components/ui/Sheet";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import { useReferralCode } from "@hooks/useReferralCode";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import React from "react";

function SidebarItem({ item, headerFont }) {
  return (
    <Link
      to={item.navigation_url}
      target={item.show_in_tab ? "_blank" : undefined}
      className="py-2 hover:bg-perk-primary px-6 w-full text-start text-perk-primary-content hover:no-underline sidebar-item"
      style={{
        fontFamily: headerFont ? `'${headerFont}', sans-serif` : "inherit",
      }}
    >
      <span className="text-perk-primary-content">{item.name}</span>
    </Link>
  );
}

function HeaderNavigation({ navItems, participant }) {
  if (!navItems || navItems.length === 0) {
    return null;
  }

  return (
    <>
      {navItems.map((navItem) => {
        if (!participant && !navItem.show_on_logout) {
          return null;
        }

        return (
          <Link
            key={navItem.id}
            to={navItem.navigation_url}
            className="hover:underline-offset-8 hover:decoration-2 hidden md:block text-perk-header-text hover:text-perk-primary header-item"
            target={navItem.show_in_tab ? "_blank" : undefined}
          >
            {navItem.name}
          </Link>
        );
      })}
    </>
  );
}

export function Header() {
  const program = useProgram();
  const participant = useParticipant();
  const referralCode = useReferralCode();

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  const headerFont =
    program?.program_template_detail?.element_css_style?.["header_font"];

  const logo = program?.program_template_detail?.header_logo?.public_url;
  const navItems = program.navigation_menus;

  return (
    <div
      className="w-full h-24 flex justify-between px-4 py-2 items-center bg-perk-header-bg text-perk-header-text"
      style={{
        fontFamily: headerFont ? `'${headerFont}', sans-serif` : "inherit",
      }}
    >
      {logo ? (
        <Link
          to={{
            pathname: "/",
            search: referralCode,
          }}
        >
          <img src={logo} alt="logo" className="h-8 xl:h-12" />
        </Link>
      ) : (
        <span className="h-12" aria-hidden="true" />
      )}
      <div className="flex flex-row items-center font-bold gap-x-2 xl:gap-x-12 text-lg">
        <HeaderNavigation navItems={navItems} participant={participant} />
        {participant ? (
          <>
            <Link
              className="flex flex-col hover:no-underline text-perk-header-text hover:text-perk-primary items-end header-item"
              to="/my-account"
            >
              <span className="leading-none text-end">
                Hi! {participant.first_name}
              </span>
              <span className="leading-none text-end">
                +{participant.unused_points} {program.term_points_as}
              </span>
            </Link>
            <Sheet>
              <SheetTrigger className="focus:outline-none text-perk-header-text header-item">
                <HamburgerMenuIcon className="size-6" />
              </SheetTrigger>
              <SheetContent className="p-0">
                <SheetHeader>
                  <SheetDescription className="flex mt-10 flex-col items-start text-lg gap-y-2">
                    <SidebarItem
                      item={{
                        name: "My Account",
                        navigation_url: "/my-account",
                      }}
                      headerFont={headerFont}
                    />
                    {navItems.map((navItem) => {
                      return (
                        <SidebarItem
                          key={navItem.id}
                          item={navItem}
                          headerFont={headerFont}
                        />
                      );
                    })}
                    <form
                      action="/public/signin/sign_out_user"
                      method="post"
                      className="w-full"
                    >
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value={csrfToken}
                      />
                      <button
                        type="submit"
                        className="sidebar-item py-2 hover:bg-perk-primary px-6 w-full text-start text-perk-primary-content hover:no-underline focus:outline-none"
                        style={{
                          fontFamily: headerFont
                            ? `'${headerFont}', sans-serif`
                            : "inherit",
                        }}
                      >
                        <span>Logout</span>
                      </button>
                    </form>
                  </SheetDescription>
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </>
        ) : (
          <Link
            to={{
              pathname: "/signin",
              search: referralCode,
            }}
          >
            <button className="px-3 py-1 font-normal text-base focus:outline-none text-perk-primary-content bg-perk-primary">
              Sign In
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}
