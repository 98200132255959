import { formatDate, Separator } from "@bleu.builders/ui";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/Accordion";
import Card from "@components/ui/Card";
import { WysiwygText } from "@components/WysiwygText";
import React, { ReactNode } from "react";

import { CloseButton } from "../challenges/components/CloseButton";
import { titleForReward } from "../home/RewardSection";
import { Reward } from "../types";
import { RewardDate } from "./RewardDate";

interface RewardLayoutProps {
  reward: Reward;
  children?: ReactNode;
  renderDetails?: () => ReactNode;
  renderFooter?: () => ReactNode;
  renderImage?: () => ReactNode;
}

export function RewardLayout({
  reward,
  children,
  renderDetails,
  renderFooter,
  renderImage,
}: RewardLayoutProps) {
  const hasDetails =
    reward.description_details ||
    reward.terms_conditions ||
    reward?.participant_sweepstakes_entry > 0;
  const earnedOn = reward.earned_by_current_participant_on;

  const defaultFooter = () => (
    <div className="flex flex-col gap-y-2 w-full justify-between px-6 items-center mt-2 md:flex-row">
      <div></div>
      <RewardDate earnedOn={earnedOn} endDate={reward.finish} />
    </div>
  );

  const defaultImage = () => (
    <div className="flex justify-center">
      <img
        src={reward?.image?.public_url}
        alt={reward.name}
        className="size-1/2"
      />
    </div>
  );

  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards">
      <Card.Header>
        <div className="flex justify-between items-center">
          <span className="text-xl font-bold text-perk-primary uppercase">
            {titleForReward(reward?.selection)} Reward
          </span>
          <div className="justify-self-end">
            <CloseButton />
          </div>
        </div>
        {earnedOn && (
          <span className="text-center text-2xl">
            Congrats &#128591; You&apos;ve earned:
          </span>
        )}
        <span className="text-center font-bold text-3xl">{reward.name}</span>
      </Card.Header>
      <Card.Content className="flex flex-col gap-y-6 px-6">
        {earnedOn && reward.redemption_details && (
          <WysiwygText text={reward.redemption_details} />
        )}
        {renderImage ? renderImage() : defaultImage()}
        <p className="text-center text-lg">{reward.description}</p>
        {children}
        {hasDetails && (
          <div className="bg-border rounded-lg flex flex-col items-center px-6 py-3 gap-y-3">
            <span className="text-xl font-bold">Details</span>
            <Separator className="bg-black" />
            {reward?.description_details && (
              <WysiwygText text={reward.description_details} />
            )}
            {renderDetails && renderDetails()}
            {reward?.terms_conditions && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger className="hover:no-underline">
                    Terms and Conditions
                  </AccordionTrigger>
                  <AccordionContent>{reward.terms_conditions}</AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        )}
      </Card.Content>
      <Card.Footer>
        {renderFooter ? renderFooter() : defaultFooter()}
      </Card.Footer>
    </Card.Root>
  );
}
