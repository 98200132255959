import { Button, SWRDataTable } from "@bleu.builders/ui";
import Link from "@components/Link";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useParams } from "react-router-dom";

function AddNewParticipantProfile() {
  const { program_id, end_user_id } = useParams();

  return (
    <Link
      to={`/admin/v2/programs/${program_id}/users/${end_user_id}/attributes/new`}
    >
      <Button size="sm" className="ml-auto hidden h-8 lg:flex">
        <PlusIcon className="mr-2 size-4" />
        Add Attribute
      </Button>
    </Link>
  );
}

function ParticipantAttributesTable() {
  const { end_user_id } = useParams();

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/participants/${end_user_id}/participant_profile`}
      action={<AddNewParticipantProfile />}
      searchKey="attribute_display_name"
    />
  );
}

export default ParticipantAttributesTable;
