import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { WithSidebarLayout } from "../../../../../Layout";

const programSettingsSidebar = (program_id) => {
  return [
    {
      title: "Configuration",
      items: [
        {
          title: "General",
          href: `/admin/v2/programs/${program_id}/settings/program/general`,
          items: [],
        },
        {
          title: "Your labels",
          href: `/admin/v2/programs/${program_id}/settings/program/labels`,
          items: [],
        },
        {
          title: "Meta tags / SEO",
          href: `/admin/v2/programs/${program_id}/settings/program/seo`,
          items: [],
        },
        {
          title: "Program Pages",
          href: `/admin/v2/programs/${program_id}/settings/program/program-pages`,
          items: [],
        },
        {
          title: "Profile Attributes",
          href: `/admin/v2/programs/${program_id}/settings/program/profile-attributes`,
          items: [],
        },
        {
          title: "Enrollment",
          href: `/admin/v2/programs/${program_id}/settings/program/enrollment`,
          items: [],
        },
      ],
    },
  ];
};

export function SettingsSidebar() {
  const { id } = useProgram();

  const sidebarItems = programSettingsSidebar(id);

  return <WithSidebarLayout items={sidebarItems} />;
}
