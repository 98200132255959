// JsFromRoutes CacheKey abd05c0ece9b279b7cb0a5369ef125c2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  setWinners: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/set_winners",
  ),
  generateSweepstakeEntries: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/generate_sweepstake_entries",
  ),
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/rewards/:reward_id/sweepstake",
  ),
};
