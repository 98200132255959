import { useProgram } from "@contexts/ProgramContext";
import { useUser } from "@contexts/UserContext";
import { cn } from "@utils/mergeClassNames";
import React from "react";

export const EmailPreview = ({
  primaryStyle,
  secondaryStyle,
  borderRadiusClass,
  inputBorderRadiusClass,
  headerLogoPreview,
  footerData,
}) => {
  const { user } = useUser();
  const program = useProgram();

  return (
    <div className="flex justify-center">
      <div className="bg-background overflow-hidden w-full max-w-sm shadow-lg h-fit rounded-sm border border-muted">
        <div
          className="px-2 py-4 flex items-center justify-center"
          style={primaryStyle}
        >
          <img className="size-10" src={headerLogoPreview} />
        </div>
        <div className="px-8 py-2 max-w-lg mx-auto my-2 text-center">
          <h1 className="text-lg font-bold mb-2">Hey, {user.first_name}</h1>
          <p className="text-base mb-8">
            You're just a click away from getting started in {program.name}. To
            activate your account, please click the link below.
          </p>
          <button
            className={cn(
              "text-center focus-visible:outline-offset-2 disabled:opacity-40 py-2 px-4",
              borderRadiusClass,
            )}
            style={primaryStyle}
          >
            Confirm & Activate
          </button>
        </div>
        <div
          className="flex flex-col items-center justify-center mt-3 gap-0 text-muted-foreground text-sm"
          style={{
            backgroundColor: footerData.backgroundColor,
          }}
        >
          <span
            style={{
              color: footerData.fontColor,
            }}
          >
            {program.name}
          </span>

          <span
            style={{
              color: footerData.fontColor,
            }}
          >
            Support • Unsubscribe
          </span>
          <img
            className="h-10 w-16 my-3"
            src="https://res.cloudinary.com/basis/image/upload/v1671010736/Perk/Perk_Studios_with_Powered_By_Logo_Gold_4x.png"
          />
        </div>
      </div>
    </div>
  );
};
