import { Button, SectionTitle, SWRDataTable } from "@bleu.builders/ui";
import Link from "@components/Link";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useParams } from "react-router-dom";

function AddNewChallengeLink() {
  const { program_id } = useParams();

  return (
    <Link to={`/admin/v2/programs/${program_id}/challenges/new`}>
      <Button size="sm" className="ml-auto hidden h-8 lg:flex">
        <PlusIcon className="mr-2 size-4" />
        Add Challenge
      </Button>
    </Link>
  );
}

export default function ChallengesPage() {
  const { program_id } = useParams();
  return (
    <div className="mx-20 mb-10">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Challenges</SectionTitle>
          <p className="text-muted-foreground">
            Here&apos;s a list of your challenges.
          </p>
        </div>
      </div>
      <SWRDataTable
        fetchPath={`/admin/v2/programs/${program_id}/challenges`}
        action={<AddNewChallengeLink />}
        defaultParams={{
          columnVisibility: {
            tags: false,
            details_url: false,
          },
        }}
      />
    </div>
  );
}
