import { adminV2Dashboard } from "@api/all";
import { cn } from "@bleu.builders/ui";
import Link from "@components/Link";
import { client } from "@utils/api-client";
import React, { Suspense } from "react";
import {
  Await,
  defer,
  Outlet,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";

import { DashboardTopBar } from "./(components)/DahboardTopBar";

export type LatestActions = {
  latestActions: {
    data: {
      action_type: string;
      details_url: string;
      name: string;
      actionable_url: string;
      title: string;
      created_at: string;
      city: string;
      country: string;
    }[];
  };
};

const paths = ({ program_id }) => [
  {
    name: "Overview",
    path: `/admin/v2/programs/${program_id}/dashboard`,
  },
  {
    name: "Actions",
    path: `/admin/v2/programs/${program_id}/dashboard/actions`,
  },
  {
    name: "Challenges",
    path: `/admin/v2/programs/${program_id}/dashboard/challenges`,
  },
  {
    name: "Rewards",
    path: `/admin/v2/programs/${program_id}/dashboard/rewards`,
  },
];

const headers = {
  Accept: "application/json",
};

const loader = async ({ params }) => {
  const { program_id } = params;

  const latestActions = client(
    adminV2Dashboard.latestActions.path({ program_id }),
    { headers },
  );

  return defer({
    latestActions,
  });
};

function DashboardPage() {
  const { pathname } = useLocation();
  const { program_id } = useParams();

  const { latestActions } = useLoaderData() as LatestActions;

  return (
    <div>
      <Suspense fallback={<></>}>
        <Await
          resolve={latestActions}
          errorElement={<p>Error! something went wrong</p>}
        >
          {(data) => {
            return <DashboardTopBar data={data} />;
          }}
        </Await>
      </Suspense>
      <div className="pr-8 mt-5 flex flex-col gap-y-5 mx-20">
        <div className="mt-2 flex gap-3">
          {paths({ program_id }).map((item, idx) => (
            <Link
              key={idx}
              to={item.path}
              className={cn(
                "text-muted-foreground px-2 py-1 text-sm font-medium bg-border rounded-md",
                {
                  "bg-gold border-2-primary rounded-md text-foreground":
                    pathname === item.path,
                },
              )}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <Outlet />
      </div>
    </div>
  );
}
DashboardPage.loader = loader;
export default DashboardPage;
