import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { SettingsCard } from "../(components)/SettingsCard";

export default function ProgramGeneralSetting() {
  const program = useProgram();

  const fields = [
    {
      name: "name",
      type: "input",
      label: "Name",
      description: "Name of the program.",
      placeholder: "Perk Brand Awareness",
      required: true,
    },
    {
      name: "domain_type",
      type: "select",
      label: "Domain Type",
      description:
        "Choose whether to use a `perk.studio` subdomain or another domain you own.",
      options: [
        { label: "Use a perk.studio subdomain", value: "include_in_domain" },
        { label: "Use my own domain", value: "own_domain" },
      ],
      required: true,
    },
    {
      name: "domain",
      type: "input",
      label: "Domain",
      description:
        "Change the `perk.studio` subdomain or add a domain you own for your clients.",
      placeholder: "Domain name",
    },
    {
      name: "test_mode",
      type: "switch",
      label: "Test Mode",
      disabled: (program) => !program.test_mode,
      description:
        "Enable test mode to test your program. Transactions are simulated. Once off, no return to test.",
    },
  ];

  return (
    <>
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={program}
          fields={fields}
          title={"General Settings"}
          action={{ path: `/admin/v2/programs/${program.id}` }}
          useFormSubmit
        />
      </div>
    </>
  );
}
