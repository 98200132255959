import { toast } from "@bleu.builders/ui";
import { useFlipper } from "@contexts/FlipperContext";
import { useProgram } from "@contexts/ProgramContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { client } from "@utils/api-client";
import { serialize } from "object-to-formdata";
import React from "react";
import { useForm } from "react-hook-form";
import { json, useActionData, useSubmit } from "react-router-dom";
import { z } from "zod";

import { EndUserLayout } from "../Layout";
import { EnterForm } from "./EnterForm";
import { MagicLinkSent } from "./MagicLinkSent";
import { NewParticipantForm } from "./NewParticipantForm";
import { OtpConfirmation } from "./OtpConfimation";

interface ActionData {
  ok: boolean;
  participant: {
    first_name: string;
  };
  sign_type: string;
  otp_logged?: boolean;
  message?: string;
}

const emailSchema = z.object({
  email: z.string().email("Invalid email"),
});

enum AuthState {
  INITIAL = "initial",
  NEW_PARTICIPANT = "new_participant",
  OTP_VERIFICATION = "otp_verification",
  MAGIC_LINK_SENT = "magic_link_sent",
}

const action = async ({ request }) => {
  const formData = await request.formData();
  const response = await client("public/signin/verify", { body: formData });

  return json(response);
};

function SignInPage() {
  const program = useProgram();
  const submit = useSubmit();
  const flipper = useFlipper();
  const actionData = useActionData() as ActionData;
  const [authState, setAuthState] = React.useState<AuthState>(
    AuthState.INITIAL,
  );
  const [participant, setParticipant] = React.useState(null);

  const form = useForm({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: "",
      auth_type: flipper.otp_login ? "otp" : "magic_link",
    },
  });

  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get("redirectTo");

  const handleSubmit = async () => {
    const formData = serialize({
      ...form.getValues(),
      auth_type: flipper.otp_login ? "otp" : "magic_link",
    });
    searchParams.forEach((value, key) => {
      formData.append(key, value);
    });
    submit(formData, { method: "post", encType: "multipart/form-data" });
  };

  React.useEffect(() => {
    if (!actionData) return;
    if (actionData.ok) {
      setParticipant(actionData.participant);
      if (actionData.otp_logged) {
        window.location.href = redirectTo || "/";
        return;
      }
      setAuthState(
        flipper.otp_login
          ? AuthState.OTP_VERIFICATION
          : AuthState.MAGIC_LINK_SENT,
      );
    } else {
      if (actionData.sign_type === "otp" && actionData.ok === false) {
        toast({
          title: "Error!",
          description: actionData.message,
          variant: "destructive",
        });
      } else {
        setAuthState(AuthState.NEW_PARTICIPANT);
      }
      if (authState === AuthState.NEW_PARTICIPANT) {
        toast({
          title: "Error!",
          description: "Required fields are missing",
          variant: "destructive",
        });
      }
    }
  }, [actionData, flipper.otp_login]);

  const renderAuthComponent = () => {
    switch (authState) {
      case AuthState.NEW_PARTICIPANT:
        return (
          <NewParticipantForm
            program={program}
            form={form}
            handleSubmit={handleSubmit}
            setIsNewParticipant={() => setAuthState(AuthState.INITIAL)}
          />
        );
      case AuthState.OTP_VERIFICATION:
        return (
          <OtpConfirmation
            handleSubmit={handleSubmit}
            participant={participant}
            form={form}
          />
        );
      case AuthState.MAGIC_LINK_SENT:
        return <MagicLinkSent participant={participant} />;
      default:
        return (
          <EnterForm
            program={program}
            form={form}
            handleSubmit={handleSubmit}
            hideAuthTypeSelection={true}
          />
        );
    }
  };

  return (
    <>
      <EndUserLayout className="my-10">
        <div className="flex w-full justify-center">
          {renderAuthComponent()}
        </div>
      </EndUserLayout>
    </>
  );
}

SignInPage.action = action;
export { SignInPage };
