import {
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@bleu.builders/ui";
import Button from "@components/ui/Button";
import Card from "@components/ui/Card";
import Dialog from "@components/ui/Dialog";
import {
  ChevronLeftIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
  MobileIcon,
  QuestionMarkCircledIcon,
  SewingPinIcon,
  SketchLogoIcon,
} from "@radix-ui/react-icons";
import { client } from "@utils/api-client";
import React, { useEffect } from "react";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";

import RedeemCoupon from "./(components)/RedeemCoupon";

async function loader({ request }) {
  const url = new URL(request.url);

  if (
    !url.searchParams.get("zip_code") &&
    (!url.searchParams.get("latitude") || !url.searchParams.get("longitude"))
  ) {
    const zip_code = window.localStorage.getItem("perk-zipcode");
    if (zip_code) {
      url.searchParams.set("zip_code", zip_code);
    }
    const geolocationStr = window.localStorage.getItem("perk-geolocation");
    const geolocation = geolocationStr ? JSON.parse(geolocationStr) : null;

    if (geolocation?.latitude && geolocation?.longitude) {
      url.searchParams.set("latitude", geolocation.latitude);
      url.searchParams.set("longitude", geolocation.longitude);
    }

    if (!zip_code && !geolocation) {
      return defer({
        data: null,
      });
    }
  }

  const data = client(
    "/public/offers/merchant?" + new URLSearchParams(url.search),
  );
  return defer({
    data,
  });
}

const BadgeLink = ({ children, Icon, ...props }) => (
  <a
    {...props}
    className="inline-flex items-center gap-1 rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-300 hover:scale-105"
  >
    <Icon className="text-perk-primary font-semibold" />
    {children}
  </a>
);

function MerchantOffersSkeleton() {
  return (
    <div className="mt-20 flex min-h-screen flex-col items-center justify-start bg-gray-100 pb-10 lg:mt-20">
      <div className="h-48 w-full max-w-[unset] animate-pulse bg-gray-300 object-cover opacity-100" />
      <p className="mt-5 animate-pulse font-medium">
        Loading merchant offers...
      </p>
      <section className="mt-1 flex items-center justify-center lg:mt-28">
        <div className="grid grid-cols-1 items-start gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-3">
          {Array.from({ length: 6 }).map((_, i) => (
            <div
              key={i}
              className="mx-auto w-80 rounded-xl bg-gray-100 shadow-xl"
            >
              <div className="h-48 animate-pulse overflow-hidden bg-gray-200 p-3"></div>
              <div className="h- p-3">
                <div className="mt-2 grid grid-cols-3 gap-4">
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="col-span-2 h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded  bg-gray-200"></div>
                  <div className="..."></div>
                  <div className="... col-span-2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

function MerchantDescriptionBanner({ merchant }) {
  const address = `${merchant.merchantAddress?.address}, ${merchant.merchantAddress?.city}, ${merchant.merchantAddress?.state} ${merchant.merchantAddress?.zipCode}`;
  const phoneNumber = merchant.merchantPhone?.replace(/-/g, "");

  const scrollToOffers = () => {
    const offersDiv = document.getElementById("offers");
    if (offersDiv) {
      offersDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative mx-auto mb-4 mt-24 flex h-48 w-full items-center justify-center lg:mt-0">
      <div className="relative flex size-full min-h-40 items-center justify-center overflow-hidden pt-96 lg:pt-0">
        <img
          style={{ filter: "blur(60px)", transform: "scale(2)" }}
          className="absolute right-[15%] h-[800%] w-[500%] max-w-[unset] object-cover opacity-100"
          src={merchant.logoPath}
          alt="Background logo"
        />
      </div>
      <div className="absolute size-full max-w-[unset] shadow-xl" />
      <div className="absolute">
        <div className="relative inset-x-0 bottom-0 top-20 mx-auto">
          <div className="mx-auto w-full max-w-xs gap-4 rounded-xl border bg-white p-4 lg:flex lg:max-w-4xl">
            <div className="flex h-28 items-center justify-center rounded-t bg-cover text-center lg:h-auto lg:w-48 lg:rounded-l-xl lg:rounded-t-none">
              <img
                className="max-h-32 w-full object-contain"
                src={merchant.logoPath}
                alt="Logo"
              />
            </div>
            <div className="border-grey-light lg:border-grey-light mt-3 flex max-w-lg flex-col items-start justify-start gap-0 rounded-b leading-normal lg:mt-0 lg:rounded-b-none">
              <h1 className="my-0 text-xl font-bold text-black">
                {merchant.merchantName}
              </h1>
              <div className="text-perk-primary mb-2 mt-0 text-sm font-bold">
                {merchant.firstCategory}, {merchant.secondCategory}
              </div>
              {merchant.stubCopy && (
                <p className="text-grey-darker max-h-24 overflow-y-auto text-sm">
                  {merchant.stubCopy}
                </p>
              )}
              <div className="flex items-center gap-2">
                <BadgeLink
                  Icon={SketchLogoIcon}
                  role="button"
                  onClick={() => scrollToOffers()}
                >
                  {merchant.offers.length} Offers
                </BadgeLink>
                <BadgeLink
                  Icon={SewingPinIcon}
                  href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                  target="_blank"
                >
                  Directions
                </BadgeLink>
                {phoneNumber && (
                  <BadgeLink Icon={MobileIcon} href={`tel:${phoneNumber}`}>
                    Call
                  </BadgeLink>
                )}
              </div>
            </div>
            <div className="border-grey-light lg:border-grey-light flex flex-col items-start justify-start rounded-b text-xs leading-normal lg:px-2">
              <div className="text-lg font-bold">Location:</div>
              <div>
                {merchant.merchantAddress?.address},{" "}
                {merchant.merchantAddress?.state}{" "}
              </div>
              <div>{merchant.merchantAddress?.zipCode}</div>
              {merchant.merchantPhone && <div>{merchant.merchantPhone}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const tabOptions = [
  {
    value: "info",
    label: "More info",
    Icon: InfoCircledIcon,
  },
  {
    value: "terms",
    label: "Terms",
    Icon: QuestionMarkCircledIcon,
  },
];

function MerchantOffers() {
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Suspense fallback={<MerchantOffersSkeleton />}>
      <Await resolve={data} errorElement={<p>Error to find merchant!</p>}>
        {(data) =>
          data && data.result?.length ? (
            <div className="flex min-h-screen flex-col items-center justify-start bg-gray-100 pb-10 lg:mt-20">
              <MerchantDescriptionBanner merchant={data.result[0]} />
              <div className="mt-60 flex w-8/12 items-center justify-start gap-2 text-lg font-semibold text-black lg:mt-28">
                <ChevronLeftIcon />
                <button onClick={() => navigate(-1)} className="font-bold">
                  Back to All Offers
                </button>
              </div>
              <section className="mb-10 mt-3 flex items-center justify-center lg:mt-28">
                <div
                  id="offers"
                  className="grid grid-cols-1 items-start gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-3"
                >
                  {data.result[0].offers.map((offer, idx) => (
                    <Card.Root key={idx}>
                      {/* @ts-expect-error TS(2322) FIXME: Type '{ src: any; }' is not assignable to type 'In... Remove this comment to see the full error message */}
                      <Card.Logo src={data.result[0].logoPath} />
                      <Card.Content>
                        <Card.Title>{offer.offerTextShort}</Card.Title>
                        <div className="flex items-center justify-center py-2">
                          <TabsRoot defaultValue="info" className="w-full">
                            <TabsList className="flex items-center p-2 pt-0 text-xs">
                              {tabOptions.map(({ value, label, Icon }) => (
                                <TabsTrigger
                                  value={value}
                                  key={value}
                                  className="text-perk-primary gap-1 rounded-none py-2 data-[state=active]:bg-gray-200 data-[state=active]:shadow"
                                >
                                  {Icon && (
                                    <Icon
                                      className="size-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <p className="m-0 items-center p-0 font-normal text-gray-700">
                                    {label}
                                  </p>
                                </TabsTrigger>
                              ))}
                            </TabsList>
                            {tabOptions.map(({ value }) => (
                              <TabsContent
                                value={value}
                                key={value}
                                className="flex items-center text-xs"
                              >
                                <p className="max-h-24 overflow-y-auto py-2">
                                  {value === "info"
                                    ? offer.offerTextLong
                                    : offer.validQualifier ||
                                      "No terms available"}
                                </p>
                              </TabsContent>
                            ))}
                          </TabsRoot>
                        </div>
                        <div className="flex items-center justify-center">
                          {/* @ts-expect-error TS(2559) FIXME: Type '{ children: Element[]; }' has no properties ... Remove this comment to see the full error message */}
                          <Dialog.Root>
                            <Dialog.Trigger asChild>
                              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; disabled: boolean; }' is... Remove this comment to see the full error message */}
                              <Button disabled={false}>Redeem now</Button>
                            </Dialog.Trigger>
                            <Dialog.Content className="sm:max-w-[550px]">
                              <RedeemCoupon
                                offer={offer}
                                merchant={data.result[0]}
                              />
                            </Dialog.Content>
                          </Dialog.Root>
                        </div>
                      </Card.Content>
                    </Card.Root>
                  ))}
                </div>
              </section>
              <footer className="mx-5 flex items-center gap-2">
                <ExclamationTriangleIcon />
                <span className="text-sm text-gray-600">
                  THIS PAGE CANNOT BE PRINTED AND USED AS A VALID COUPON
                </span>
              </footer>
            </div>
          ) : (
            <div className="flex min-h-screen flex-col items-center justify-center text-gray-800">
              <h1 className="text-6xl font-bold">404</h1>
              <p className="mt-4 text-xl">Page not found.</p>
              <p className="text-md mt-4">
                We're sorry, but the merchant you were looking for doesn't
                exist.
              </p>
            </div>
          )
        }
      </Await>
    </React.Suspense>
  );
}

MerchantOffers.loader = loader;
export default MerchantOffers;
