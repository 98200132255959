import { cn, Toaster } from "@bleu.builders/ui";
import React from "react";

import { Footer } from "./Footer";
import { Header } from "./Header";

export function EndUserLayout({
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className={cn("grow flex flex-col items-center", className)}>
        <div className="z-[1500]">
          <Toaster className="right-0" />
        </div>
        <div className="flex w-full grow">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
