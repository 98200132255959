export function rewardType(rewardSelection) {
  switch (rewardSelection) {
    case "sweepstake":
      return "sweepstakes";
    case "redeem":
      return "cash-in";
    default:
      return rewardSelection;
  }
}
