// JsFromRoutes CacheKey bdac11cf1f38a7bebfef52bf5e49530b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/rewards/:reward_id/coupons",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:reward_id/coupons",
  ),
};
