import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { cn } from "@utils/mergeClassNames";
import React, { createContext, useContext } from "react";

import Button from "./Button";

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const DialogContext = createContext();

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogContext.Provider");
  }
  return context;
};

const Root = React.forwardRef(
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
  ({ className, children, defaultOpen = false, ...props }, ref) => {
    const [open, setOpen] = React.useState(defaultOpen);

    return (
      <DialogPrimitive.Root
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; ref: ForwardedRef<unkno... Remove this comment to see the full error message
        ref={ref}
        className={cn(className)}
        open={open}
        onOpenChange={setOpen}
        {...props}
      >
        <DialogContext.Provider value={{ close: () => setOpen(false) }}>
          {children}
        </DialogContext.Provider>
      </DialogPrimitive.Root>
    );
  },
);
Root.displayName = DialogPrimitive.Root.displayName;

const Trigger = DialogPrimitive.Trigger;

const Portal = ({ className, ...props }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ className: string; }' is not assignable to... Remove this comment to see the full error message
  <DialogPrimitive.Portal className={cn(className)} {...props} />
);
Portal.displayName = DialogPrimitive.Portal.displayName;

const Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/60 backdrop-blur-sm",
      className,
    )}
    {...props}
  />
));
Overlay.displayName = DialogPrimitive.Overlay.displayName;

const Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  // @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
  <Portal>
    <Overlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] gap-4 rounded-xl border shadow-lg duration-200",
        className,
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
        <Cross2Icon className="size-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </Portal>
));
Content.displayName = DialogPrimitive.Content.displayName;

const Header = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);
Header.displayName = "DialogHeader";

const Footer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);
Footer.displayName = "DialogFooter";

const Title = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
Title.displayName = DialogPrimitive.Title.displayName;

const Description = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-muted-foreground text-sm", className)}
    {...props}
  />
));
Description.displayName = DialogPrimitive.Description.displayName;

const CloseButton = React.forwardRef(({ ...props }, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type '{}'.
  const { close } = useDialog();

  // @ts-expect-error TS(2322) FIXME: Type '{ ref: ForwardedRef<unknown>; onClick: any; ... Remove this comment to see the full error message
  return <Button ref={ref} onClick={close} {...props} />;
});
CloseButton.displayName = "CloseButton";

const Dialog = {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  Header,
  Footer,
  Title,
  Description,
  CloseButton,
};

export default Dialog;
