import { adminV2Rewards } from "@api/index";
import { toast } from "@bleu.builders/ui";
import { client } from "@utils/api-client";
import React from "react";
import { json, redirect, useLoaderData } from "react-router-dom";

import { WizardForm } from "../(components)/wizard/Form";

const loader = async ({ params }) => {
  const { reward_id } = params;
  const { data } = await adminV2Rewards.edit({ id: reward_id });
  return json({ data });
};

const action = async ({ params, request }) => {
  const formData = await request.formData();
  const { reward_id } = params;

  const path = adminV2Rewards.update.path({ id: reward_id });
  const data = await client(path, {
    body: formData,
    method: "PUT",
  });

  toast({
    title: data?.success ? "Success!" : "Error!!!",
    description: data?.success ? "Reward updated successfully." : data?.errors,
    variant: data?.success ? "default" : "destructive",
  });

  if (data?.success) {
    return redirect(`/admin/v2/programs/${data.program_id}/rewards`);
  }

  return json({ ok: false });
};

function EditRewardPage() {
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data } = useLoaderData();

  return (
    <div>
      <WizardForm data={data} mode="update" />
    </div>
  );
}

EditRewardPage.loader = loader;
EditRewardPage.action = action;
export default EditRewardPage;
