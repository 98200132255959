import {
  Button,
  capitalize,
  Card,
  CardContent,
  Carousel,
  CarouselContent,
  CarouselItem,
  cn,
} from "@bleu.builders/ui";
import Link from "@components/Link";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import { useReferralCode } from "@hooks/useReferralCode";
import { ClockIcon } from "@radix-ui/react-icons";
import { distanceOfTimeInWords } from "@utils/formatDate";
import Autoplay from "embla-carousel-autoplay";
import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Reward } from "../types";

export function BackgroundHero() {
  const program = useProgram();
  const participant = useParticipant();
  const referralCode = useReferralCode();
  const heroInfo = {
    title: program?.program_template_detail?.header_text,
    description: program?.program_template_detail?.header_description,
    imageUrl:
      program?.program_template_detail?.header_background_image?.public_url,
  };
  const { rewards } = useLoaderData() as { rewards: Reward[] };

  const plugin = React.useRef(
    Autoplay({ delay: 7000, stopOnInteraction: true, playOnInit: true }),
  );

  return (
    <div
      className={cn(
        "relative w-full min-h-[600px]",
        !participant && "md:min-h-[800px] xl:min-h-[calc(100vh-96px)]",
      )}
    >
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${heroInfo?.imageUrl})` }}
      >
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center w-full">
          <div className="w-full px-4 sm:px-6 md:px-28 xl:px-64 py-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="flex flex-col gap-y-6">
                <div className="text-perk-hero-text flex flex-col gap-y-6 hero-text">
                  <h1 className="font-medium text-2xl md:text-3xl lg:text-4xl">
                    {heroInfo.title}
                  </h1>
                  <p className="font-semibold text-lg md:text-xl">
                    {heroInfo.description}
                  </p>
                </div>
                {!participant && (
                  <Link
                    to={{
                      pathname: "/signin",
                      search: referralCode,
                    }}
                  >
                    <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button w-full text-lg md:text-xl py-3 px-6 focus:outline-none">
                      Get Started
                    </Button>
                  </Link>
                )}
              </div>
              <div className="md:mt-0">
                <React.Suspense fallback={"Loading..."}>
                  <Await
                    resolve={rewards}
                    errorElement={<p>Error loading Rewards</p>}
                  >
                    {(resolvedData) => (
                      <>
                        {resolvedData.length > 0 && (
                          <div>
                            <span className="text-perk-hero-text text-lg md:text-xl mb-2 block hero-text">
                              What you can win:
                            </span>
                            <Carousel
                              plugins={[plugin.current]}
                              className="size-full"
                              opts={{ align: "start", loop: true }}
                            >
                              <CarouselContent>
                                {resolvedData.length > 0 &&
                                  resolvedData
                                    .filter(
                                      (item) =>
                                        !(
                                          item.feature === false && !participant
                                        ),
                                    )
                                    .map((item, index) => (
                                      <CarouselItem key={index}>
                                        <RewardCard reward={item} />
                                      </CarouselItem>
                                    ))}
                              </CarouselContent>
                            </Carousel>
                          </div>
                        )}
                      </>
                    )}
                  </Await>
                </React.Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RewardCard({ reward }) {
  return (
    <Card className="bg-background h-[200px] md:h-[400px] overflow-hidden relative group border-0 shadow-lg">
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden bg-gray-100">
        <img
          src={reward?.image?.public_url}
          alt={reward.name}
          className="size-full object-contain"
        />
      </div>
      <CardContent className="relative h-full flex flex-col justify-between p-4 bg-gradient-to-t from-black via-black/50 to-transparent text-white z-10">
        <div className="flex justify-end items-center gap-x-1">
          <ClockIcon className="size-4" />
          <p className="text-sm m-0">
            Prize Ends in {distanceOfTimeInWords(new Date(), reward.finish)}
          </p>
        </div>
        <div className="mt-auto">
          <h3 className="text-xl md:text-2xl font-bold mb-2 drop-shadow-md">
            {capitalize(reward.name)}
          </h3>
        </div>
      </CardContent>
    </Card>
  );
}
