import { useSubmit } from "react-router-dom";

export function useSendActionData() {
  const submit = useSubmit();
  const submitWithIntent = async (intent, data = {}) => {
    submit(
      { intent, data: { ...data } },
      {
        method: "post",
        encType: "application/json",
      },
    );
  };
  return { submitWithIntent };
}
