import * as SmartphoneIcon from "@assets/lottie/wired-outline-246-smartphone-morph.json";
import * as SavingPigIcon from "@assets/lottie/wired-outline-453-savings-pig.json";
import * as CouponIcon from "@assets/lottie/wired-outline-1370-coupon.json";
import { Button as BleuButton, Counter } from "@bleu.builders/ui";
import Link from "@components/Link";
import BlurredBackgroundLogo from "@components/ui/BlurredBackgroundLogo";
import Button from "@components/ui/Button";
import Card from "@components/ui/Card";
import Carousel from "@components/ui/Carousel";
import { useDialog } from "@components/ui/Dialog";
import LottieIcon from "@components/ui/LottieIcon";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import { redeemStateMachine } from "@lib/states/redeem";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import toTitleCase from "@utils/toTitleCase";
import { useMachine } from "@xstate/react";
import React, { useEffect } from "react";

const ModalCardWrapper = ({ children, logoSrc, bgLogoSrc = null }) => (
  <Card.Root className="max-w-none">
    {/* @ts-expect-error TS(2322) FIXME: Type '{ bgLogo: any; src: any; }' is not assignabl... Remove this comment to see the full error message */}
    <Card.Logo bgLogo={bgLogoSrc} src={logoSrc} />
    <Card.Content>{children}</Card.Content>
  </Card.Root>
);

const WantsToAuth = ({ logo }) => {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  const { membership_challenge, name } = useProgram();

  return (
    <ModalCardWrapper logoSrc={logo}>
      <Card.Title className="text-md pl-0 text-center font-extrabold">
        🔒 Premium Access Required
      </Card.Title>
      <Card.Description className="text-md px-16 text-center">
        Support {toTitleCase(name)} and unlock over 400,000 discounts at
        businesses just like this one.
      </Card.Description>
      <div className="flex flex-col items-center justify-center px-10 text-center">
        <div className="mb-3 mt-1 flex justify-center">
          <Counter
            duration={3500}
            toValue={494267}
            fromValue={494237}
            className="text-perk-primary text-center text-4xl font-bold"
          />
          <span className="text-perk-primary text-center text-4xl font-bold">
            +
          </span>
        </div>
        <p className="mb-2 text-xs">Current Premium Member Discounts</p>
        <p className="text-sm font-bold">
          For instant access to this this offer, and thousands more:
        </p>
        <form
          action={`/participants/challenges/${membership_challenge.id}/membership_checkout_session`}
          method="post"
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; type: string; }' is not ... Remove this comment to see the full error message */}
          <Button type="submit">Join now</Button>
        </form>
      </div>
      <div className="flex flex-col items-center justify-center gap-1 py-2">
        <span className="text-base font-bold">Already a member?</span>
        <button
          className="text-sm text-black underline"
          data-toggle="modal"
          data-target="#sign_in_modal"
        >
          click here to login
        </button>
      </div>
    </ModalCardWrapper>
  );
};

const Confirmation = ({ send, logo, offer }) => {
  // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type '{}'.
  const { close } = useDialog();
  const redemptionType =
    offer.redemptionTypes.length === 1 ? offer.redemptionTypes[0] : "CLICK";

  useEffect(() => {
    send("SELECT_REDEMPTION_TYPE", { redemptionType });
  }, [redemptionType]);

  return (
    <ModalCardWrapper logoSrc={logo}>
      <Card.Title className="pl-0 text-center text-lg font-extrabold">
        Ready to Redeem?
      </Card.Title>
      <Card.Description className="mb-1 text-center text-sm">
        {offer.offerTextShort}
      </Card.Description>
      <div className="mb-2 flex w-full max-w-lg items-center justify-between gap-2 bg-yellow-100 p-1">
        <InfoCircledIcon className="size-10 text-gray-500" />
        <p className="m-0 text-sm text-gray-500">
          {redemptionType === "MOBILE"
            ? "IMPORTANT: This is a yearly, one-time use coupon. Click 'Redeem Now' ONLY if you are ready to use it at the location. Once displayed, it can not be shown again until next year."
            : "NOTE: You will be redirected to a new page where you can claim your offer."}
        </p>
      </div>
      <Card.Footer className="justify-center gap-3">
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; onClick: any; }' is not ... Remove this comment to see the full error message */}
        <Button onClick={close}>Cancel</Button>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; onClick: () => any; }' i... Remove this comment to see the full error message */}
        <Button onClick={() => send("CLICK_REDEEM")}>
          {redemptionType === "CLICK" ? "Get Discount" : "Redeem Now"}
        </Button>
      </Card.Footer>
    </ModalCardWrapper>
  );
};

const Redeeming = ({ logo }) => {
  const participantName = useParticipant().first_name;

  return (
    <ModalCardWrapper logoSrc={logo}>
      <Card.Title className="px-5 text-xl">
        {participantName} - Your exclusive code is being generated.
      </Card.Title>
      <div className="flex flex-col items-center justify-center pt-4">
        <p className="m-0">please wait...</p>
        <LottieIcon iconData={CouponIcon} width={250} height={250} />
      </div>
      <div className="mb-1 flex max-w-lg items-center justify-between gap-2 bg-yellow-100 p-1">
        <InfoCircledIcon className="size-10 text-gray-500" />
        <p className="m-0 text-sm text-gray-500">
          If the situation calls for gratuity, we kindly encourage you to
          calculate it based on the original total prior to any discounts
          applied. Thank you.
        </p>
      </div>
    </ModalCardWrapper>
  );
};

const RedeemError = ({ error, logo }) => {
  const message =
    error.redemption_data?.message ?? "You have already redeemed this offer.";

  return (
    <ModalCardWrapper logoSrc={logo}>
      <Card.Title className="px-5 text-xl">
        Whoops! Something went wrong while redeeming the offer.
      </Card.Title>
      <div className="flex flex-col items-center justify-center pt-4">
        <LottieIcon iconData={SavingPigIcon} width={200} height={200} />
        <p className="mb-2 text-center text-xs font-bold">{message}</p>
      </div>
      <div className="mb-1 flex max-w-lg items-center justify-between gap-2 bg-yellow-100 p-1">
        <InfoCircledIcon className="size-10 text-gray-500" />
        <p className="m-0 text-sm text-gray-500">
          Printable offers from our website(s) expire 14 days from the date that
          the offer was printed or as stated on the coupon. Mobile offers are
          valid for ten minutes from the redemption being completed. Please see
          offer terms for specific rules or conditions.
        </p>
      </div>
    </ModalCardWrapper>
  );
};

const RedemptionInfo = ({ logo, data, offer, redemptionType }) => {
  // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type '{}'.
  const { close } = useDialog();

  if (redemptionType === "CLICK") {
    window.open(offer["webSite"], "_blank");
    close();
    return null;
  }

  const voucherUri = data?.redemption_data?.coupon?.voucher;
  const decodedVoucherUri = voucherUri.replace(/&amp;/g, "&");

  return (
    <Card.Root className="max-w-none">
      <div className="relative flex min-h-20 items-center justify-center">
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; }'... Remove this comment to see the full error message */}
        <BlurredBackgroundLogo.Root className="min-h-20">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ src: any; }' is not assignable to type 'In... Remove this comment to see the full error message */}
          <BlurredBackgroundLogo.Background src={logo} />
          <div className="flex max-h-20 w-full max-w-72 items-center justify-center object-contain">
            <LottieIcon iconData={SmartphoneIcon} width={60} height={60} />
            <h2 className="justify-left z-20 pt-1 text-left text-lg font-semibold text-black">
              Show this screen to receive your discount.
            </h2>
          </div>
        </BlurredBackgroundLogo.Root>
        <div className="absolute left-[-10%] top-[-10%] h-[110%] w-[120%] max-w-[unset] border-2 border-dashed border-black/25" />
      </div>
      <Card.Content>
        <img src={decodedVoucherUri} alt="voucher" />
      </Card.Content>
    </Card.Root>
  );
};

const buttonName = {
  signup: "Sign up",
  video: "Watch Now",
  article: "Read Now",
  referral: "Recruit Friends",
  membership: "Become a Member",
  share: "Share",
  collect: "Take",
  link: "Visit Now",
  call_action: "Visit Now",
  engage: "Visit Social Media",
  unified_social: "Check out posts",
  donation: "Donate",
};

function ChallengeCard({ challenge }) {
  if (challenge.challenge_type === "engage") {
    return null;
  }

  return (
    <div className="flex max-h-40 w-full items-center justify-center lg:max-h-none">
      <div
        className="relative mb-3 w-5/6 rounded-lg bg-cover bg-center p-4 shadow transition-all duration-300 ease-in-out hover:shadow-md md:p-8"
        style={{
          backgroundImage: `linear-gradient(var(--brand-tile-background-overlay), var(--brand-tile-background-overlay)),url("${challenge.image?.url}")`,
        }}
      >
        <div className="w-full text-left">
          {challenge.icon && (
            <img
              className="float-right size-8"
              src={challenge.icon.url}
              alt="challenge icon"
            />
          )}
        </div>
        <div className="flex w-full flex-col items-center lg:items-start">
          <h3 className="custom_h3 mb-4 text-base lg:text-lg">
            {challenge.caption}
          </h3>
          <ActionButtion challenge={challenge} />
        </div>
      </div>
    </div>
  );
}

const ActionButtion = ({ challenge }) => {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();
  return (
    <>
      {challenge.challenge_type === "membership" ? (
        <form
          action={`/public/challenges/${challenge.id}/membership_checkout_session`}
          method="post"
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <BleuButton
            className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button text-sm md:text-base md:py-3 md:px-6 focus:outline-none"
            type="submit"
          >
            Become a member
          </BleuButton>
        </form>
      ) : (
        <Link to={`/challenge/${challenge.identifier}`}>
          <BleuButton className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button text-sm md:text-base md:py-3 md:px-6 focus:outline-none">
            {buttonName[challenge.challenge_type]}
          </BleuButton>
        </Link>
      )}
    </>
  );
};

const WantsToBeAMember = ({ logo }) => {
  const { challenges_with_offer_rewards } = useProgram();

  return (
    <ModalCardWrapper logoSrc={logo}>
      <Card.Title className="text-md pl-0 text-center font-extrabold">
        🔒 Premium Access Required
      </Card.Title>
      <Card.Description className="text-md px-16 text-center">
        Unlock over 400,000 discounts at businesses just like this one.
      </Card.Description>
      <div className="flex flex-col items-center justify-center px-10 text-center">
        <div className="mb-3 mt-1 flex justify-center">
          <Counter
            duration={3500}
            toValue={494267}
            fromValue={494237}
            className="text-perk-primary text-center text-2xl font-bold lg:text-4xl"
          />
          <span className="text-perk-primary text-center text-2xl font-bold lg:text-4xl">
            +
          </span>
        </div>
        <p className="text-sm font-bold">
          For instant access to this this offer, and thousands more, complete a
          challenge:
        </p>
      </div>
      {/* @ts-expect-error TS(2559) FIXME: Type '{ children: Element[]; }' has no properties ... Remove this comment to see the full error message */}
      <Carousel.Root>
        {/* @ts-expect-error TS(2559) FIXME: Type '{ children: any; }' has no properties in com... Remove this comment to see the full error message */}
        <Carousel.Items>
          {challenges_with_offer_rewards.map((challenge) => (
            <ChallengeCard challenge={challenge} key={challenge.id} />
          ))}
        </Carousel.Items>
        <Carousel.Indicator />
        {/* @ts-expect-error TS(2322) FIXME: Type '{ role: string; className: string; }' is not... Remove this comment to see the full error message */}
        <Carousel.Control role="left" className="-left-6 -top-1" />
        {/* @ts-expect-error TS(2322) FIXME: Type '{ role: string; className: string; }' is not... Remove this comment to see the full error message */}
        <Carousel.Control role="right" className="-right-6 -top-1" />
      </Carousel.Root>
    </ModalCardWrapper>
  );
};

export default function RedeemCoupon({ offer, merchant }) {
  const participant = useParticipant();
  const [state, send] = useMachine(redeemStateMachine, {
    context: { participant, offer },
  });
  const { redemption, redemptionError, redemptionType } = state.context;

  const logo = merchant.logoPath;

  switch (state.value) {
    case "unauthenticated":
      return null;

    case "participantAuthenticationPending":
      return <WantsToAuth logo={logo} />;

    case "participantAuthorizationPending":
      return <WantsToBeAMember logo={logo} />;

    case "redeemConfirmationPending":
      return <Confirmation send={send} logo={logo} offer={offer} />;

    case "redeemInProgress":
      return <Redeeming logo={logo} />;

    case "redeemError":
      return <RedeemError error={redemptionError} logo={logo} />;

    case "redeemSuccess":
      return (
        <RedemptionInfo
          logo={logo}
          data={redemption}
          offer={offer}
          redemptionType={redemptionType}
        />
      );

    default:
      return null;
  }
}
