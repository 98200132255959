import { SWRDataTable } from "@bleu.builders/ui";
import React from "react";
import { useParams } from "react-router-dom";

function ParticipantAnswersTable() {
  const { end_user_id } = useParams();

  return (
    <SWRDataTable
      searchKey="answer"
      fetchPath={`/admin/v2/participants/${end_user_id}/participant_answers`}
    />
  );
}

export default ParticipantAnswersTable;
