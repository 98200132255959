import {
  Button,
  ClickToCopy,
  formatDateTime,
  Input,
  Label,
} from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import { FieldsLoaderData } from "@lib/pageBuilder/pagesHelper";
import * as React from "react";
import { useActionData, useLoaderData } from "react-router-dom";

import { SettingsCard } from "../../(components)/SettingsCard";

interface ActionData {
  data: {
    success: boolean;
    data: {
      name: string;
      api_key: string;
      expires_at: string;
    };
  };
}

export default function IntegrationApiAuthNew() {
  const { data } = useLoaderData() as FieldsLoaderData;
  const actionData = useActionData() as ActionData;

  if (!actionData?.data?.success) {
    return (
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={data.defaultValues}
          fields={data.fields}
          title={"Create API Key"}
          action={{ path: "/admin/v2/programs/:program_id/api_auths" }}
        />
      </div>
    );
  }

  const Field = ({ label, value }) => (
    <div className="flex flex-col gap-y-2">
      <Label>{label}</Label>
      <Input placeholder={`${label}`} value={value} disabled />
    </div>
  );

  return (
    <div className="flex p-6 flex-col gap-y-4 my-8">
      <p className="bg-destructive/20 rounded-xl p-4 border border-destructive">
        Make sure to copy your API Key now. You won’t be able to see it again!
      </p>
      <Card.Root className="max-w-full border dark:border-2 shadow-sm bg-background">
        <Card.Content className="p-6">
          <Card.Title className="mt-0 p-0 text-xl font-semibold text-foreground mb-2 flex justify-between items-center w-full">
            <span>API Key</span>
            <Button
              onClick={() => {
                window.history.back();
              }}
            >
              Go back
            </Button>
          </Card.Title>
          <div className="flex flex-col gap-y-4">
            <Field label="Name" value={actionData?.data?.data.name} />
            <Field
              label="Expires At"
              value={formatDateTime(actionData?.data?.data.expires_at)}
            />
            <div className="flex flex-col gap-y-2">
              <Label>Value</Label>
              <div className="flex items-center gap-x-2">
                <Input
                  placeholder="API Key Value"
                  value={actionData?.data?.data.api_key}
                  disabled
                />
                <ClickToCopy
                  text={actionData?.data?.data.api_key}
                  className="border-2 border-input/50 rounded-md h-10 px-2"
                >
                  <button className="ml-2 text-sm font-semibold text-primary">
                    Copy
                  </button>
                </ClickToCopy>
              </div>
            </div>
          </div>
        </Card.Content>
      </Card.Root>
    </div>
  );
}
