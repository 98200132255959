// JsFromRoutes CacheKey 0b2ad58ea5b16c9d0fc82f0a0798fb04
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  toggleUserStatus: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/users/:id/toggle_user_status",
  ),
  index: /* #__PURE__ */ definePathHelper("get", "/admin/v2/users"),
  create: /* #__PURE__ */ definePathHelper("post", "/admin/v2/users"),
  new: /* #__PURE__ */ definePathHelper("get", "/admin/v2/users/new"),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/users/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/users/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/users/:id"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/admin/v2/users/:id"),
};
