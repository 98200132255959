// JsFromRoutes CacheKey a8801d032f477e91dfefeed6007aaf06
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/carousels",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/carousels",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/carousels/new",
  ),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/carousels/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/carousels/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/carousels/:id"),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/carousels/:id",
  ),
};
