import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import { TableContext } from "./TableContext";

const defaultFilterFn = (row, id, filterValue) =>
  row.getValue(id).includes(filterValue);

const buildColumns = (columnsConfig, filters) => {
  if (!columnsConfig) return [];

  return columnsConfig.map((columnConfig) => ({
    ...columnConfig,
    filterFn: columnConfig.filterable ? defaultFilterFn : null,
  }));
};

export function DataTable({
  children,
  data,
  error,
  tableState,
  setTableState,
  buildTableColumns = buildColumns,
}) {
  const { pagination, rowSelection, columnVisibility, columnFilters, sorting } =
    tableState;

  const {
    setPagination,
    setRowSelection,
    setColumnVisibility,
    setColumnFilters,
    setSorting,
  } = setTableState;

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const columns = buildTableColumns(data?.columns, data?.filters);
  const filters = data?.filters;
  const searchKey = data?.search?.key;

  const table = useReactTable({
    data: data?.data ?? [],
    pageCount: Math.ceil((data?.total ?? 0) / pagination.pageSize),
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      pagination: pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const contextValue = {
    data,
    filters,
    error,
    tableState,
    setTableState,
    table,
    searchKey,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
}
