import { useParticipant } from "@contexts/ParticipantContext";
import { client } from "@utils/api-client";
import React from "react";
import { defer, useLoaderData } from "react-router-dom";

import { EndUserLayout } from "../Layout";
import { LoggedPage } from "./LoggedPage";
import { NotLoggedPage } from "./NotLoggedPage";

const loader = async ({ request }) => {
  const url = new URL(request.url);
  const sessionId = url.searchParams.get("session_id");
  const [
    rewardsData,
    carouselData,
    socialFeedData,
    challengeData,
    donationData,
    offersData,
    currentRewardsData,
    claimedRewardsData,
    stripeReceiptData,
  ] = await Promise.all([
    client(`/public/home/active_rewards`),
    client(`/public/home/carousel`),
    client(`/public/home/social_feed_challenge`),
    client(`/public/home/active_uncompleted_challenges`),
    client(`/public/home/donation_challenge`),
    client(`/public/home/offers_reward`),
    client(`/public/home/current_rewards`),
    client(`/public/home/claimed_rewards`),
    client(`/public/home/stripe_receipt?session_id=${sessionId || ""}`),
  ]);

  return defer({
    rewards: rewardsData,
    carousel: carouselData,
    socialFeed: socialFeedData,
    challenges: challengeData,
    donation: donationData,
    offers: offersData,
    currentRewards: currentRewardsData,
    claimedRewards: claimedRewardsData,
    stripeReceipt: stripeReceiptData,
  });
};

export default function HomePage() {
  const participant = useParticipant();
  return (
    <EndUserLayout>
      {participant ? <LoggedPage /> : <NotLoggedPage />}
    </EndUserLayout>
  );
}

HomePage.loader = loader;
