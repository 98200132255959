export function formatDate(date: Date | string | number) {
  return Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date));
}

export function formatDateTime(date: Date | string | number) {
  return Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }).format(new Date(date));
}

export function formatDateTimeCompact(date: Date | string | number) {
  return new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  }).format(new Date(date));
}

export function formatTime(date: Date | string | number) {
  return Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(date));
}

export function formatDateTimeToNow(date: Date | string | number) {
  const now = new Date();
  const diff = now.getTime() - new Date(date).getTime();
  if (diff < 1000) {
    return "just now";
  } else if (diff < 60000) {
    return `${Math.floor(diff / 1000)} seconds ago`;
  } else if (diff < 3600000) {
    return `${Math.floor(diff / 60000)} minutes ago`;
  } else if (diff < 86400000) {
    return `${Math.floor(diff / 3600000)} hours ago`;
  } else {
    const dateTime = Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(date));
    return `on ${dateTime}`;
  }
}

export function distanceOfTimeInWords(
  fromDate: Date | string,
  toDate: Date | string,
): string {
  const from = fromDate instanceof Date ? fromDate : new Date(fromDate);
  const to = toDate instanceof Date ? toDate : new Date(toDate);

  const diffInSeconds = Math.floor((to.getTime() - from.getTime()) / 1000);

  const days = Math.floor(diffInSeconds / (24 * 60 * 60));
  const hours = Math.floor((diffInSeconds % (24 * 60 * 60)) / (60 * 60));

  let result = "";

  if (days > 0) {
    result += `${days}d `;
  }

  if (hours > 0 || days > 0) {
    result += `${hours}h`;
  }

  return result.trim();
}
