import { CommandMenu, ModeToggle, Toaster, useToast } from "@bleu.builders/ui";
import { useProgram } from "@contexts/ProgramContext";
import { useRailsFlash } from "@contexts/RailsFlashContext";
import { useUser } from "@contexts/UserContext";
import toTitleCase from "@utils/toTitleCase";
import React from "react";
import { Outlet } from "react-router-dom";

import { AddNewProgramDialog } from "./(components)/AddNewProgram";
import { MainNav } from "./(components)/MainNav";
import { ProgramSwitcher } from "./(components)/ProgramSwitcher";
import { SidebarNav } from "./(components)/SidebarNav";
import { UserNav } from "./(components)/UserNav";

const adminCommands = (program_id) => {
  return {
    mainNav: [
      {
        title: "Dashboard",
        href: `/admin/v2/programs/${program_id}/dashboard`,
      },
      {
        title: "Rewards",
        href: `/admin/v2/programs/${program_id}/rewards`,
      },
      {
        title: "Challenges",
        href: `/admin/v2/programs/${program_id}/challenges`,
      },
      {
        title: "Settings | General",
        href: `/admin/v2/programs/${program_id}/settings`,
      },
      {
        title: "Settings | Program Pages",
        href: `/admin/v2/programs/${program_id}/settings/program/program-pages`,
      },
      {
        title: "Settings | Meta tags / SEO",
        href: `/admin/v2/programs/${program_id}/settings/program/seo`,
      },
      {
        title: "Settings | Profile Attributes",
        href: `/admin/v2/programs/${program_id}/settings/program/profile-attributes`,
      },
      {
        title: "Settings | Your Label",
        href: `/admin/v2/programs/${program_id}/settings/program/labels`,
      },
      {
        title: "Settings | Header Navigations Menu",
        href: `/admin/v2/programs/${program_id}/settings/program/header-navigations-menu`,
      },
      {
        title: "Integrations | Social Feed",
        href: `/admin/v2/programs/${program_id}/settings/integrations/social-network-pages`,
      },
      {
        title: "Integrations | Offers Reward",
        href: `/admin/v2/programs/${program_id}/settings/integrations/offers-reward`,
      },
      {
        title: "Integrations | Photo Contests",
        href: `/admin/v2/programs/${program_id}/settings/integrations/photo-contests`,
      },
      {
        title: "Create program",
        href: `/admin/v2/organization/programs#add-new-program`,
      },
      {
        title: "Create challenge",
        href: `/admin/v2/programs/${program_id}/challenges/new`,
      },
      {
        title: "Create reward",
        href: `/admin/v2/programs/${program_id}/rewards/new`,
      },
      {
        title: "Organization | Invite admin",
        href: `/admin/v2/organization/admins#invite`,
      },
      {
        title: "Organization | Programs",
        href: `/admin/v2/organization/programs`,
      },
      {
        title: "Organization | Admins",
        href: `/admin/v2/organization/admins`,
      },
    ],
  };
};

export function useFlashMessages() {
  const flashMessages = useRailsFlash();
  const { toast } = useToast();

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      flashMessages?.forEach((flash) => {
        toast({
          title: toTitleCase(flash.type),
          description: flash.message,
          variant: flash.type === "error" ? "destructive" : "default",
        });
      });
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);
}

function NoProgramLayout() {
  useFlashMessages();

  return (
    <>
      <Toaster />
      <div className="flex-col md:flex">
        <div className="border-b dark:border-b-2">
          <div className="flex h-16 items-center px-4">
            <AddNewProgramDialog
              description={
                "To continue, create your organization's first program."
              }
            />
            <div className="ml-auto flex items-center space-x-4">
              <UserNav />
              <ModeToggle />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Layout() {
  const { programs } = useUser();
  const program = useProgram();
  useFlashMessages();

  if (programs.length === 0) {
    return <NoProgramLayout />;
  }

  const programUrl = program.subdomain_url;

  return (
    <>
      <Toaster />
      <div className="flex-col md:flex bg-background text-foreground h-full min-h-screen">
        <div className="border-b">
          <div className="flex h-16 items-center px-4">
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ campaig... Remove this comment to see the full error message */}
            <ProgramSwitcher program={program} userPrograms={programs} />
            <MainNav className="mx-6" program={program} />
            <div className="ml-auto flex items-center space-x-4">
              <CommandMenu
                //  @ts-ignore expecting sidebar command
                commands={adminCommands(program.id)}
                placeholder="Global search"
              />
              {/* @ts-ignore */}
              <UserNav programUrl={programUrl} />
              <ModeToggle />
            </div>
          </div>
        </div>
        <div className="flex-1 space-y-4 pt-0">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export const WithSidebarLayout = ({ items }) => {
  return (
    <div className="container w-full items-start flex">
      <aside className="z-30 -ml-2 hidden shrink-0 md:sticky md:block h-[calc(100vh-4rem)]">
        <SidebarNav items={items} />
      </aside>

      <div className="mb-8 size-full flex-1 flex-col space-y-8 px-2 py-0 md:flex pl-10">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
