import Card from "@components/ui/Card";
import Dialog from "@components/ui/Dialog";
import { useProgram } from "@contexts/ProgramContext";
import React from "react";
import { useLoaderData } from "react-router-dom";

export default function StripeReceiptInfo() {
  // @ts-ignore
  const { stripeReceipt } = useLoaderData();
  const { fetch_stripe_receipt_info } = stripeReceipt;

  if (!fetch_stripe_receipt_info) return null;
  const { title, message, participant_name } = fetch_stripe_receipt_info;
  const program = useProgram();

  const programLogo = program?.program_template_detail?.header_logo?.public_url;
  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; defaultOpen: boolean; }... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={true}>
      <Dialog.Content className="sm:max-w-[550px]">
        <Card.Root className="max-w-none">
          <Card.Header className="flex items-center justify-center">
            <img
              src={programLogo}
              alt="programLogo"
              className="max-h-[160px] max-w-[160px]"
            />
          </Card.Header>
          <Card.Content className="flex flex-col items-center justify-center gap-3 px-10">
            <h1 className="text-4xl">{title}</h1>
            <div className="text-lg font-bold text-gray-500">
              Hey {participant_name} 👋
            </div>
            <div className="text-center text-lg ">{message}</div>
            {/* @ts-expect-error TS(2559) FIXME: Type '{ children: string; }' has no properties in ... Remove this comment to see the full error message */}
            <Dialog.CloseButton>OK</Dialog.CloseButton>
          </Card.Content>
        </Card.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
}
