import { adminV2Coupons } from "@api/index";
import { SWRDataTable } from "@bleu.builders/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { AddCouponsDialog } from "./(components)/AddCoupons";

function RewardsCouponsTable() {
  const { reward_id } = useParams();

  return (
    <SWRDataTable
      fetchPath={adminV2Coupons.index.path({ reward_id })}
      searchKey="code"
      action={<AddCouponsDialog />}
    />
  );
}

export default RewardsCouponsTable;
