import React, { createContext, useContext } from "react";

export const ProgramContext = createContext(null);

export const ProgramProvider = ({ children, program }) => {
  return (
    <ProgramContext.Provider value={program}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgram = () => {
  return useContext(ProgramContext);
};
