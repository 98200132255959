import { Form } from "@bleu.builders/ui";
import { SubmitButton } from "@components/SubmitButton";
import Card from "@components/ui/Card";
import { buildForm } from "@utils/buildForm";
import { getObjectsDifference } from "@utils/objectDifference";
import { toSlug } from "@utils/toSlug";
import { serialize } from "object-to-formdata";
import React from "react";
import { useForm } from "react-hook-form";
import { FormEncType, useSubmit } from "react-router-dom";

export function SettingsCard({
  title,
  fields,
  defaultValues,
  footerContent = undefined,
  actionText = "Save",
  action = undefined,
  encType = "multipart/form-data" as FormEncType,
  _method = "patch",
  method = "post",
  useFormSubmit = false,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const form = useForm({
    defaultValues,
  });

  const submit = useSubmit();

  const handleSubmit = async () => {
    if (useFormSubmit) return;

    const isValid = await form.trigger();
    if (!isValid) return;

    // if the mode is "update", we only send the changed values
    const values =
      _method === "patch"
        ? form.getValues()
        : getObjectsDifference(defaultValues, form.getValues());
    const formData = serialize(values);

    submit(formData, { method: "post", encType });
  };

  const fieldNameContainsDomain = fields
    .map((field) => field.name)
    .includes("domain");

  return (
    <Card.Root className="max-w-full border dark:border-2 shadow-sm bg-background">
      <Form
        {...form}
        action={action.path}
        method={method as "post" | "get"}
        encType={
          encType as "application/x-www-form-urlencoded" | "multipart/form-data"
        }
        onSubmit={() => setIsSubmitting(true)}
      >
        <input name="_method" type="hidden" value={_method} />
        <Card.Content className="p-6">
          <Card.Title className="mt-0 p-0 text-xl font-semibold text-foreground mb-2">
            {title}
          </Card.Title>
          <div className="flex flex-wrap gap-4">{buildForm(fields, form)}</div>
        </Card.Content>
        <Card.Footer
          className={"flex justify-between border-t px-6 py-3 text-sm"}
        >
          {fieldNameContainsDomain && <ProgramUrl form={form} />}
          {footerContent ? footerContent : <div />}
          <SubmitButton
            isSubmitting={isSubmitting}
            className="h-8"
            type={useFormSubmit ? "submit" : "button"}
            onClick={handleSubmit}
          >
            {actionText}
          </SubmitButton>
        </Card.Footer>
      </Form>
    </Card.Root>
  );
}

function ProgramUrl({ form }) {
  const getFrontEndUrl = () => {
    const port = window.location.port;
    const isDevelopment = process.env.NODE_ENV === "development";
    const domainType = form.watch("domain_type");
    const domain = form.watch("domain");

    if (domainType === "include_in_domain") {
      const origin = window.location.origin.split(".");
      origin.splice(1, 0, toSlug(domain), ".");
      origin.splice(4, 0, ".");
      return origin.join("");
    } else {
      if (isDevelopment && port) {
        return `https://${domain}:${port}`;
      }
      return `https://${domain}`;
    }
  };

  const url = getFrontEndUrl();
  return (
    <span>
      Domain preview:{" "}
      <a target="_blank" href={url} className="hover:underline">
        {url}
      </a>
    </span>
  );
}
