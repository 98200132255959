import { Spinner, toast } from "@bleu.builders/ui";
import { useParticipant } from "@contexts/ParticipantContext";
import { client } from "@utils/api-client";
import React, { useEffect, useRef } from "react";
import {
  Await,
  defer,
  json,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";

import { EndUserLayout } from "../Layout";
import { Reward } from "../types";
import { AchievementReward } from "./Achievement";
import { OffersReward } from "./Offers";
import { RedeemReward } from "./Redeem";
import { RewardLayout } from "./RewardLayout";
import { SweepstakesReward } from "./Sweepstakes";

const action = async ({ request }) => {
  const formData = await request.formData();
  const data = await client("/public/rewards/process_reward", {
    body: formData,
    method: "POST",
  });
  return json({ data });
};

const loader = async ({ params }) => {
  const { reward_id } = params;
  try {
    const [rewardData] = await Promise.all([
      client(`/public/rewards/${reward_id}`),
    ]);

    if (!rewardData) {
      return redirect("/");
    }

    return defer({
      reward: rewardData,
    });
  } catch (error) {
    return redirect("/");
  }
};

const REWARD_COMPONENTS = {
  sweepstake: SweepstakesReward,
  achievement: AchievementReward,
  redeem: RedeemReward,
  offer: OffersReward,
} as const;

function RewardContent({
  data,
  handleSubmit,
}: {
  data: { reward: Reward };
  handleSubmit: (customData?: FormData) => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, []);

  if (
    !data?.reward?.selection ||
    !REWARD_COMPONENTS[
      data?.reward?.selection as keyof typeof REWARD_COMPONENTS
    ]
  ) {
    return (
      <div
        className="z-[1450] w-full flex justify-center overflow-y-auto focus:outline-none"
        tabIndex={-1}
        ref={contentRef}
      >
        <RewardLayout reward={data.reward} />
      </div>
    );
  }

  const RewardComponent =
    REWARD_COMPONENTS[
      data?.reward?.selection as keyof typeof REWARD_COMPONENTS
    ];

  if (!RewardComponent) {
    return null;
  }

  return (
    <div
      className="z-[1450] w-full flex justify-center overflow-y-auto focus:outline-none"
      tabIndex={-1}
      ref={contentRef}
    >
      <RewardComponent data={data} handleSubmit={handleSubmit} />
    </div>
  );
}

export function RewardPage() {
  const params = useParams();
  const participant = useParticipant();
  const navigate = useNavigate();
  const submit = useSubmit();

  const data = useLoaderData() as {
    reward: Promise<Reward>;
  };

  const actionData = useActionData() as {
    data: { message?: string; stripe_url?: string; success: boolean };
  };

  useEffect(() => {
    if (!participant) {
      const path = `/signin?redirect_to=${location.pathname}${location.search}`;
      navigate(path);
    }
  }, [participant]);

  useEffect(() => {
    if (actionData?.data?.success) {
      if (actionData?.data?.stripe_url) {
        window.location.href = actionData?.data?.stripe_url;
        return;
      } else {
        toast({
          title: "Congrats!",
          description:
            actionData?.data?.message ||
            "You have successfully claimed this reward",
        });
        navigate("/");
      }
    } else if (!actionData?.data?.success && actionData?.data?.message) {
      toast({
        title: "Something went wrong",
        description: actionData?.data?.message,
        variant: "destructive",
      });
    }
  }, [actionData]);

  if (participant === null) {
    const path = `/signin?redirect_to=${location.pathname}${location.search}`;
    navigate(path);
  }

  function Loading() {
    return (
      <div className="flex justify-center items-center flex-col w-full">
        <Spinner />
        <p className="mt-4 text-lg">Loading, please wait...</p>
      </div>
    );
  }

  const handleSubmit = async (customData?: FormData) => {
    const formData = customData || new FormData();

    if (params && params.reward_id) {
      formData.append("reward_id", params.reward_id);

      submit(formData, { method: "post", encType: "multipart/form-data" });
    }
  };

  return (
    <div>
      <div className="fixed inset-0 z-[1400] bg-black/60 backdrop-blur-[1px] overflow-y-auto"></div>
      <EndUserLayout className="my-10">
        <React.Suspense fallback={<Loading />}>
          <Await resolve={data} errorElement={<p>Error loading challenge</p>}>
            {(resolvedData) => (
              <RewardContent data={resolvedData} handleSubmit={handleSubmit} />
            )}
          </Await>
        </React.Suspense>
      </EndUserLayout>
    </div>
  );
}

RewardPage.loader = loader;
RewardPage.action = action;
