import {
  capitalize,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  cn,
  Separator,
} from "@bleu.builders/ui";
import Link from "@components/Link";
import Card from "@components/ui/Card";
import { BanknotesIcon, RssIcon } from "@heroicons/react/24/solid";
import {
  CalendarIcon,
  ClipboardCopyIcon,
  ClockIcon,
  CommitIcon,
  DownloadIcon,
  EnvelopeClosedIcon,
  ExternalLinkIcon,
  FileTextIcon,
  GlobeIcon,
  HeartFilledIcon,
  Link1Icon,
  PersonIcon,
  QuestionMarkCircledIcon,
  SewingPinFilledIcon,
  Share2Icon,
  StarIcon,
  UploadIcon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { formatDate, formatTime } from "@utils/formatDate";
import Autoplay from "embla-carousel-autoplay";
import React from "react";

const ACTIONS_ICON = {
  sign_up: <PersonIcon className="text-gold size-6" />,
  sign_in: <PersonIcon className="text-gold size-6" />,
  connect: <Link1Icon className="text-gold size-6" />,
  watch_video: <VideoIcon className="text-gold size-6" />,
  visit_url: <GlobeIcon className="text-gold size-6" />,
  read_article: <FileTextIcon className="text-gold size-6" />,
  quiz: <QuestionMarkCircledIcon className="text-gold size-6" />,
  survey: <QuestionMarkCircledIcon className="text-gold size-6" />,
  share: <Share2Icon className="text-gold size-6" />,
  recruit: <EnvelopeClosedIcon className="text-gold size-6" />,
  onboarding_questions: <ClipboardCopyIcon className="text-gold size-6" />,
  location_visit: <SewingPinFilledIcon className="text-gold size-6" />,
  feed: <RssIcon className="text-gold size-6" />,
  claim_reward: <HeartFilledIcon className="text-gold size-6" />,
  external: <ExternalLinkIcon className="text-gold size-6" />,
  donation: <BanknotesIcon className="text-gold size-6" />,
  membership: <PersonIcon className="text-gold size-6" />,
  submitted_content: <UploadIcon className="text-gold size-6" />,
  rate_content: <StarIcon className="text-gold size-6" />,
  import: <DownloadIcon className="text-gold size-6" />,
};

function ActionIcon({ type }) {
  const DefaultIcon = <CommitIcon className="text-gold size-6" />;
  return ACTIONS_ICON[type] || DefaultIcon;
}

function ActionItem({ item }) {
  return (
    <Card.Root className="size-full p-0 m-0 shadow-none bg-foreground/5 dark:bg-background">
      <Card.Content className="flex flex-col justify-between h-full p-5 gap-5">
        <div className="flex space-y-2 flex-col">
          <span>
            <ActionIcon type={item.action_type} />
          </span>
          <h1 className="text-foreground">
            <Link
              to={item.details_url}
              className="underline underline-offset-2"
            >
              {capitalize(item.name)}
            </Link>{" "}
            -{" "}
            {item.actionable_url ? (
              <Link
                to={item.actionable_url}
                className="underline underline-offset-2"
              >
                {item.title}
              </Link>
            ) : (
              item.title
            )}
          </h1>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center space-x-2 mb-1">
            <CalendarIcon className="text-gold size-4" />
            <span className="text-foreground text-sm font-light">
              {formatDate(item.created_at)}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <ClockIcon className="text-gold size-4" />
            <span className="text-foreground text-sm font-light">
              {formatTime(item.created_at)}
            </span>
          </div>
        </div>
      </Card.Content>
    </Card.Root>
  );
}

export function DashboardLatestActions({ data }) {
  const plugin = React.useRef(
    Autoplay({ delay: 3000, stopOnInteraction: true, playOnInit: true }),
  );

  return (
    <div className="flex flex-col gap-y-2 mb-5">
      <div className="flex items-center gap-x-2">
        <h2 className="text-2xl font-bold whitespace-nowrap">Latest Actions</h2>
        <div className="grow">
          <Separator />
        </div>
      </div>
      <Carousel
        plugins={[plugin.current]}
        className="size-full"
        opts={{ align: "start", loop: true }}
      >
        <CarouselContent className="gap-3 ml-2">
          {data.length > 0 ? (
            data.map((item, index) => (
              <CarouselItem
                key={index}
                className={cn(
                  "md:basis-1/3 lg:basis-1/6 border-2 rounded-lg pl-0",
                  index === data.length - 1 && "mr-4",
                )}
              >
                <ActionItem item={item} />
              </CarouselItem>
            ))
          ) : (
            <CarouselItem>
              <div className="flex justify-center items-center size-full h-20">
                <span className="text-foreground">No results found.</span>
              </div>
            </CarouselItem>
          )}
        </CarouselContent>
        <CarouselNext className="-right-0" />
        <CarouselPrevious className="left-4" />
      </Carousel>
    </div>
  );
}
