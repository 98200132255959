import { cn } from "@bleu.builders/ui";
import React from "react";

export function ChallegeIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 511.997 511.997"
      className={cn("size-24", className)}
      fill="currentColor"
    >
      <g>
        <g>
          <path
            d="M335.99,160.828c-1.177-3.62-4.308-6.257-8.074-6.804l-43.503-6.31L264.948,108.3c-1.686-3.412-5.161-5.572-8.966-5.572
            h-0.002c-3.807,0.001-7.282,2.163-8.966,5.577l-19.443,39.424l-43.5,6.333c-3.767,0.548-6.895,3.188-8.07,6.808
            s-0.193,7.594,2.533,10.25l31.486,30.675l-7.419,43.327c-0.643,3.752,0.901,7.543,3.981,9.78c1.742,1.265,3.804,1.908,5.876,1.908
            c1.592,0,3.191-0.38,4.655-1.15l38.903-20.466l38.914,20.445c3.369,1.771,7.452,1.475,10.531-0.764
            c3.079-2.238,4.62-6.03,3.976-9.781l-7.442-43.323l31.47-30.691C336.188,168.422,337.167,164.448,335.99,160.828z
            M284.266,191.124c-2.356,2.298-3.431,5.608-2.874,8.852l4.902,28.535l-25.631-13.466c-2.913-1.531-6.394-1.53-9.307,0.002
            l-25.624,13.48l4.887-28.538c0.556-3.244-0.521-6.554-2.878-8.851l-20.738-20.204l28.651-4.171
            c3.257-0.474,6.072-2.521,7.528-5.472l12.806-25.966l12.821,25.96c1.458,2.951,4.274,4.996,7.531,5.468l28.653,4.156
            L284.266,191.124z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M480.738,439.367l-82.656-143.77c24.961-31.158,39.916-70.661,39.916-113.597c0-100.355-81.645-182-182-182
            s-182,81.645-182,182c0,42.936,14.954,82.439,39.916,113.597l-82.656,143.77c-1.898,3.302-1.756,7.396,0.367,10.559
            c2.123,3.162,5.862,4.844,9.633,4.337l64.513-8.663l24.754,60.201c1.45,3.525,4.778,5.92,8.582,6.174
            c0.224,0.015,0.447,0.022,0.669,0.022c3.555,0,6.864-1.893,8.658-5l83.443-144.527c0.013-0.022,0.022-0.045,0.035-0.068
            c7.884,1.047,15.922,1.597,24.088,1.597s16.204-0.55,24.088-1.597c0.013,0.022,0.022,0.045,0.035,0.068l83.443,144.527
            c1.794,3.107,5.104,5,8.658,5c0.222,0,0.445-0.007,0.669-0.022c3.804-0.254,7.132-2.649,8.582-6.174L406.23,445.6l64.513,8.663
            c3.779,0.507,7.51-1.175,9.633-4.337C482.493,446.763,482.636,442.669,480.738,439.367z M141.284,479.379l-18.02-43.822
            l16.171-26.389c2.886-4.709,1.408-10.866-3.301-13.751s-10.866-1.408-13.751,3.301l-16.341,26.666l-47.33,6.355l69.276-120.496
            c22.692,22.477,51.248,39.04,83.144,47.156L141.284,479.379z M255.997,344c-89.327,0-162-72.673-162-162s72.673-162,162-162
            s162,72.673,162,162S345.324,344,255.997,344z M405.953,425.384l-16.341-26.666c-2.885-4.709-9.042-6.188-13.751-3.301
            c-4.709,2.886-6.187,9.042-3.301,13.751l16.171,26.389l-18.02,43.822l-69.848-120.98c31.896-8.116,60.453-24.679,83.144-47.156
            l69.276,120.496L405.953,425.384z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.997,52c-22.316,0-44.333,5.752-63.672,16.636c-4.813,2.708-6.519,8.806-3.811,13.619s8.806,6.521,13.619,3.811
            C218.484,76.864,237.11,72,255.997,72c60.654,0,110,49.346,110,110c0,16.574-3.591,32.499-10.673,47.332
            c-2.379,4.984-0.268,10.953,4.716,13.333c1.391,0.664,2.857,0.978,4.302,0.978c3.732,0,7.315-2.101,9.031-5.694
            c8.376-17.545,12.624-36.369,12.624-55.949C385.997,110.318,327.68,52,255.997,52z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M349.05,258.422c-3.963-3.846-10.294-3.751-14.14,0.212l-0.222,0.228c-3.858,3.951-3.783,10.283,0.168,14.141
            c1.945,1.899,4.466,2.845,6.985,2.845c2.599,0,5.197-1.007,7.156-3.014l0.264-0.271C353.108,268.6,353.013,262.269,349.05,258.422z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M322.586,282.345c-2.665-4.837-8.747-6.598-13.584-3.932C292.871,287.302,274.542,292,255.997,292
            c-60.654,0-110-49.346-110-110c0-15.889,3.311-31.213,9.84-45.546c2.29-5.026,0.071-10.956-4.955-13.246
            c-5.026-2.289-10.956-0.071-13.246,4.955c-7.724,16.954-11.64,35.067-11.64,53.837c0,71.682,58.318,130,130,130
            c21.911,0,43.578-5.557,62.658-16.071C323.491,293.263,325.252,287.182,322.586,282.345z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M174.999,92.899c-4.041-3.765-10.369-3.539-14.133,0.501l-0.222,0.239c-3.75,4.054-3.505,10.381,0.549,14.131
            c1.923,1.78,4.358,2.66,6.788,2.66c2.69,0,5.372-1.079,7.343-3.209l0.176-0.189C179.264,102.991,179.04,96.663,174.999,92.899z"
          />
        </g>
      </g>
    </svg>
  );
}
