// JsFromRoutes CacheKey cc20f34877a5a37e248c5693948e00b5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/offers_reward",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/offers_reward",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/offers_reward/new",
  ),
  edit: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/offers_reward/:id/edit",
  ),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/offers_reward/:id"),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/offers_reward/:id",
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/offers_reward/:id",
  ),
};
