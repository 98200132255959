import { adminV2Carousels } from "@api/index";
import { Form, SectionTitle, toast } from "@bleu.builders/ui";
import { SubmitButton } from "@components/SubmitButton";
import { client } from "@utils/api-client";
import { buildForm } from "@utils/buildForm";
import { serialize } from "object-to-formdata";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { json, useLoaderData, useSubmit } from "react-router-dom";

const loader = async ({ params }) => {
  const { program_id } = params;
  const data = await adminV2Carousels.new({ program_id });
  return json({ data });
};

const action = async ({ request, params }) => {
  const formData = await request.formData();
  const { program_id } = params;

  const path = adminV2Carousels.create.path({ program_id });
  const data = await client(path, {
    body: formData,
    method: "POST",
  });

  toast({
    title: data?.success ? "Success!" : "Error!!!",
    description: data?.success
      ? "Carousels updated successfully."
      : data?.errors,
    variant: data?.success ? "default" : "destructive",
  });

  return json({ success: true });
};

function ProgramCarouselsSetting() {
  // @ts-expect-error
  const { data } = useLoaderData();
  const submit = useSubmit();
  const [submittedItems, setSubmittedItems] = useState(new Set());

  function generateItemSignature(item) {
    return JSON.stringify(item);
  }

  const defaultValues = data.data.defaultValues;

  const form = useForm({
    mode: "all",
    defaultValues,
  });

  function processCarouselData(carousels) {
    return carousels.map((carousel) => {
      // include only new images (Program Attachment ID) in the submission
      if (typeof carousel.image === "number") {
        return carousel;
      } else {
        // exclude the image field if it's not a File object
        const { image, ...rest } = carousel;
        return rest;
      }
    });
  }

  async function onSubmit() {
    const isValid = await form.trigger();
    if (!isValid) return;

    // @ts-ignore
    const formValues = form.getValues().carousels;
    const values = processCarouselData(formValues);

    const newItems = values.filter((item) => {
      const signature = generateItemSignature(item);
      return !submittedItems.has(signature);
    });

    if (newItems.length === 0) {
      return;
    }

    const formData = serialize({ carousels: newItems });

    submit(formData, { method: "post", encType: "multipart/form-data" });

    newItems.forEach((item) => {
      const signature = generateItemSignature(item);
      submittedItems.add(signature);
    });

    setSubmittedItems(new Set(submittedItems));
  }

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Hero Carousels</SectionTitle>
          <p className="text-muted-foreground">
            Add large banner images for logged-in users
          </p>
        </div>
      </div>
      <div>
        <Form
          {...form}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8"
        >
          <div className="flex flex-wrap gap-5 p-2">
            {buildForm(data.data.fields, form)}
            <SubmitButton type="submit">Save</SubmitButton>
          </div>
        </Form>
      </div>
    </>
  );
}

ProgramCarouselsSetting.loader = loader;
ProgramCarouselsSetting.action = action;
export default ProgramCarouselsSetting;
