import { client } from "@utils/api-client";
import ReactOnRails from "react-on-rails";
import { assign, createMachine } from "xstate";

export const redeemStateMachine = createMachine(
  {
    id: "redeemFlow",
    initial: "unauthenticated",
    context: {
      participant: null,
      reward: null,
      offer: null,
      redemption: null,
      redemptionType: null,
      redemptionError: null,
    },
    states: {
      unauthenticated: {
        invoke: {
          src: "authenticateParticipant",
          onDone: {
            target: "unauthorized",
            actions: assign({ participant: (_, event) => event.data }),
          },
          onError: "participantAuthenticationPending",
        },
      },
      unauthorized: {
        /* render unauthorized component */
        invoke: {
          src: "checkParticipantEligibility",
          onDone: {
            target: "redeemConfirmationPending",
          },
          onError: "participantAuthorizationPending",
        },
      },
      redeemConfirmationPending: {
        on: {
          CLICK_REDEEM: "redeemInProgress",
          SELECT_REDEMPTION_TYPE: {
            actions: assign((context, event) => {
              return {
                ...context,
                // @ts-expect-error TS(2339) FIXME: Property 'redemptionType' does not exist on type '... Remove this comment to see the full error message
                redemptionType: event.redemptionType,
              };
            }),
          },
        },
      },
      participantAuthenticationPending: {
        /* render wantstoauth component */
      },
      participantAuthorizationPending: {
        /* render wantstobeamember component */
      },
      redeemInProgress: {
        invoke: {
          src: "redeemOffer",
          onDone: {
            target: "redeemSuccess",
            actions: [assign({ redemption: (_, event) => event.data })],
          },
          onError: {
            target: "redeemError",
            actions: [
              assign({ redemptionError: (_, event) => event.data }),
              // log((context, event) => {
              //   return event.data;
              // }),
            ],
          },
        },
      },
      redeemError: {
        /* render error component */
      },
      redeemSuccess: {
        /* render success message */
      },
    },
  },
  {
    services: {
      authenticateParticipant: (context, event) => {
        if (context.participant === null) {
          return Promise.reject(new Error("No participant"));
        } else {
          return Promise.resolve(context.participant);
        }
      },
      checkParticipantEligibility: (context, event) => {
        if (context.participant["eligible_offers_rewards?"] !== true) {
          return Promise.reject(new Error("Participant not authorized"));
        } else {
          return Promise.resolve(context.participant);
        }
      },
      redeemOffer: (context, event) => {
        const authenticity_token = ReactOnRails.authenticityToken();
        return client("/offers/redeem", {
          method: "POST",
          body: {
            endeca_key: context.offer["endecaKey"],
            redemption_type: context.redemptionType,
          },
          headers: {
            "X-CSRF-Token": authenticity_token,
          },
        }).catch(async (response) => {
          const errorBody = await response.json();
          throw errorBody;
        });
      },
    },
  },
);
