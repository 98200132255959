import { cn } from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import React, { useEffect, useRef, useState } from "react";

import { ChallengeData } from "../types";
import { CardContent } from "./components/CardContent";
import { CardHeaderContent } from "./components/CardHeader";
import { FooterButton } from "./components/FooterButton";

export function Article({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: () => void;
}) {
  const completedCondition = data?.completed_by_current_participant_on !== null;
  const contentRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const scrollPercentage =
          (scrollTop / (scrollHeight - clientHeight)) * 100;
        setProgress(scrollPercentage);
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards flex flex-col max-h-[80vh] md:max-h-[70vh]">
      <Card.Header className={cn("relative", completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
        {!completedCondition && (
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
            <div
              className="h-full bg-perk-primary transition-all duration-100 ease-out"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </Card.Header>
      <div ref={contentRef} className="grow overflow-y-auto">
        <CardContent data={data} completedCondition={completedCondition} />
        <Card.Footer className="flex justify-center mb-2">
          <FooterButton
            data={data}
            completedCondition={completedCondition}
            handleSubmit={handleSubmit}
          />
        </Card.Footer>
      </div>
    </Card.Root>
  );
}
