import { ClickToCopy } from "@bleu.builders/ui";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import { formatDate } from "@utils/formatDate";
import { truncateAddress } from "@utils/truncateAddress";
import React from "react";

export const DateElement = ({ date }) => <>{formatDate(date)}</>;

export const LinkElement = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className="flex flex-row items-center"
  >
    Go to link
    <ArrowTopRightIcon
      width={16}
      height={16}
      fontWeight={16}
      className="ml-1"
    />
  </a>
);

export const AddressElement = ({ address }) => (
  <ClickToCopy text={address}>{truncateAddress(address)}</ClickToCopy>
);

export const InfoList = ({ data }) => {
  return (
    <>
      {data.map(
        (item, idx) =>
          item.value && (
            <div key={idx} className="flex items-center gap-2 justify-between">
              <span className="text-sm font-medium text-foreground">
                {item.label}:
              </span>
              <h1 className="px-1 text-sm text-muted-foreground text-end">
                {item.element ? item.element : item.value}
              </h1>
            </div>
          ),
      )}
    </>
  );
};
