import Link from "@components/Link";
import { useProgram } from "@contexts/ProgramContext";
import React, { useEffect } from "react";

//@ts-ignore
import perkLogo from "../../../../assets/images/end-user/perk-social-logo.svg";

function ProgramPages({ programPages }) {
  return (
    <div className="flex gap-x-4 flex-wrap justify-center md:justify-start">
      {programPages.map((page) => (
        <Link
          key={page.id}
          to={`/${page.slug}`}
          className="text-perk-footer-text hover:text-perk-primary"
        >
          {page.title}
        </Link>
      ))}
    </div>
  );
}

export function Footer() {
  const program = useProgram();
  const currentYear = new Date().getFullYear();

  const footerFont =
    program?.program_template_detail?.element_css_style?.["footer_font"];

  return (
    <div
      className="bg-perk-footer-bg text-perk-footer-text flex flex-col sm:flex-row justify-between items-start p-4 md:px-28 xl:px-64 xl:py-6 text-lg"
      style={{
        fontFamily: footerFont ? `'${footerFont}', sans-serif` : "inherit",
      }}
    >
      <div className="w-full sm:w-fit">
        <ProgramPages programPages={program.published_program_pages} />
        <span className="flex justify-center md:justify-start">
          &#169; {currentYear} {program.name} All rights Reserved.
        </span>
      </div>
      <a
        href="https://getperk.studio/?utm_source=client-page&utm_medium=powered-footer&utm_campaign=perk-logo"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full sm:w-auto flex justify-center sm:justify-end"
      >
        <img
          src={perkLogo}
          alt="Perk Logo"
          className="w-24 sm:w-32 md:w-36 mt-2"
        />
      </a>
    </div>
  );
}
