import { cn } from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { DonationForm } from "../DonationBanner";
import { ChallengeData } from "../types";
import { CardHeaderContent } from "./components/CardHeader";

export function Donation({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: () => void;
}) {
  const completedCondition = data?.completed_by_current_participant_on !== null;
  const {
    donation_challenge: {
      active_donation_challenge_amount_options: amountOptions,
      active_donation_challenge_entity_options: entityOptions,
      entity_label,
    },
    caption,
    description,
    id,
  } = data;

  const program = useProgram();
  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards">
      <Card.Header className={cn(completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
      </Card.Header>
      <Card.Content>
        <DonationForm
          className="w-full min-w-full shadow-none"
          entities={entityOptions}
          amounts={amountOptions}
          challengeId={id}
          pointsLabel={program?.term_points_as}
          entityLabel={entity_label}
        />
      </Card.Content>
    </Card.Root>
  );
}
