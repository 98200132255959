import React from "react";

export function NftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
    >
      <path
        fill="#263238"
        d="M16,30.74c-0.44,0-0.87-0.12-1.25-0.34l-10.5-6.06C3.48,23.9,3,23.07,3,22.18V10.06C3,9.17,3.48,8.34,4.25,7.9
              l10.5-6.06c0.76-0.44,1.74-0.44,2.5,0l10.5,6.06C28.52,8.34,29,9.17,29,10.06v12.12c0,0.89-0.48,1.72-1.25,2.17l-10.5,6.06
              C16.87,30.63,16.44,30.74,16,30.74z M16,2.5c-0.26,0-0.52,0.07-0.75,0.2L4.75,8.76C4.29,9.03,4,9.53,4,10.06v12.12
              c0,0.53,0.29,1.03,0.75,1.3l10.5,6.06c0.46,0.26,1.04,0.26,1.5,0l10.5-6.06c0.46-0.27,0.75-0.77,0.75-1.3V10.06
              c0-0.53-0.29-1.03-0.75-1.3L16.75,2.7C16.52,2.57,16.26,2.5,16,2.5z"
      />
      <path
        fill="#263238"
        d="M23.5 23.12c-.28 0-.5-.22-.5-.5V9.82c0-.28.22-.5.5-.5S24 9.54 24 9.82v12.8C24 22.9 23.78 23.12 23.5 23.12zM19 17.12h-3.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5H19c.28 0 .5.22.5.5S19.28 17.12 19 17.12z"
      />
      <path
        fill="#263238"
        d="M26 11.62c-.08 0-.17-.02-.25-.07L21.28 9c-.24-.14-.32-.44-.19-.68.14-.24.44-.32.68-.19l4.47 2.55c.24.14.32.44.19.68C26.34 11.53 26.17 11.62 26 11.62zM15.5 27.62c-.28 0-.5-.22-.5-.5v-22c0-.18.09-.34.25-.43.15-.09.34-.09.5 0l4.67 2.67c.24.14.32.44.19.68-.14.24-.44.32-.68.19L16 5.98v21.14C16 27.4 15.78 27.62 15.5 27.62zM12.5 25.12c-.2 0-.38-.12-.46-.3L7 13.05v8.56c0 .28-.22.5-.5.5S6 21.9 6 21.62v-11c0-.24.17-.44.4-.49.23-.05.47.08.56.29L12 22.18V7.12c0-.28.22-.5.5-.5S13 6.84 13 7.12v17.5c0 .24-.17.44-.4.49C12.57 25.12 12.53 25.12 12.5 25.12z"
      />
    </svg>
  );
}
