import { adminV2Aptos } from "@api/all";
import { buildIndexPage } from "@lib/pageBuilder/pagesHelper";
import React from "react";
import { json, useLoaderData, useParams } from "react-router-dom";
import { IntegrationSettings } from "src/types/serializers";

import { IntegrationConnect } from "./(components)/IntegrationConnect";

interface AptosIntegrationSettings extends IntegrationSettings {
  network: string;
  wallet_address: string;
}

const loader = async ({ params }) => {
  const { program_id } = params;
  const integration = await adminV2Aptos.index({
    program_id: program_id,
  });

  return json(integration);
};

function IntegrationAptos() {
  const {
    id: configId,
    enabled,
    network,
    wallet_address,
    integratable_type,
    integratable_id,
  } = useLoaderData() as AptosIntegrationSettings;
  const { program_id } = useParams();

  const fields = [
    {
      name: "enabled",
      type: "switch",
      label: "Is this integration enabled?",
      required: true,
    },
    {
      name: "network",
      type: "select",
      label: "Network",
      options: [
        { label: "Mainnet", value: "mainnet" },
        { label: "Testnet", value: "testnet" },
      ],
      required: true,
      disabled: network === "mainnet",
    },
    {
      name: "wallet_address",
      type: "input",
      label: "Wallet Address",
      disabled: true,
    },
    {
      name: "_method",
      type: "hidden",
      value: "patch",
    },
    {
      name: "integratable_id",
      type: "hidden",
      value: integratable_type,
    },
    {
      name: "integratable_type",
      type: "hidden",
      value: integratable_id,
    },
    {
      name: "program_id",
      type: "hidden",
      value: program_id,
    },
  ];

  const { element: TransactionsTable } = buildIndexPage({
    modelName: "Aptos Blockchain Transactions",
    fetchPath: "/admin/v2/programs/:program_id/blockchain_transactions",
    searchKey: "txid",
    action: <></>,
  });

  return (
    <>
      <div className="mt-8 flex flex-col space-y-8">
        <IntegrationConnect
          defaultValues={{
            enabled: enabled,
            network: network,
            wallet_address: wallet_address,
          }}
          fields={fields}
          title={"Aptos"}
          action={{
            path: `/admin/v2/aptos/${configId}`,
          }}
        />
      </div>
      {TransactionsTable}
    </>
  );
}

IntegrationAptos.loader = loader;
export default IntegrationAptos;
