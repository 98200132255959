import { cn } from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import React from "react";

import { ChallengeData } from "../types";
import { CardContent } from "./components/CardContent";
import { CardHeaderContent } from "./components/CardHeader";
import { FooterButton } from "./components/FooterButton";

export function Link({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: () => void;
}) {
  const completedCondition = data?.completed_by_current_participant_on !== null;
  return (
    <Card.Root className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards">
      <Card.Header className={cn(completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
      </Card.Header>
      <CardContent data={data} completedCondition={completedCondition} />
      <Card.Footer className="flex justify-center mb-2">
        <FooterButton
          data={data}
          completedCondition={completedCondition}
          handleSubmit={handleSubmit}
        />
      </Card.Footer>
    </Card.Root>
  );
}
