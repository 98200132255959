import { Button } from "@bleu.builders/ui";
import Link from "@components/Link";
import { Cross1Icon } from "@radix-ui/react-icons";
import React from "react";

export function CloseButton() {
  return (
    <Link to="/">
      <Button className="bg-transparent shadow-none w-fit p-2 outline-perk-primary">
        <Cross1Icon className="size-3 text-black" />
      </Button>
    </Link>
  );
}
