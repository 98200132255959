import { useEffect, useState } from "react";

export const useMediaDuration = (videoRef, postType, isGallery) => {
  /*if the post is a gallery the time to the user score points will be 10s
    if the post is a image the time to the user score points will 5s
    if the post is a video the time to the user score points will be the 
    half of the video duration with a max of 30s.
  */
  const [timeToNext, setTimeToNext] = useState(
    isGallery ? 10 : postType === "video" ? null : 5,
  );
  const [isPlaying, setIsPlaying] = useState(false);

  if (isGallery || postType === "image") {
    return { timeToNext, isPlaying };
  }

  useEffect(() => {
    let intervalId;

    if (videoRef.current && postType === "video") {
      const video = videoRef.current;
      video.onloadedmetadata = () => {
        setTimeToNext(calculateSecondsToScorePoints(video.duration));
      };

      video.ontimeupdate = () => {
        const timeRemaining =
          calculateSecondsToScorePoints(video.duration) -
          Math.floor(video.currentTime);
        setTimeToNext(timeRemaining >= 0 ? timeRemaining : 0);
      };

      video.onpause = () => {
        setIsPlaying(false);
        clearInterval(intervalId);
      };

      video.onplay = () => {
        setIsPlaying(true);
        intervalId = setInterval(() => {
          const timeRemaining =
            calculateSecondsToScorePoints(video.duration) -
            Math.floor(video.currentTime);
          setTimeToNext(timeRemaining >= 0 ? timeRemaining : 0);
        }, 1000);
      };
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [videoRef.current, postType]);

  return { timeToNext, isPlaying };
};

const calculateSecondsToScorePoints = (time) => {
  return time > 60 ? 30 : Math.floor(time * 0.5);
};
