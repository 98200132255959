import { Toaster } from "@bleu.builders/ui";
import FlashModal from "@components/FlashModal";
import Dialog from "@components/ui/Dialog";
import EndUserRoutes from "@entrypoints/end-user";
import AdminV2Routes from "@pages/admin";
import DonationBanner from "@pages/programs/DonationBanner";
import DonationModal from "@pages/programs/DonationModal";
import MembershipBanner from "@pages/programs/MembershipBanner";
import MembershipModal from "@pages/programs/MembershipModal";
import StripeReceiptInfo from "@pages/programs/StripeReceiptInfo";
import ReactOnRails from "react-on-rails";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  // @ts-expect-error TS(2322) FIXME: Type '{ Root: React.ForwardRefExoticComponent<Reac... Remove this comment to see the full error message
  Dialog,
  DonationBanner,
  Toaster,
  EndUserRoutes,
  MembershipModal,
  DonationModal,
  MembershipBanner,
  StripeReceiptInfo,
  FlashModal,
  AdminV2Routes,
});
