import { buildForm, SectionTitle } from "@bleu.builders/ui";
import { use } from "i18next";
import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { BrandingOutletContextType } from "./(components)/Layout";

const fields = (fonts) => [
  {
    name: "element_css_style[header_font]",
    label: "Header font",
    description: "This is the header font",
    type: "searchable_select",
    options: fonts,
  },
  {
    name: "element_css_style[footer_font]",
    label: "Footer font",
    description: "This is the footer font",
    type: "searchable_select",
    options: fonts,
  },
];

function ProgramFontsSettingPage() {
  const { form, fonts } = useOutletContext<BrandingOutletContextType>();

  const fontOptions = fonts.map((font) => ({
    label: font.label,
    value: font.label,
  }));

  return (
    <div>
      <SectionTitle>Fonts</SectionTitle>
      <div className="grid gap-8">
        <div className="grid lg:grid-cols-2 gap-4">
          {/** @ts-ignore */}
          {fonts && buildForm(fields(fontOptions), form)}
        </div>
      </div>
    </div>
  );
}

export default ProgramFontsSettingPage;
