import React from "react";

import { ChallengeData } from "../../types";
import { CollectCard } from "./card";

export function QuestionnaireForm({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: (customData?: FormData) => void;
}) {
  const handleProfileQuestionOrSurveySubmit = async ({ form }) => {
    const isValid = await form.trigger();
    if (!isValid) return;
    const values = form.getValues();

    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      const questionId = key.split("_")[1];
      const question = data.questions.find(
        (q) => q.id.toString() === questionId,
      );

      if (question) {
        formData.append(`questions[${questionId}][id]`, questionId);
        const valueArray = Array.isArray(value)
          ? value
          : question.answer_type === "check_box"
            ? (value as string).split(",")
            : [value];

        valueArray.forEach((v) => {
          if (v) {
            formData.append(
              `questions[${questionId}][value][]`,
              v.toString().trim(),
            );
          }
        });
        formData.append(`questions[${questionId}][type]`, question.answer_type);
      }
    });

    handleSubmit(formData);
  };

  const completedCondition =
    data?.completed_by_current_participant_on !== null &&
    data.participant_answers.length > 0;

  return (
    <CollectCard
      data={data}
      completedCondition={completedCondition}
      handleSubmit={handleProfileQuestionOrSurveySubmit}
    />
  );
}
