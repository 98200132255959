import { buildForm } from "@utils/buildForm";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { BrandingOutletContextType } from "./(components)/Layout";

const FIELDS = [
  {
    name: "favicon_file",
    label: "Favicon",
    description: "This is the favicon",
    type: "program_attachment",
    required: true,
  },
  {
    name: "header_logo",
    label: "Logo",
    description: "This is the header logo",
    type: "program_attachment",
    required: true,
  },
  {
    name: "header_text",
    label: "Hero Title",
    description: "This is the hero title",
    type: "input",
    required: true,
  },
  {
    name: "header_description",
    label: "Hero Description",
    description: "This is the hero description",
    type: "input",
    required: true,
  },
  {
    name: "header_background_image",
    label: "Hero Background Image",
    description: "This is the hero background image",
    type: "program_attachment",
    required: true,
  },
];

export default function ProgramAssetsSettingPage() {
  const { form } = useOutletContext<BrandingOutletContextType>();

  return (
    <div>
      <h2 className="mb-0 pt-8 text-2xl font-bold tracking-tight">Assets</h2>
      <div className="grid lg:grid-cols-1 gap-4">
        {/** @ts-ignore */}
        {buildForm(FIELDS, form)}
      </div>
    </div>
  );
}
