import React from "react";

export function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
    >
      <path
        fill="#263238"
        d="M24.5,27h-3.34c-0.88,0-1.74-0.37-2.35-1.02c-0.57-0.6-0.85-1.37-0.81-2.17c0.05-0.74,0.36-1.43,0.87-1.94
              C19.43,21.32,20.21,21,21,21h2.5c0.28,0,0.5,0.22,0.5,0.5S23.78,22,23.5,22H21c-0.53,0-1.04,0.21-1.42,0.58
              c-0.34,0.34-0.55,0.8-0.58,1.29c-0.03,0.52,0.16,1.02,0.54,1.42c0.42,0.45,1.01,0.7,1.62,0.7h3.34c0.28,0,0.5-0.22,0.5-0.5v-4
              c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v4C26,26.33,25.33,27,24.5,27z"
      />
      <circle cx="21" cy="24" r="1" fill="#263238" />
      <path
        fill="#263238"
        d="M24 31H9c-1.1 0-2-.9-2-2V16.5C7 15.67 7.67 15 8.5 15h16c.28 0 .5.22.5.5S24.78 16 24.5 16h-16C8.22 16 8 16.22 8 16.5S8.22 17 8.5 17H24c1.1 0 2 .9 2 2v10C26 30.1 25.1 31 24 31zM8 17.91V29c0 .55.45 1 1 1h15c.55 0 1-.45 1-1V19c0-.55-.45-1-1-1H8.5C8.33 18 8.16 17.97 8 17.91zM5.52 20.96c-.02 0-.04 0-.06 0C2.87 20.63 1 18.54 1 16c0-2.53 1.83-4.67 4.3-5.08C4.99 10.1 4.83 9.23 4.83 8.34c0-1.75.63-3.44 1.76-4.77.18-.21.5-.23.71-.05s.23.5.05.71C6.37 5.37 5.83 6.83 5.83 8.34c0 .86.17 1.69.5 2.47l.14.34c.06.15.05.31-.03.45-.08.14-.22.23-.38.24L5.7 11.88C3.59 12.1 2 13.88 2 16c0 2.03 1.51 3.7 3.58 3.96.27.04.47.29.43.56C5.98 20.78 5.77 20.96 5.52 20.96z"
      />
      <path
        fill="#263238"
        d="M27.5 20.96c-.24 0-.46-.18-.49-.43-.04-.27.15-.53.42-.57 1.51-.22 2.57-1.4 2.57-2.87 0-1.66-1.3-3-2.95-3.05l-.39-.01c-.16-.01-.31-.09-.4-.22-.09-.14-.11-.31-.05-.46l.14-.36c.24-.62.37-1.26.37-1.91 0-2.89-2.35-5.25-5.25-5.25-.82 0-1.61.19-2.35.56l-.26.13c-.13.06-.27.07-.4.02-.13-.05-.24-.15-.29-.28l-.11-.28C17.08 3.57 14.78 2 12.17 2c-.6 0-1.21.09-1.78.25-.27.08-.54-.08-.62-.34C9.69 1.65 9.84 1.37 10.1 1.3 10.78 1.1 11.47 1 12.17 1c2.94 0 5.55 1.72 6.72 4.39.82-.37 1.69-.56 2.58-.56 3.44 0 6.25 2.8 6.25 6.25 0 .67-.11 1.34-.33 1.99C29.44 13.29 31 15 31 17.09c0 1.95-1.44 3.57-3.42 3.86C27.55 20.96 27.53 20.96 27.5 20.96zM8.4 3.07c-.16 0-.31-.08-.41-.22-.16-.23-.1-.54.13-.7l.1-.07c.23-.16.54-.1.7.13.16.23.1.54-.13.7l-.1.07C8.6 3.04 8.5 3.07 8.4 3.07z"
      />
    </svg>
  );
}
