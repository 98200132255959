import React from "react";
import { Link as RRDLink, type LinkProps } from "react-router-dom";

export default function Link({ to, children, ...props }: LinkProps) {
  return (
    <RRDLink to={to} unstable_viewTransition {...props}>
      {children}
    </RRDLink>
  );
}
