// JsFromRoutes CacheKey efa8122186d9c4ba3faa75e9b47b2819
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/profile_attributes",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/profile_attributes",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/profile_attributes/new",
  ),
  edit: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/profile_attributes/:id/edit",
  ),
  show: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/profile_attributes/:id",
  ),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/profile_attributes/:id",
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/profile_attributes/:id",
  ),
};
