// import PictureSelector from "@components/PictureSelector";
import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { SettingsCard } from "../(components)/SettingsCard";

export default function ProgramLabelSetting() {
  const program = useProgram();

  const fields = [
    {
      name: "term_points_as",
      type: "input",
      label: "Points are called",
      description:
        "You can create a custom label to define points, e.g., Points, Units, FunBucks, etc",
      placeholder: "Point name",
      value: program.term_points_as,
    },
    {
      name: "earn_points_icon",
      type: "program_attachment",
      label: "Earn Points Icon",
      description: "Earn Points will have the icon below.",
    },
    {
      name: "term_rewards_as",
      type: "input",
      label: "Rewards are called",
      description:
        "You can create a custom label to define rewards, e.g., Rewards, Prizes, etc",
      placeholder: "Rewards name",
      value: program.term_rewards_as,
    },
    {
      name: "current_rewards_icon",
      type: "program_attachment",
      label: "Current Reward Icon",
      description: "Current Rewards will have the icon below.",
    },
    {
      name: "my_rewards_icon",
      type: "program_attachment",
      label: "My Reward Icon",
      description: "Rewards earned by users will have the icon below.",
    },
    {
      name: "prizes_title",
      type: "input",
      label: "Prizes Title",
      description: "This is the title that will be shown for Rules.",
      required: false,
    },
    {
      name: "my_account_title",
      type: "input",
      label: "My Account Title",
      description: "This is the title that will be shown for My Account.",
      required: false,
    },
  ];

  return (
    <>
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={{
            ...program,
            earn_points_icon: program.earn_points_icon?.url,
            current_rewards_icon: program.current_rewards_icon?.url,
            my_rewards_icon: program.my_rewards_icon?.url,
          }}
          fields={fields}
          title={"Label Settings"}
          action={{ path: `/admin/v2/programs/${program.id}` }}
          encType={"multipart/form-data"}
          useFormSubmit
        />
      </div>
    </>
  );
}
