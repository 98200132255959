import { formatDate } from "@utils/formatDate";
import React from "react";

export function RewardDate({ earnedOn, endDate }) {
  return (
    <span className="text-end">
      {earnedOn
        ? `Earned on: ${formatDate(earnedOn)}`
        : `Expires on: ${formatDate(endDate)}`}
    </span>
  );
}
