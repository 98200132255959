import { buildForm as bleuUiBuildForm } from "@bleu.builders/ui";
import { DelegateField } from "@components/DependentField";
import { ImageMultiSelectCheckboxesField } from "@components/ImageMultiSelectCheckboxesField";
import { ImageRadioGroupField } from "@components/ImageRadioGroupField";
import { ProgramAttachmentUploadField } from "@components/ProgramAttachments/AttachmentUploadField";
import { QuestionsField } from "@components/QuestionsField";
import { WebhookEventField } from "@components/WebhookEventField";

const customFields = {
  program_attachment: ProgramAttachmentUploadField,
  questions: QuestionsField,
  delegate: DelegateField,
  webhook_event: WebhookEventField,
  image_radio: ImageRadioGroupField,
  image_checkbox: ImageMultiSelectCheckboxesField,
};

export const buildForm = (fields, form, index = 0) => {
  return bleuUiBuildForm(fields, form, index, customFields);
};
