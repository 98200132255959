import React from "react";
import Lottie from "react-lottie";

const LottieIcon = ({ iconData, width = 400, height = 400 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: iconData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
};

export default LottieIcon;
