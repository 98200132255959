import { FieldsLoaderData } from "@lib/pageBuilder/pagesHelper";
import { SettingsCard } from "@pages/admin/organization/programs/settings/(components)/SettingsCard";
import React from "react";
import { useLoaderData, useParams } from "react-router-dom";

function fieldTypeToForm(answerType) {
  switch (answerType) {
    case "text_area":
      return "textarea";
    case "boolean":
      return "switch";
    case "date":
      return "date";
    case "date_time":
      return "datetime";
    default:
      return "input";
  }
}

export function EditAttributeForm() {
  const { data } = useLoaderData() as FieldsLoaderData;
  const params = useParams();

  const fields = [
    {
      name: "attribute_display_name",
      type: "input",
      label: "Field Name",
      disabled: true,
    },
    {
      name: "value",
      type: fieldTypeToForm(data.defaultValues.attribute_field_type),
      label: "Value",
    },
  ];

  return (
    <div className="my-8 space-y-4">
      <SettingsCard
        defaultValues={data.defaultValues}
        fields={fields}
        title="Edit User Attribute"
        action={{
          path: `/admin/v2/participant_profile/${params.participant_profile_id}`,
        }}
      />
    </div>
  );
}
