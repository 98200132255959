import { SWRDataTable } from "@bleu.builders/ui";
import { DownloadButton } from "@components/DownloadButton";
import { useQueryAppendedUrl } from "@hooks/useQueryAppendedUrl";
import React from "react";
import { useParams } from "react-router-dom";

function RewardsUserTable() {
  const { reward_id } = useParams();

  const downloadUrl = useQueryAppendedUrl(
    `/admin/v2/rewards/${reward_id}/participants.csv`,
  );

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/rewards/${reward_id}/participants`}
      action={<DownloadButton url={downloadUrl} text="Export Table" />}
      defaultParams={{
        columnVisibility: {
          challenges: false,
          tags: false,
          rewards: false,
          gender: false,
        },
      }}
    />
  );
}

export default RewardsUserTable;
