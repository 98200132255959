import { FieldsLoaderData } from "@lib/pageBuilder/pagesHelper";
import { SettingsCard } from "@pages/admin/organization/programs/settings/(components)/SettingsCard";
import React from "react";
import { useLoaderData } from "react-router-dom";

export function NewAttributeForm() {
  const { data } = useLoaderData() as FieldsLoaderData;

  return (
    <div className="my-8 space-y-4">
      <SettingsCard
        defaultValues={data.defaultValues}
        fields={data.fields}
        title="Add Attributes"
        action={{
          path: `/admin/v2/participant_profile`,
        }}
      />
    </div>
  );
}
