import { adminV2Challenges, adminV2Programs } from "@api/index";
import { Button, TaggablePopover } from "@bleu.builders/ui";
import Link from "@components/Link";
import Card from "@components/ui/Card";
import { SimpleKpiCard } from "@components/ui/SimpleKpiCard";
import { useFormActionToast } from "@hooks/useFormActionToast";
import { useSendActionData } from "@hooks/useSendActionData";
import { formatDateTimeCompact } from "@utils/formatDate";
import { cn } from "@utils/mergeClassNames";
import React from "react";
import {
  json,
  Outlet,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const paths = ({ challenge_id }) => [
  {
    name: "Users",
    path: `/admin/v2/challenges/${challenge_id}`,
  },
  {
    name: "Participant Answers",
    path: `/admin/v2/challenges/${challenge_id}/participant_answers`,
  },
];

const info = ({ data }) => [
  {
    name:
      status.find((currentStatus) => currentStatus.value === data.status)
        .label ?? data.status,
    label: "Status",
  },
  {
    name: data.challengeType,
    label: "Type",
  },
  {
    name: data.points,
    label: "Earnable Points",
  },
  {
    name: data.start,
    label: "Start",
    type: "date",
  },
  {
    name: data.finish,
    label: "End",
    type: "date",
  },
];

const status = [
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ended",
    value: "ended",
  },
  {
    label: "Scheduled",
    value: "scheduled",
  },
];

const intents = {
  toggle_tag: adminV2Challenges.toggleTag,
};

const intentToastTitle = {
  toggle_tag: "Tag updated",
};

const loader = async ({ params }) => {
  const { challenge_id, program_id } = params;
  const data = await adminV2Challenges.show({ id: challenge_id });
  const tags = await adminV2Programs.tags({ id: program_id });

  return json({
    data,
    tags: tags.tags,
  });
};

const action = async ({ request }) => {
  const body = await request.json();

  if (!intents[body.intent]) {
    return json({
      success: false,
      message: "Invalid intent",
    });
  }

  const data = await intents[body.intent]({ data: { ...body.data } });

  return json({
    data,
    intent: body.intent,
    success: true,
  });
};

function ChallengeDetailsPage() {
  const { pathname } = useLocation();
  const { challenge_id } = useParams();
  const navigate = useNavigate();
  const actionData = useActionData();
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data, tags } = useLoaderData();
  const { submitWithIntent } = useSendActionData();

  useFormActionToast(actionData, intentToastTitle);

  const tagSubmission = async ({ tag }) => {
    await submitWithIntent("toggle_tag", { tag, id: challenge_id });
  };

  return (
    <div className="container mx-0 pt-5 pl-8">
      <div className="grid grid-cols-10 gap-10">
        <div className="col-span-3">
          <Card.Root className="max-w-none border-2 shadow-sm bg-background">
            <Card.Header className="mb-0 pb-0">
              <div className="border-b-1 flex w-full flex-col items-center justify-center">
                <img
                  src={data.image?.url}
                  alt=""
                  className="w-h-28 h-28 rounded-md object-cover"
                />
                <h1 className="mt-1 text-xl font-semibold text-foreground">
                  {data.name}
                </h1>
              </div>
              <Card.Description className="flex flex-col gap-1 py-2 text-sm">
                <Button
                  size="sm"
                  className="text-sm"
                  onClick={() => {
                    navigate(`/admin/v2/challenges/${challenge_id}/edit`);
                  }}
                >
                  Edit
                </Button>
              </Card.Description>
            </Card.Header>
            <div className="border-b dard:border-b-4" />
            <Card.Content className="mx-4 items-center">
              <div className="mt-2 px-6">
                {info({ data }).map(
                  (item, idx) =>
                    item.name && (
                      <div
                        key={idx}
                        className="flex items-center gap-2 justify-between"
                      >
                        <span className="text-sm font-medium text-foreground">
                          {item.label}:
                        </span>
                        <h1 className="px-1 text-sm text-muted-foreground text-end">
                          {item.type === "date"
                            ? formatDateTimeCompact(item.name)
                            : item.name}
                        </h1>
                      </div>
                    ),
                )}
              </div>
            </Card.Content>
            <Card.Footer className="mt-4 flex flex-row justify-center gap-1">
              <TaggablePopover
                tags={tags}
                selectedTags={data.tag_list}
                onSelect={tagSubmission}
              />
            </Card.Footer>
          </Card.Root>
        </div>
        <div className="col-span-7">
          <div className="flex gap-5">
            <SimpleKpiCard title="Total completions">
              {data.completions}
            </SimpleKpiCard>
            <SimpleKpiCard title="Click generated">
              {data.clicks ?? 0}
            </SimpleKpiCard>
            <SimpleKpiCard title="Points Earned By Users">
              {data.points * data.completions}
            </SimpleKpiCard>
          </div>
          <div className="mt-2 flex gap-3">
            {paths({ challenge_id }).map((item, idx) => (
              <Link
                key={idx}
                to={item.path}
                className={cn(
                  "text-muted-foreground px-2 py-1 text-sm font-medium",
                  {
                    "bg-accent border-2-primary rounded-md text-foreground":
                      pathname === item.path,
                  },
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="mr-10 mt-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

ChallengeDetailsPage.loader = loader;
ChallengeDetailsPage.action = action;
export default ChallengeDetailsPage;
