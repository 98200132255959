import { adminV2PhotoContest } from "@api/all";
import React from "react";
import { json, useLoaderData, useParams } from "react-router-dom";

import { IntegrationConnect } from "./(components)/IntegrationConnect";

const loader = async ({ params }) => {
  const { program_id } = params;
  const photoContest = await adminV2PhotoContest.index({
    program_id: program_id,
  });

  return json(photoContest);
};

function IntegrationPhotoContests() {
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { id: config_id, qrati_program_id, active } = useLoaderData();
  const { program_id } = useParams();

  const fields = [
    {
      name: "active",
      type: "switch",
      label: "Is this integration active?",
      required: true,
    },
    {
      name: "qrati_program_id",
      type: "input",
      label: "Program ID on Qrati",
      required: true,
    },
    {
      name: "_method",
      type: "hidden",
      value: "patch",
    },
    {
      name: "program_id",
      type: "hidden",
      value: program_id,
    },
  ];

  return (
    <>
      <div className="my-8 flex flex-col space-y-8">
        <IntegrationConnect
          defaultValues={{
            qrati_program_id,
            active,
          }}
          fields={fields}
          title={"Photo Contests"}
          action={{
            path: `/admin/v2/photo_contest/${config_id}`,
          }}
        />
      </div>
    </>
  );
}

IntegrationPhotoContests.loader = loader;
export default IntegrationPhotoContests;
