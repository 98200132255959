import ReactOnRails from "react-on-rails";

export const client = async (path, options = {}) => {
  const config = {
    // @ts-expect-error TS(2339) FIXME: Property 'body' does not exist on type '{}'.
    method: options.body ? "POST" : "GET",
    ...options,
    headers: new Headers({
      "X-CSRF-Token": ReactOnRails.authenticityToken(),
      // @ts-expect-error TS(2339) FIXME: Property 'headers' does not exist on type '{}'.
      ...options.headers,
    }),
  };
  // @ts-expect-error TS(2339) FIXME: Property 'body' does not exist on type '{}'.
  const body = config.body
    ? // @ts-expect-error TS(2339) FIXME: Property 'body' does not exist on type '{}'.
      config.body instanceof FormData
      ? // @ts-expect-error TS(2339) FIXME: Property 'body' does not exist on type '{}'.
        config.body
      : // @ts-expect-error TS(2339) FIXME: Property 'body' does not exist on type '{}'.
        JSON.stringify(config.body)
    : undefined;

  const response = await fetch(path, { ...config, body });

  return response.ok ? await response.json() : Promise.reject(response);
};
