import React from "react";

import { Reward } from "../types";
import NFTCertification from "./NftCertification";
import { RewardLayout } from "./RewardLayout";

export function AchievementReward({
  data,
  handleSubmit,
}: {
  data: { reward: Reward };
  handleSubmit: (customData?: FormData) => void;
}) {
  const { reward } = data;
  const earnedOn = reward.earned_by_current_participant_on;

  const renderAchievementImage = () => {
    if (earnedOn && reward?.selection === "achievement") {
      return (
        <div className="flex justify-center">
          <img
            src={reward?.achievement_reward?.active_image?.public_url}
            alt={reward.name}
            className="size-1/2"
          />
        </div>
      );
    }

    return (
      <div className="flex justify-center">
        <img
          src={reward?.image?.public_url}
          alt={reward.name}
          className="size-1/2"
        />
      </div>
    );
  };

  return (
    <RewardLayout reward={reward} renderImage={renderAchievementImage}>
      {reward?.selection === "achievement" && reward?.nft_item && (
        <NFTCertification nftItem={reward?.nft_item} />
      )}
    </RewardLayout>
  );
}
