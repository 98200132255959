/**
 * Extracts referral code from URL query parameters
 * @returns {string} Formatted referral code parameter or empty string
 */

import { useLocation } from "react-router-dom";

export function useReferralCode() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get("referral_code");

  return referralCode ? `?referral_code=${referralCode}` : "";
}
