import React, { createContext, useContext } from "react";

export const RailsFlashContext = createContext(null);

function parseFlashMessages(flashMessages) {
  return flashMessages.map(([type, message]) => ({ type, message }));
}

export const RailsFlashProvider = ({ children, messages }) => {
  const parsed_messages = parseFlashMessages(messages);

  return (
    <RailsFlashContext.Provider value={parsed_messages}>
      {children}
    </RailsFlashContext.Provider>
  );
};

export const useRailsFlash = () => {
  return useContext(RailsFlashContext);
};
