import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SubmitButton,
} from "@bleu.builders/ui";
import {
  ArrowRightIcon,
  Cross1Icon,
  EnvelopeClosedIcon,
  LockClosedIcon,
} from "@radix-ui/react-icons";
import React from "react";

export function EnterForm({
  program,
  form,
  handleSubmit,
  hideAuthTypeSelection = false,
}) {
  return (
    <div className="h-fit w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border rounded-lg shadow-sm p-6">
      <div className="flex w-full justify-end mb-4">
        <Button
          className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <Cross1Icon className="size-3" />
        </Button>
      </div>

      <h2 className="text-xl text-center mb-6">Welcome to {program?.name}!</h2>

      <Form {...form} onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="grid gap-6">
          {!hideAuthTypeSelection && (
            <FormField
              control={form.control}
              name="auth_type"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Choose how to sign in</FormLabel>
                  <FormControl>
                    <div className="grid grid-cols-2 gap-4">
                      <Button
                        type="button"
                        className={`flex items-center justify-center p-4 border rounded-lg transition-all ${
                          field.value === "magic_link"
                            ? "border-perk-primary bg-perk-primary/10"
                            : "border-gray-200 hover:border-perk-primary/50"
                        }`}
                        onClick={() => field.onChange("magic_link")}
                      >
                        <EnvelopeClosedIcon className="size-4 mr-2" />
                        <div className="text-sm">
                          <div className="font-medium">Magic Link</div>
                          <div className="text-xs text-gray-500">
                            Login via email
                          </div>
                        </div>
                      </Button>

                      <Button
                        type="button"
                        className={`flex items-center justify-center p-4 border rounded-lg transition-all ${
                          field.value === "otp"
                            ? "border-perk-primary bg-perk-primary/10"
                            : "border-gray-200 hover:border-perk-primary/50"
                        }`}
                        onClick={() => field.onChange("otp")}
                      >
                        <LockClosedIcon className="size-4 mr-2" />
                        <div className="text-sm">
                          <div className="font-medium">OTP Code</div>
                          <div className="text-xs text-gray-500">
                            Login with code
                          </div>
                        </div>
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Please enter your email to continue</FormLabel>
                <FormControl>
                  <Input
                    className="placeholder:text-gray-400 rounded-lg"
                    autoFocus
                    placeholder="your@email.com"
                    id="email"
                    type="email"
                    required
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />

          <SubmitButton
            type="submit"
            className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-lg w-full"
          >
            Continue to get your{" "}
            {form.watch("auth_type") === "otp" ? "login code" : "login link"}
            <ArrowRightIcon className="ml-2 size-3" />
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
}
