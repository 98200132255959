import React, { createContext, useContext } from "react";

export const UserContext = createContext(null);

export const UserProvider = ({ children, user, isAdmin, programs }) => {
  return (
    <UserContext.Provider value={{ user, isAdmin, programs }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
