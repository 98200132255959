// JsFromRoutes CacheKey bbcf7b407076ca71753aa50dff72e82f
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { Config } from "@js-from-routes/client";

const noop = (val) => val;
Config.deserializeData = noop;

import * as helpers from "./all";

export * from "./all";
export default helpers;
