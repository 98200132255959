import Link from "@components/Link";
import { ExternalLinkIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import React from "react";

import { BlockchainIcon } from "../assets/BlockchainIcon";
import { NftIcon } from "../assets/NftIcon";
import { WalletIcon } from "../assets/WalletIcon";

const NFTCertification = ({ nftItem }) => {
  if (!nftItem) return null;

  const truncateAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div className="flex flex-col justify-center items-center gap-y-3 p-4 bg-border rounded-lg">
      <div className="flex items-center gap-x-2">
        <InfoCircledIcon className="size-4" />
        <span>Certified on Blockchain</span>
      </div>

      <div className="flex flex-col md:flex-row gap-x-10 gap-y-4 md:gap-y-0">
        <div className="flex flex-col items-center gap-y-2">
          <div className="flex items-center gap-x-2">
            <BlockchainIcon />
            <span>View on Blockchain</span>
          </div>
          <Link
            to={nftItem.explorer_mint_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-x-2 text-black border rounded bg-gray-100 px-2 py-1 hover:bg-gray-300 transition-colors"
          >
            <span>See transaction</span>
            <ExternalLinkIcon className="size-4" />
          </Link>
        </div>

        <div className="flex flex-col items-center gap-y-2">
          <div className="flex items-center gap-x-2">
            <NftIcon />
            <span>NFT Token Address</span>
          </div>
          <Link
            to={nftItem.explorer_token_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-x-2 text-black border rounded bg-gray-100 px-2 py-1 hover:bg-gray-300 transition-colors"
          >
            <span>{truncateAddress(nftItem.address)}</span>
            <ExternalLinkIcon className="size-4" />
          </Link>
        </div>

        <div className="flex flex-col items-center gap-y-2">
          <div className="flex items-center gap-x-2">
            <WalletIcon />
            <span>Your Wallet Address</span>
          </div>
          <Link
            to={nftItem.owner_explorer_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-x-2 text-black border rounded bg-gray-100 px-2 py-1 hover:bg-gray-300 transition-colors"
          >
            <span>{truncateAddress(nftItem.owner_address)}</span>
            <ExternalLinkIcon className="size-4" />
          </Link>
        </div>
      </div>

      <div className="flex items-center justify-center gap-x-2 mt-4 w-full">
        <span>Powered by</span>
        <img
          src="https://www.perkstudios.com/wp-content/uploads/2024/02/Aptos_Primary_BLK.svg"
          alt="Aptos Logo"
          className="h-6"
        />
      </div>
    </div>
  );
};

export default NFTCertification;
