import { KpiCard } from "@bleu.builders/ui";
import * as React from "react";

export function SimpleKpiCard({
  title,
  children,
}: React.PropsWithChildren<{
  title: string;
}>) {
  return (
    <KpiCard className="p-3 md:p-4">
      <KpiCard.Header>
        <KpiCard.Title className="text-sm md:text-base">{title}</KpiCard.Title>
      </KpiCard.Header>
      <KpiCard.Content className="text-sm md:text-base">
        {children}
      </KpiCard.Content>
    </KpiCard>
  );
}
