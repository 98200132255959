import { ChallegeIcon } from "@assets/end-user/ChallengeIcon";
import { Button, capitalize } from "@bleu.builders/ui";
import Link from "@components/Link";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import { useReferralCode } from "@hooks/useReferralCode";
import { ClockIcon } from "@radix-ui/react-icons";
import { distanceOfTimeInWords } from "@utils/formatDate";
import React from "react";
import { useLoaderData } from "react-router-dom";

import { Challenge } from "../types";

export function ChallengeSection() {
  return (
    <div className="px-4 md:px-28 xl:px-64">
      <ChallengeList />
    </div>
  );
}

function ChallengeHeader() {
  const program = useProgram();
  const challengeIcon = program?.earn_points_icon?.public_url;

  return (
    <div className="flex justify-center my-5 gap-x-4 md:gap-x-12 items-center text-perk-primary-content px-4">
      <div>
        {challengeIcon ? (
          <img
            src={challengeIcon}
            alt="Earn points Icon"
            className="max-h-24"
          />
        ) : (
          <ChallegeIcon className="earn-points-icon" />
        )}
      </div>
      <div className="flex flex-col justify-start">
        <p className="text-2xl md:text-3xl earn-points">
          Earn {capitalize(program?.term_points_as)}
        </p>
        <p className="md:text-xl earn-points">
          The{" "}
          <span className="text-perk-primary font-semibold">more you earn</span>{" "}
          the more you can win!
        </p>
      </div>
    </div>
  );
}

const ChallengeList = () => {
  const { challenges } = useLoaderData() as { challenges: Challenge[] };
  if (challenges.length === 0) {
    return null;
  }
  return (
    <div className="justify-self-center current-challenge-list">
      <ChallengeHeader />
      <div className="hidden md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-16 mb-8">
        {challenges.map((challenge) => (
          <div key={challenge.id} className="flex justify-center">
            <ChallengeItem challenge={challenge} />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-8 mb-8 md:hidden">
        {challenges.map((challenge) => (
          <div key={challenge.id} className="w-full flex justify-center">
            <ChallengeItem challenge={challenge} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChallengeList;

function ChallengeItem({ challenge }: { challenge: Challenge }) {
  const challengeImage = challenge?.image?.public_url;
  const challengeIcon = challenge?.icon?.public_url;
  return (
    <div className="flex justify-center w-full">
      <div
        className="w-full md:w-80 rounded-perk-tiles overflow-hidden hover:shadow-lg challenge-tile"
        style={
          challengeImage
            ? {
                backgroundImage: `linear-gradient(to bottom, rgba(var(--perk-challenge-tile-bg) / 0.5), rgba(var(--perk-challenge-tile-bg) / 0.5)), url(${challengeImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
            : {
                backgroundColor: "rgb(var(--perk-challenge-tile-bg) / 0.5)",
              }
        }
      >
        <div className="flex flex-col p-6">
          <div className="flex justify-between items-center challenge-points">
            <span className="text-xl font-bold text-perk-primary-content">
              +{challenge?.points}
            </span>
            {challengeIcon && (
              <img
                src={challengeIcon}
                alt="Challenge Icon"
                className="size-8"
              />
            )}
          </div>
          <span className="text-2xl my-6 block text-perk-primary-content challenge-text">
            {challenge?.caption}
          </span>
          <div className="flex justify-between items-center flex-wrap gap-y-2">
            <CtaButton challenge={challenge} />
            <div className="text-xs md:text-base flex justify-end items-center gap-x-1 text-perk-primary-content">
              <ClockIcon className="size-4" />
              <p className="m-0">
                {distanceOfTimeInWords(new Date(), challenge.finish)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CtaButton({ challenge }: { challenge: Challenge }) {
  const referralCode = useReferralCode();
  const participant = useParticipant();

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  function getSignInUrl() {
    const baseUrl = `/signin`;
    const redirectParam = `redirect_to=/challenge/${challenge.identifier}`;
    return referralCode
      ? `${baseUrl}${referralCode}&${redirectParam}`
      : `${baseUrl}?${redirectParam}`;
  }

  function getChallengeUrl() {
    return challenge.bypass_modal
      ? challenge.link_url
      : `/challenge/${challenge.identifier}`;
  }

  if (challenge?.challenge_type === "membership") {
    if (!participant) {
      return (
        <Link to={getSignInUrl()}>
          <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button py-3 px-6 focus:outline-none">
            Become a member
          </Button>
        </Link>
      );
    }

    return (
      <form
        action={`/public/challenges/${challenge.id}/membership_checkout_session`}
        method="post"
      >
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <Button
          className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button py-3 px-6 focus:outline-none"
          type="submit"
        >
          Become a member
        </Button>
      </form>
    );
  }

  if (!participant) {
    return (
      <Link to={getSignInUrl()}>
        <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button py-3 px-6 focus:outline-none">
          {challenge?.cta_button_text}
        </Button>
      </Link>
    );
  }

  return (
    <Link to={getChallengeUrl()}>
      <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button py-3 px-6 focus:outline-none">
        {challenge?.cta_button_text}
      </Button>
    </Link>
  );
}
