import { buildForm, Button, Form } from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import Dialog from "@components/ui/Dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useSubmit } from "react-router-dom";

export function AddNewAdminForm({ description, fields }) {
  const form = useForm({});
  const submit = useSubmit();

  async function onSubmit() {
    const isValid = await form.trigger();
    if (!isValid) return;

    submit(
      { intent: "INVITE_ADMIN", data: { ...form.getValues() } },
      {
        method: "post",
        encType: "application/json",
      },
    );
  }

  return (
    <Card.Root className="w-full max-w-none bg-background">
      <Card.Header className="py-3">
        <Card.Title className="pl-2 text-xl">Invite Admin</Card.Title>
        <Card.Description className="py-0 text-sm">
          {description}
        </Card.Description>
      </Card.Header>
      <Card.Content className="mx-4">
        <Form
          {...form}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8"
        >
          <div className="flex flex-wrap gap-3 p-2">
            {buildForm(fields, form)}
          </div>
          <Button type="submit">Send Invite</Button>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}

export function AddAdminDialog({ description, fields, defaultOpen = true }) {
  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; defaultOpen: boolean;... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={defaultOpen}>
      <Dialog.Trigger asChild>
        <Button size="sm" className="ml-auto hidden h-8 lg:flex">
          <PlusIcon className="mr-2 size-4" />
          Invite Admin
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className="sm:max-w-[550px]">
        <AddNewAdminForm description={description} fields={fields} />
      </Dialog.Content>
    </Dialog.Root>
  );
}
