// JsFromRoutes CacheKey c1ca7bc6113d34f7ddcfd2b5019e1483
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  tags: /* #__PURE__ */ definePathHelper("get", "/admin/v2/programs/:id/tags"),
  fonts: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:id/fonts",
  ),
  updateTemplateDetails: /* #__PURE__ */ definePathHelper(
    "put",
    "/admin/v2/programs/:id/update_template_details",
  ),
  deactivateProgram: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:id/deactivate_program",
  ),
  index: /* #__PURE__ */ definePathHelper("get", "/admin/v2/programs"),
  create: /* #__PURE__ */ definePathHelper("post", "/admin/v2/programs"),
  new: /* #__PURE__ */ definePathHelper("get", "/admin/v2/programs/new"),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/programs/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/programs/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/programs/:id"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/admin/v2/programs/:id"),
};
