import {
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@bleu.builders/ui";
import React from "react";

import { EmailPreview } from "./EmailPreview";
import { ProgramPreview } from "./ProgramPreview";

const borders = {
  none: "rounded-none",
  small: "rounded-sm",
  medium: "rounded-md",
  large: "rounded-3xl",
  full: "rounded-full",
};

const fields = (fonts) => [
  {
    name: "element_css_style[header_font]",
    label: "Header font",
    description: "This is the header font",
    type: "searchable_select",
    options: fonts,
  },
  {
    name: "element_css_style[footer_font]",
    label: "Footer font",
    description: "This is the footer font",
    type: "searchable_select",
    options: fonts,
  },
];

export function Preview({ form, fonts }) {
  const [headerLogoPreview, setHeaderLogoPreview] = React.useState(null);
  const [heroBackgroundPreview, setHeaderBackgroundPreview] =
    React.useState(null);

  const primaryStyle = {
    backgroundColor: form.watch("element_css_style[perk-primary]"),
    color: form.watch("element_css_style[perk-primary-content]"),
  };

  const secondaryStyle = {
    backgroundColor: form.watch("element_css_style[perk-secondary]"),
    color: form.watch("element_css_style[perk-secondary-content]"),
  };

  const borderRadiusClass =
    borders[form.watch("element_css_style[perk-button-border-radius]")];
  const tileBorderRadiusClass =
    borders[form.watch("element_css_style[perk-tiles-border-radius]")] ||
    "rounded-none";
  const inputBorderRadiusClass =
    borders[form.watch("element_css_style[perk-input-border-radius]")];

  React.useEffect(() => {
    const headerLogo = form.watch("header_logo");
    const headerLogoUrl = form.watch("header_logo_url");

    if (typeof headerLogo === "string") {
      setHeaderLogoPreview(headerLogo);
    } else {
      headerLogoUrl && setHeaderLogoPreview(headerLogoUrl);
    }
  }, [form.watch("header_logo")]);

  React.useEffect(() => {
    const headerBackground = form.watch("header_background_image");
    const headerBackgroundUrl = form.watch("header_background_image_url");

    if (typeof headerBackground === "string") {
      setHeaderBackgroundPreview(headerBackground);
    } else {
      headerBackgroundUrl && setHeaderBackgroundPreview(headerBackgroundUrl);
    }
  }, [form.watch("header_background_image")]);

  const fontOptions = fields(fonts)?.[0]?.options || [];

  const getFontUrlByLabel = (label) => {
    return Array.isArray(fontOptions)
      ? fontOptions.find((opt) => opt.label === label)?.url
      : null;
  };

  const headerData = {
    logo: headerLogoPreview,
    font: {
      family: form.watch("element_css_style[header_font]"),
      url: getFontUrlByLabel(form.watch("element_css_style[header_font]")),
    },
    backgroundColor: form.watch("element_css_style[perk-header-color]"),
    fontColor: form.watch("element_css_style[perk-header-content-color]"),
  };

  const heroData = {
    background: heroBackgroundPreview,
    title: form.watch("header_text"),
    description: form.watch("header_description"),
    fontColor: form.watch("element_css_style[perk-header-title-color]"),
  };

  const footerData = {
    font: {
      family: form.watch("element_css_style[footer_font]"),
      url: getFontUrlByLabel(form.watch("element_css_style[footer_font]")),
    },
    backgroundColor: form.watch("element_css_style[perk-footer-color]"),
    fontColor: form.watch("element_css_style[perk-footer-content-color]"),
  };

  return (
    <div>
      <div className="pb-4">
        <h2 className="text-xl font-bold tracking-tight">Branding Preview</h2>
      </div>
      <TabsRoot defaultValue="program">
        <TabsList className="grid w-full grid-cols-2 rounded-sm">
          <TabsTrigger value="program">Program</TabsTrigger>
          <TabsTrigger value="email">Emails</TabsTrigger>
        </TabsList>
        <TabsContent value="program">
          <ProgramPreview
            primaryStyle={primaryStyle}
            secondaryStyle={secondaryStyle}
            borderRadiusClass={borderRadiusClass}
            tileBorderRadiusClass={tileBorderRadiusClass}
            inputBorderRadiusClass={inputBorderRadiusClass}
            headerData={headerData}
            heroData={heroData}
            footerData={footerData}
          />
        </TabsContent>
        <TabsContent value="email">
          <EmailPreview
            primaryStyle={primaryStyle}
            secondaryStyle={secondaryStyle}
            borderRadiusClass={borderRadiusClass}
            inputBorderRadiusClass={inputBorderRadiusClass}
            headerLogoPreview={headerLogoPreview}
            footerData={footerData}
          />
        </TabsContent>
      </TabsRoot>
    </div>
  );
}
