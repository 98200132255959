import Link from "@components/Link";
import { cn } from "@utils/mergeClassNames";
import React from "react";
import { useLocation } from "react-router-dom";

export const organizationSidebar = [
  {
    title: "Organization",
    items: [
      {
        title: "Programs",
        href: "/admin/v2/organization/programs",
        items: [],
      },
      {
        title: "Admins",
        href: "/admin/v2/organization/admins",
        items: [],
      },
    ],
  },
];

function SidebarNavItems({ items, pathname }) {
  return items?.length ? (
    <div className="grid grid-flow-row auto-rows-max text-sm">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          className={cn(
            "group flex w-full items-center rounded-md border border-transparent py-1 pr-2 hover:underline",
            item.disabled && "cursor-not-allowed opacity-60",
            pathname.includes(item.href)
              ? "text-foreground font-medium"
              : "text-muted-foreground",
          )}
        >
          {item.title}
          {item.label && (
            <span className="ml-2 rounded-md bg-[#adfa1d] px-1.5 py-0.5 text-xs leading-none text-[#000000] no-underline group-hover:no-underline">
              {item.label}
            </span>
          )}
        </Link>
      ))}
    </div>
  ) : null;
}

export function SidebarNav({ items }) {
  const pathname = useLocation().pathname;

  return items.length ? (
    <div className="size-full border-r">
      {items.map((item, index) => (
        <div key={index} className={cn("h-full pt-8 pl-8 mr-10")}>
          <h4 className="mb-1 rounded-md px-0 py-1 text-xl font-semibold text-foreground">
            {item.title}
          </h4>
          {item?.items?.length && (
            <SidebarNavItems items={item.items} pathname={pathname} />
          )}
        </div>
      ))}
    </div>
  ) : null;
}
