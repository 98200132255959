import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { SettingsCard } from "../(components)/SettingsCard";

export default function CustomCSS() {
  const program = useProgram();

  const fields = [
    {
      name: "css",
      type: "textarea",
      label: "Custom CSS",
      placeholder: ".custom-body {\n  background-color: #fffff;\n}",
      description:
        "Customize the look and feel of your program by adding custom CSS. These changes will be applied directly to the program, the preview won't show it.",
    },
  ];

  return (
    <>
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={program}
          fields={fields}
          title={"Custom CSS"}
          action={{ path: `/admin/v2/programs/${program.id}` }}
          useFormSubmit
        />
      </div>
    </>
  );
}
