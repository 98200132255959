import { adminV2MiniContest } from "@api/all";
import { Button, SWRDataTable } from "@bleu.builders/ui";
import { useFormActionToast } from "@hooks/useFormActionToast";
import { useSendActionData } from "@hooks/useSendActionData";
import React, { useState } from "react";
import { json, useActionData, useParams } from "react-router-dom";

const intents = {
  set_winners: adminV2MiniContest.setWinners,
};

const intentToastTitle = {
  set_winners: "Winner assigned",
};

const action = async ({ request }) => {
  const body = await request.json();

  if (!intents[body.intent]) {
    return json({
      success: false,
      message: "Invalid intent",
    });
  }

  const data = await intents[body.intent]({ data: { ...body.data } });
  location.reload();

  if (data?.data?.length === 0) {
    return json({
      success: false,
      message: "Failed to perform action",
    });
  }

  return json({
    data,
    intent: body.intent,
    success: true,
  });
};

function MiniContestUserTable() {
  const { reward_id } = useParams();
  const [selectedData, setSelectedData] = useState([]);
  const actionData = useActionData();
  const { submitWithIntent } = useSendActionData();

  useFormActionToast(actionData, intentToastTitle);

  function MiniContestActions() {
    const winnersSubmission = async () => {
      await submitWithIntent("set_winners", { selectedData, id: reward_id });
    };

    return (
      <div className="flex items-center gap-x-2">
        <Button onClick={winnersSubmission} size="sm">
          Assign Winners
        </Button>
      </div>
    );
  }

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/rewards/${reward_id}/mini_contest`}
      action={<MiniContestActions />}
      selectedRows={selectedData}
      setSelectedData={setSelectedData}
    />
  );
}

MiniContestUserTable.action = action;
export default MiniContestUserTable;
