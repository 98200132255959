import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { cn } from "@utils/mergeClassNames";
import React, {
  Children,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const isClient = () => {
  return typeof window !== "undefined";
};

const theme = {
  root: {
    base: "relative h-full w-full",
    leftControl:
      "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
    rightControl:
      "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none",
  },
  indicators: {
    active: {
      off: "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
      on: "bg-white dark:bg-gray-800",
    },
    base: "h-3 w-3 rounded-full",
    wrapper:
      "absolute bottom-3 lg:bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
  },
  item: {
    base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
    wrapper: "w-full flex-shrink-0 transform cursor-grab snap-center",
  },
  control: {
    base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-white/50 group-focus:outline-none group-focus:ring-1 bg-gray-800/30 group-hover:bg-gray-800/60 sm:h-10 sm:w-10 focus:outline-none",
    icon: "h-3 w-3 text-white dark:text-gray-800 sm:h-6 sm:w-6",
  },
  scrollContainer: {
    base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
    snap: "snap-x",
  },
};

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const CarouselContext = createContext();

export const useCarousel = () => {
  const context = useContext(CarouselContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a CarouselContext.Provider");
  }
  return context;
};

const carouselReducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_ITEM":
      return { ...state, activeItem: action.payload };
    case "SET_IS_DRAGGING":
      return { ...state, isDragging: action.payload };
    case "SET_IS_HOVERING":
      return { ...state, isHovering: action.payload };
    case "SET_ITEMS":
      return { ...state, items: action.payload };
    default:
      return state;
  }
};

const Root = React.forwardRef(
  (
    {
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type '{}'.
      children,
      // @ts-expect-error TS(2339) FIXME: Property 'onSlideChange' does not exist on type '{... Remove this comment to see the full error message
      onSlideChange = null,
      // @ts-expect-error TS(2339) FIXME: Property 'pauseOnHover' does not exist on type '{}... Remove this comment to see the full error message
      pauseOnHover = true,
      // @ts-expect-error TS(2339) FIXME: Property 'slide' does not exist on type '{}'.
      slide = true,
      // @ts-expect-error TS(2339) FIXME: Property 'slideInterval' does not exist on type '{... Remove this comment to see the full error message
      slideInterval = 3000,
      ...props
    },
    ref,
  ) => {
    const initialState = {
      activeItem: 0,
      isDragging: false,
      isHovering: false,
    };
    const [state, dispatch] = useReducer(carouselReducer, initialState);
    const carouselContainer = useRef(null);
    const setHovering = useCallback(
      (hovering) => dispatch({ type: "SET_IS_HOVERING", payload: hovering }),
      [dispatch],
    );
    const setActiveItem = useCallback(
      (item) => dispatch({ type: "SET_ACTIVE_ITEM", payload: item }),
      [dispatch],
    );

    const { activeItem, isDragging, isHovering, items } = state;
    const didMountRef = useRef(false);

    const navigateTo = useCallback(
      (item) => () => {
        if (!items) return;
        item = (item + items.length) % items.length;
        if (carouselContainer.current) {
          carouselContainer.current.scrollLeft =
            carouselContainer.current.clientWidth * item;
        }
        setActiveItem(item);
      },
      [items],
    );

    useEffect(() => {
      if (
        carouselContainer.current &&
        !isDragging &&
        carouselContainer.current.scrollLeft !== 0
      ) {
        setActiveItem(
          Math.round(
            carouselContainer.current.scrollLeft /
              carouselContainer.current.clientWidth,
          ),
        );
      }
    }, [isDragging]);

    useEffect(() => {
      if (slide && !(pauseOnHover && isHovering)) {
        const intervalId = setInterval(
          () => !isDragging && navigateTo(activeItem + 1)(),
          slideInterval,
        );

        return () => clearInterval(intervalId);
      }
    }, [
      activeItem,
      isDragging,
      navigateTo,
      slide,
      slideInterval,
      pauseOnHover,
      isHovering,
    ]);

    useEffect(() => {
      if (didMountRef.current) {
        onSlideChange && onSlideChange(activeItem);
      } else {
        didMountRef.current = true;
      }
    }, [onSlideChange, activeItem]);

    return (
      <div
        // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        className={cn(theme.root.base, className)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onTouchStart={() => setHovering(true)}
        onTouchEnd={() => setHovering(false)}
        {...props}
      >
        <CarouselContext.Provider
          value={{ state, dispatch, carouselContainer, navigateTo }}
        >
          {children}
        </CarouselContext.Provider>
      </div>
    );
  },
);
Root.displayName = "Root";

// @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
const Items = React.forwardRef(({ className, children, ...props }, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'state' does not exist on type '{}'.
  const { state, dispatch, carouselContainer } = useCarousel();
  const { activeItem, isDragging } = state;
  const handleDragging = (dragging) => () =>
    dispatch({ type: "SET_IS_DRAGGING", payload: dragging });

  const isDeviceMobile =
    isClient() && navigator.userAgent.indexOf("IEMobile") !== -1;

  const items = useMemo(
    () =>
      Children.map(children, (child) =>
        cloneElement(child, {
          className: cn(theme.item.base, child.props.className),
        }),
      ),
    [children, theme.item.base],
  );

  useEffect(() => {
    dispatch({ type: "SET_ITEMS", payload: items });
  }, [items]);

  return (
    <ScrollContainer
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      ref={ref}
      className={cn(
        theme.scrollContainer.base,
        (isDeviceMobile || !isDragging) && theme.scrollContainer.snap,
      )}
      draggingClassName="cursor-grab"
      innerRef={carouselContainer}
      onEndScroll={handleDragging(false)}
      onStartScroll={handleDragging(true)}
      vertical={false}
    >
      {items?.map((item, index) => (
        <div
          key={index}
          className={theme.item.wrapper}
          data-active={activeItem === index}
        >
          {item}
        </div>
      ))}
    </ScrollContainer>
  );
});
Items.displayName = "Items";

// @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
const Indicator = React.forwardRef(({ className, children, ...props }, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'state' does not exist on type '{}'.
  const { state, navigateTo } = useCarousel();
  const { activeItem, items } = state;

  return (
    // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    <div className={cn(theme.indicators.wrapper, className)} ref={ref}>
      {items?.map((_, index) => (
        <button
          key={index}
          className={cn(
            theme.indicators.base,
            theme.indicators.active[index === activeItem ? "on" : "off"],
          )}
          onClick={navigateTo(index)}
          aria-label={`Slide ${index + 1}`}
        />
      ))}
    </div>
  );
});
Indicator.displayName = "Indicator";

const Control = React.forwardRef(
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
  ({ className, role = "left", children, Icon = null, ...props }, ref) => {
    // @ts-expect-error TS(2339) FIXME: Property 'state' does not exist on type '{}'.
    const { state, navigateTo } = useCarousel();
    const { activeItem, items } = state;

    const next = role === "left" ? activeItem - 1 : activeItem + 1;

    const controlTheme =
      role === "left" ? theme.root.leftControl : theme.root.rightControl;
    const ControlIcon = Icon
      ? Icon
      : role === "left"
        ? CaretLeftIcon
        : CaretRightIcon;
    if (!items) return null;
    if (items.length === 1) return null;

    return (
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      <div ref={ref} className={cn(controlTheme, className)}>
        <button
          className="group focus:outline-none"
          onClick={navigateTo(next)}
          type="button"
          aria-label={`${role} slide`}
          {...props}
        >
          <span className={theme.control.base}>
            <ControlIcon className={theme.control.icon} />
          </span>
        </button>
      </div>
    );
  },
);
Control.displayName = "Control";

const Carousel = {
  Root,
  Items,
  Indicator,
  Control,
};

export default Carousel;
