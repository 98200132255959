import { toast } from "@bleu.builders/ui";
import { Spinner } from "@components/Spinner";
import { useParticipant } from "@contexts/ParticipantContext";
import { client } from "@utils/api-client";
import React, { useEffect, useRef, useState } from "react";
import {
  Await,
  defer,
  json,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";

import { EndUserLayout } from "../Layout";
import { ChallengeData } from "../types";
import { Article } from "./Article";
import { CallToAction } from "./CallToAction";
import { QuestionnaireForm } from "./collect/Questionnaire";
import { Quiz } from "./collect/Quiz";
import { Donation } from "./Donation";
import { Link } from "./Link";
import { Referral } from "./Referral";
import { SocialFeedPost } from "./SocialFeedPost";
import { Submitted } from "./Submitted";
import { Video } from "./Video";

interface ActionData {
  data: {
    success: boolean;
    message?: string;
  };
}

const action = async ({ request }) => {
  const formData = await request.formData();

  const data = await client("/public/challenges/submit", {
    body: formData,
    method: "POST",
  });

  return json({ data });
};

const loader = async ({ params }) => {
  const { challenge_id } = params;
  try {
    const [data] = await Promise.all([
      client(`/public/challenges/${challenge_id}`),
    ]);

    if (!data) {
      return redirect("/");
    }

    return defer({ data });
  } catch (error) {
    return redirect("/");
  }
};

const CHALLENGE_COMPONENTS = {
  call_action: CallToAction,
  link: Link,
  article: Article,
  video: Video,
  referral: Referral,
  collect_quiz: Quiz,
  collect_survey: QuestionnaireForm,
  collect_profile: QuestionnaireForm,
  unified_social: SocialFeedPost,
  donation: Donation,
} as const;

function ChallengeContent({
  data,
  handleSubmit,
  isSubmitted,
}: {
  data: ChallengeData;
  handleSubmit: (customData?: FormData) => void;
  isSubmitted: boolean;
}) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, []);

  if (isSubmitted && data.challenge_type !== "video") {
    return (
      <div className="z-[1450] w-full flex justify-center overflow-y-auto">
        <Submitted successMessage={data.success_message} />
      </div>
    );
  }

  const challengeName = (
    data.challenge_type === "collect" && data.parameters
      ? `${data.challenge_type}_${data.parameters}`
      : data.challenge_type
  ) as keyof typeof CHALLENGE_COMPONENTS;

  const ChallengeComponent = CHALLENGE_COMPONENTS[challengeName];

  if (!ChallengeComponent) {
    return null;
  }

  return (
    <div
      className="z-[1450] w-full flex justify-center overflow-y-auto focus:outline-none"
      tabIndex={-1}
      ref={contentRef}
    >
      <ChallengeComponent data={data} handleSubmit={handleSubmit} />
    </div>
  );
}

export default function ChallengePage() {
  const [loading, setLoading] = useState(true);
  const { data } = useLoaderData() as { data: ChallengeData };
  const actionData = useActionData() as ActionData;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const submit = useSubmit();
  const participant = useParticipant();

  useEffect(() => {
    if (actionData?.data?.success) {
      setIsSubmitted(true);
    } else if (!actionData?.data?.success && actionData?.data?.message) {
      toast({
        title: actionData?.data?.message || "Something went wrong",
        description: "Please try again",
        variant: "destructive",
      });
    }
  }, [actionData]);

  const handleSubmit = async (customData?: FormData) => {
    const formData = customData || new FormData();

    if (params && params.challenge_id) {
      formData.append("challenge_id", params.challenge_id);

      submit(formData, { method: "post", encType: "multipart/form-data" });
    }
  };

  useEffect(() => {
    if (!participant) {
      const path = `/signin?redirect_to=${location.pathname}${location.search}`;
      navigate(path);
    }
  }, [participant]);

  useEffect(() => {
    if (data?.status !== "active") {
      toast({
        title: "Challenge is not active",
        description: "This challenge is no longer available",
        variant: "destructive",
      });
      navigate("/");
    }
    if (
      data?.bypass_modal &&
      data?.link_url &&
      !data?.is_repeatable_and_completed
    ) {
      window.location.href = data.link_url;
    } else {
      setLoading(false);
    }
    if (data?.current_participant === null) {
      const path = `/signin?redirect_to=${location.pathname}${location.search}`;
      navigate(path);
    }
  }, [data, navigate]);

  function Loading() {
    return (
      <div className="flex justify-center items-center flex-col w-full">
        <Spinner />
        <p className="mt-4 text-lg">Loading, please wait...</p>
      </div>
    );
  }

  return (
    <div>
      {!loading && (
        <div className="fixed inset-0 z-[1400] bg-black/60 backdrop-blur-[1px] overflow-y-auto"></div>
      )}
      <EndUserLayout className="my-10">
        {loading ? (
          <Loading />
        ) : (
          <React.Suspense fallback={<Loading />}>
            <Await resolve={data} errorElement={<p>Error loading challenge</p>}>
              {(data) => (
                <ChallengeContent
                  data={data}
                  handleSubmit={handleSubmit}
                  isSubmitted={isSubmitted}
                />
              )}
            </Await>
          </React.Suspense>
        )}
      </EndUserLayout>
    </div>
  );
}

ChallengePage.loader = loader;
ChallengePage.action = action;
