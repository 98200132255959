import Button from "@components/ui/Button";
import { formatDate } from "@utils/formatDate";
import React from "react";

export default function MembershipModal({
  challenge,
  membership_challenge,
  completed,
}) {
  // TODO PERK-535: create form component
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  const headerContent = completed ? (
    <span className="text-xl font-light text-white">
      <i className="fa fa-check-circle mr-2 text-white" aria-hidden="true"></i>
      Completed on {formatDate(challenge.updated_at)}
    </span>
  ) : (
    <span className="text-xl font-light text-white">
      <i className="fa fa-clock-o mr-2 text-white" aria-hidden="true"></i>
      {challenge.finish && `Ends on ${formatDate(challenge.finish)}`}
    </span>
  );

  return (
    <div className="modal-dialog modal-lg">
      <div className="pointer-events-auto relative flex max-h-[calc(100vh_-_3.5rem)] w-full flex-col rounded-lg border border-solid bg-[#313131] bg-clip-padding">
        <div className="flex items-start justify-between rounded-t-[0.3rem] border-b-0 border-solid border-b-[#292a2b] p-4">
          {headerContent}
          <button
            className="float-right text-2xl font-bold leading-none text-white"
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            onClick={() => (window.location = "/")}
          >
            &times;
          </button>
        </div>
        <div className="max-h-[calc(100vh_-_210px)] overflow-y-auto pb-3">
          <div className="rounded-xl px-5 py-2 text-left font-light">
            <div
              className="text-perk-primary-content"
              id="image_link"
              dangerouslySetInnerHTML={{ __html: challenge.description }}
            />
            <style>{`#image_link img { width: 300px; }`}</style>
            <div className="row">
              <div className="flex w-full justify-center text-center">
                {completed && (
                  // @ts-expect-error TS(2322) FIXME: Type '{ children: string; onClick: () => string; }... Remove this comment to see the full error message
                  <Button onClick={() => (window.location = "/my-offers")}>
                    See my offers
                  </Button>
                )}
                {!completed && membership_challenge.price > 0 && (
                  <form
                    action={`/participants/challenges/${challenge.id}/membership_checkout_session`}
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={csrfToken}
                    />
                    {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; type: string; }' is not ... Remove this comment to see the full error message */}
                    <Button type="submit">Become a member</Button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
