import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { SettingsCard } from "../(components)/SettingsCard";

export default function SEOSetting() {
  const program = useProgram();

  const fields = [
    {
      name: "seo",
      type: "textarea",
      label: "Meta tags / SEO",
      placeholder:
        '<meta property="og:description" content="This is your share link description"/>',
      description:
        "Add any metatags you want to be displayed on your program's website.",
    },
  ];

  return (
    <>
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={program}
          fields={fields}
          title={"Meta tags"}
          action={{ path: `/admin/v2/programs/${program.id}` }}
          useFormSubmit
        />
      </div>
    </>
  );
}
