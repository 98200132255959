import { Button, ButtonProps } from "@bleu.builders/ui";
import React from "react";
import { useNavigation } from "react-router-dom";

export interface SubmitButtonProps extends ButtonProps {
  submittingText?: string;
  isSubmitting?: boolean;
}

export const SubmitButton = React.forwardRef<
  HTMLButtonElement,
  SubmitButtonProps
>(
  (
    {
      className,
      asChild = false,
      isSubmitting = false,
      submittingText = "Saving...",
      ...props
    },
    ref,
  ) => {
    const { state } = useNavigation();

    return (
      <Button
        ref={ref}
        {...props}
        className={className}
        loadingText={submittingText}
        loading={isSubmitting || state === "submitting"}
      />
    );
  },
);
