// JsFromRoutes CacheKey bb393643e817af91bbdabdc6ba84238a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  setWinners: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/set_mini_contest_winners",
  ),
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/rewards/:reward_id/mini_contest",
  ),
};
