import { Button, ButtonProps, cn } from "@bleu.builders/ui";
import { DownloadIcon } from "@radix-ui/react-icons";
import React from "react";

export interface DownloadButtonProps extends ButtonProps {
  url: string;
  text: string;
}

export const DownloadButton = React.forwardRef<
  HTMLButtonElement,
  DownloadButtonProps
>(({ className, asChild = false, url, text, ...props }, ref) => {
  return (
    <a
      // TODO: PERK-967 add filter/sorting params here in the future
      href={url}
      target="_blank"
      className="w-full"
    >
      <Button ref={ref} className={cn(className, "h-8")} {...props} size="sm">
        <DownloadIcon className="mr-2 size-4" />
        <span>{text}</span>
      </Button>
    </a>
  );
});
