import React from "react";

export function BlockchainIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
    >
      <path
        fill="#263238"
        d="M16 31c-.08 0-.17-.02-.24-.06l-2-1.11c-.16-.09-.26-.26-.26-.44v-2.45c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11c.16.09.26.26.26.44v2.45c0 .18-.1.35-.26.44l-2 1.11C16.17 30.98 16.08 31 16 31zM14.5 29.1l1.5.83 1.5-.83v-1.86L16 26.4l-1.5.83V29.1zM26.5 25.08c-.08 0-.17-.02-.24-.06l-2-1.11C24.1 23.82 24 23.65 24 23.47v-2.44c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11C28.9 20.68 29 20.85 29 21.03v2.44c0 .18-.1.35-.26.44l-2 1.11C26.67 25.06 26.58 25.08 26.5 25.08zM25 23.18l1.5.83 1.5-.83v-1.85l-1.5-.83L25 21.32V23.18zM5.5 25.08c-.08 0-.17-.02-.24-.06l-2-1.11C3.1 23.82 3 23.65 3 23.47v-2.44c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11C7.9 20.68 8 20.85 8 21.03v2.44c0 .18-.1.35-.26.44l-2 1.11C5.67 25.06 5.58 25.08 5.5 25.08zM4 23.18l1.5.83L7 23.18v-1.85l-1.5-.83L4 21.32V23.18zM16 6.67c-.08 0-.17-.02-.24-.06l-2-1.11C13.6 5.41 13.5 5.24 13.5 5.06V2.61c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11c.16.09.26.26.26.44v2.45c0 .18-.1.35-.26.44l-2 1.11C16.17 6.65 16.08 6.67 16 6.67zM14.5 4.77L16 5.6l1.5-.83V2.9L16 2.07 14.5 2.9V4.77zM26.5 12.08c-.08 0-.17-.02-.24-.06l-2-1.11C24.1 10.82 24 10.65 24 10.47V8.03c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11C28.9 7.68 29 7.85 29 8.03v2.44c0 .18-.1.35-.26.44l-2 1.11C26.67 12.06 26.58 12.08 26.5 12.08zM25 10.18l1.5.83 1.5-.83V8.32l-1.5-.83L25 8.32V10.18zM5.5 12.08c-.08 0-.17-.02-.24-.06l-2-1.11C3.1 10.82 3 10.65 3 10.47V8.03c0-.18.1-.35.26-.44l2-1.11c.15-.08.33-.08.49 0l2 1.11C7.9 7.68 8 7.85 8 8.03v2.44c0 .18-.1.35-.26.44l-2 1.11C5.67 12.06 5.58 12.08 5.5 12.08zM4 10.18l1.5.83L7 10.18V8.32L5.5 7.49 4 8.32V10.18z"
      />
      <path
        fill="#263238"
        d="M26.5 20.42c-.28 0-.5-.22-.5-.5v-8.34c0-.28.22-.5.5-.5s.5.22.5.5v8.34C27 20.2 26.78 20.42 26.5 20.42zM18 27.64c-.18 0-.35-.09-.44-.26-.13-.24-.05-.55.19-.68l6.55-3.64c.24-.13.55-.05.68.19.13.24.05.55-.19.68l-6.55 3.64C18.17 27.62 18.08 27.64 18 27.64zM14 27.64c-.08 0-.17-.02-.24-.06l-6.55-3.64c-.24-.13-.33-.44-.19-.68.13-.24.44-.33.68-.19l6.55 3.64c.24.13.33.44.19.68C14.35 27.55 14.18 27.64 14 27.64zM5.5 20.42c-.28 0-.5-.22-.5-.5v-8.34c0-.28.22-.5.5-.5S6 11.3 6 11.58v8.34C6 20.2 5.78 20.42 5.5 20.42zM7.5 8.97c-.18 0-.35-.09-.44-.26C6.93 8.47 7.02 8.17 7.26 8.04l6.5-3.61C14 4.29 14.3 4.38 14.44 4.62c.13.24.05.55-.19.68l-6.5 3.61C7.67 8.95 7.58 8.97 7.5 8.97zM24.5 8.97c-.08 0-.17-.02-.24-.06l-6.5-3.61c-.24-.13-.33-.44-.19-.68C17.7 4.38 18 4.29 18.24 4.42l6.5 3.61c.24.13.33.44.19.68C24.85 8.88 24.68 8.97 24.5 8.97zM20.5 13.3c-.08 0-.17-.02-.24-.06L16 10.87l-4.26 2.37c-.24.13-.55.05-.68-.19-.13-.24-.05-.55.19-.68l4.5-2.5c.15-.08.33-.08.49 0l4.5 2.5c.24.13.33.44.19.68C20.85 13.21 20.68 13.3 20.5 13.3z"
      />
      <path
        fill="#263238"
        d="M16,21.3c-0.18,0-0.35-0.09-0.44-0.26c-0.13-0.24-0.05-0.55,0.19-0.68L20,18.01v-4.36l-3.76,2.09
                c-0.24,0.13-0.55,0.05-0.68-0.19c-0.13-0.24-0.05-0.55,0.19-0.68l4.5-2.5c0.15-0.09,0.34-0.08,0.5,0.01S21,12.62,21,12.8v5.5
                c0,0.18-0.1,0.35-0.26,0.44l-4.5,2.5C16.17,21.28,16.08,21.3,16,21.3z"
      />
      <path
        fill="#263238"
        d="M16 21.3c-.08 0-.17-.02-.24-.06l-4.5-2.5C11.1 18.65 11 18.48 11 18.3v-5.5c0-.18.09-.34.25-.43.15-.09.34-.09.5-.01l4.5 2.5c.16.09.26.26.26.44v5.5c0 .18-.09.34-.25.43C16.18 21.28 16.09 21.3 16 21.3zM12 18.01l3.5 1.94v-4.36L12 13.65V18.01zM16 26.17c-.28 0-.5-.22-.5-.5V22.5c0-.28.22-.5.5-.5s.5.22.5.5v3.17C16.5 25.94 16.28 26.17 16 26.17z"
      />
      <g>
        <path
          fill="#263238"
          d="M22,12.53c-0.17,0-0.33-0.08-0.42-0.23c-0.15-0.23-0.08-0.54,0.16-0.69l2.46-1.55
                c0.23-0.15,0.54-0.08,0.69,0.16c0.15,0.23,0.08,0.54-0.16,0.69l-2.46,1.55C22.18,12.5,22.09,12.53,22,12.53z"
        />
      </g>
      <g>
        <path
          fill="#263238"
          d="M9.96,12.53c-0.09,0-0.18-0.03-0.27-0.08L7.23,10.9c-0.23-0.15-0.3-0.46-0.16-0.69
                c0.15-0.23,0.46-0.3,0.69-0.16l2.46,1.55c0.23,0.15,0.3,0.46,0.16,0.69C10.29,12.45,10.13,12.53,9.96,12.53z"
        />
      </g>
      <g>
        <path
          fill="#263238"
          d="M24.46,21.53c-0.09,0-0.18-0.03-0.27-0.08l-2.46-1.55c-0.23-0.15-0.3-0.46-0.16-0.69
                c0.15-0.23,0.46-0.3,0.69-0.16l2.46,1.55c0.23,0.15,0.3,0.46,0.16,0.69C24.79,21.45,24.63,21.53,24.46,21.53z"
        />
      </g>
      <g>
        <path
          fill="#263238"
          d="M7.5,21.53c-0.17,0-0.33-0.08-0.42-0.23C6.93,21.06,7,20.75,7.23,20.6l2.46-1.55
                c0.23-0.15,0.54-0.08,0.69,0.16c0.15,0.23,0.08,0.54-0.16,0.69l-2.46,1.55C7.68,21.5,7.59,21.53,7.5,21.53z"
        />
      </g>
      <g>
        <path
          fill="#263238"
          d="M16,9c-0.28,0-0.5-0.22-0.5-0.5v-2C15.5,6.22,15.72,6,16,6s0.5,0.22,0.5,0.5v2C16.5,8.78,16.28,9,16,9z"
        />
      </g>
    </svg>
  );
}
