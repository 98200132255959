import { WithSidebarLayout } from "@pages/admin/Layout";
import React from "react";
import { useParams } from "react-router-dom";

const layoutSettingsSidebar = (program_id) => {
  return [
    {
      title: "Layout Settings",
      items: [
        {
          title: "Header Navigation Menus",
          href: `/admin/v2/programs/${program_id}/settings/layout/navigation-menus`,
          items: [],
        },
        {
          title: "Carousels",
          href: `/admin/v2/programs/${program_id}/settings/layout/carousels`,
          items: [],
        },
      ],
    },
  ];
};

export function LayoutSettingsSidebar() {
  const { program_id } = useParams();

  const sidebarItems = layoutSettingsSidebar(program_id);

  return <WithSidebarLayout items={sidebarItems} />;
}
