import { adminV2Participants } from "@api/all";
import { SectionTitle, SWRDataTable, toast } from "@bleu.builders/ui";
import { DownloadButton } from "@components/DownloadButton";
import { useQueryAppendedUrl } from "@hooks/useQueryAppendedUrl";
import { client } from "@utils/api-client";
import React from "react";
import { json, useParams } from "react-router-dom";

import { ImportCsvDialog } from "./(components)/import_users";

const action = async ({ request, params }) => {
  const formData = await request.formData();
  const { program_id } = params;

  const path = adminV2Participants.importCsv.path({ program_id });
  const data = await client(path, {
    body: formData,
  });

  toast({
    title: data?.success ? "Success!" : "Error!!!",
    description: data?.success ? data.message : data?.errors,
    variant: data?.success ? "default" : "destructive",
  });

  if (data?.success) return json({ ok: true });

  return json({ ok: false });
};

const TableActions = ({ program_id }) => {
  const downloadUrl = useQueryAppendedUrl(
    `/admin/v2/programs/${program_id}/participants.csv`,
  );

  return (
    <div className="flex items-center gap-x-2">
      <ImportCsvDialog defaultOpen={false} />
      <DownloadButton url={downloadUrl} text="Export Table" />
    </div>
  );
};

function ParticipantsPage() {
  const { program_id } = useParams();

  return (
    <div className="mx-20 mb-10">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Users</SectionTitle>
          <p className="text-muted-foreground">
            Here&apos;s a list of the program users.
          </p>
        </div>
      </div>
      <SWRDataTable
        fetchPath={`/admin/v2/programs/${program_id}/participants`}
        hasDetails
        action={<TableActions program_id={program_id} />}
        defaultParams={{
          columnVisibility: {
            challenges: false,
            tags: false,
            rewards: false,
            gender: false,
          },
        }}
      />
    </div>
  );
}

ParticipantsPage.action = action;
export default ParticipantsPage;
