import React, { useEffect, useState } from "react";

function useFonts(fonts: { family: string; url: string }[]) {
  const [loading, setLoading] = useState(false);

  if (!fonts.length) return;

  const fontFaces = fonts
    .filter((font) => font.family)
    .map((font) => new FontFace(font.family, `url(${font.url})`));

  async function loadFontFace(fontFace) {
    const loadedFont = await fontFace.load();
    // @ts-expect-error
    document.fonts.add(loadedFont);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all(fontFaces.map(loadFontFace));
    fontFaces.forEach((fontFace) => {
      loadFontFace(fontFace);
    });
    setLoading(false);
  }, [fontFaces]);

  return { loading };
}

export function FontPreview({ font, label }) {
  useFonts([font]);

  return (
    <div>
      <p className="text-base font-bold" style={{ fontFamily: font.family }}>
        {label}
      </p>
    </div>
  );
}
