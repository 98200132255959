import React from "react";

import Dialog from "./ui/Dialog";

export default function FlashModal({ flash_messages, logo }) {
  const getColor = (type) => {
    switch (type) {
      case "success":
        return "text-green-900 border-green-300 bg-green-100";
      case "error":
        return "text-red-900 border-red-300 bg-red-100";
      case "alert":
        return "text-yellow-900 border-yellow-300 bg-yellow-100";
      default:
        return "text-blue-900 border-blue-300 bg-blue-100";
    }
  };

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; defaultOpen: boolean; }... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={flash_messages.length > 0}>
      <Dialog.Content className="bg-white">
        <div className="mt-2 flex flex-col gap-2">
          {flash_messages.map(([type, message], index) => (
            <div
              key={index}
              className={`mx-3 my-0.5 flex items-center rounded-md border-t-4 p-4 shadow-md ${getColor(
                type,
              )}`}
            >
              <svg
                className="size-4 shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ml-3 text-lg font-medium">{message}</div>
            </div>
          ))}
        </div>
        <div className="mx-2 mb-2 flex justify-center">
          {/* @ts-expect-error TS(2559) FIXME: Type '{ children: string; }' has no properties in ... Remove this comment to see the full error message */}
          <Dialog.CloseButton>OK</Dialog.CloseButton>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
