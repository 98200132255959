import { useProgram } from "@contexts/ProgramContext";
import React from "react";

import { SettingsCard } from "../(components)/SettingsCard";

export default function ProgramEnrollmentSetting() {
  const program = useProgram();

  const fields = [
    {
      name: "override_enrollment",
      type: "switch",
      label: "Override Enrollment",
      description:
        "Enable this to override the default enrollment flow for this program.",
    },
    {
      name: "program_custom_signin_url",
      type: "input",
      label: "Program Sign In URL",
      description:
        "The URL where participants will be redirected to sign in to the program.",
    },
  ];

  return (
    <>
      <div className="my-8 space-y-4">
        <SettingsCard
          defaultValues={program}
          fields={fields}
          title={"Enrollment Settings"}
          action={{ path: `/admin/v2/programs/${program.id}` }}
          useFormSubmit
        />
      </div>
    </>
  );
}
