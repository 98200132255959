// JsFromRoutes CacheKey fe0192ca79e62d5a893be8f042f97a4b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  enabled: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/aptos/:id/enabled",
  ),
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/aptos",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/aptos",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/aptos/new",
  ),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/aptos/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/aptos/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/aptos/:id"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/admin/v2/aptos/:id"),
};
