import { cn } from "@utils/mergeClassNames";
import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
} from "react";

const Button = forwardRef(function (
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
  { className = "", children, disabled = false, ...rest },
  ref,
) {
  let hasIconLeft = false;

  const modifiedChildren = Children.map(children, (child) => {
    if (isValidElement(child) && child.type === ButtonIcon) {
      const positionClass = hasIconLeft ? "ml-2" : "mr-2";
      hasIconLeft = true;

      return cloneElement(child, {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        className: positionClass,
      });
    }
    return child;
  });

  return (
    <button
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      {...rest}
      disabled={disabled}
      className={cn(
        "text-center focus-visible:outline-offset-2 disabled:opacity-40",
        { "transform transition-transform hover:scale-105": !disabled },
        "custom_btn",
        className,
      )}
    >
      {modifiedChildren}
    </button>
  );
});

export function ButtonIcon({ icon }) {
  return <span>{icon}</span>;
}

export default Button;
