// JsFromRoutes CacheKey 1e6ee3230dd8cdc97bdf7979d659f1c5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  programPerformanceMetrics: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/program_performance_metrics",
  ),
  topUsersAllTime: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/top_users_all_time",
  ),
  usersGrowth: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/users_growth",
  ),
  actions: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/actions",
  ),
  topUsersThisMonth: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/top_users_this_month",
  ),
  latestUsers: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/latest_users",
  ),
  latestActions: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/latest_actions",
  ),
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/dashboard",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/dashboard/new",
  ),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/dashboard/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/dashboard/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/dashboard/:id"),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/dashboard/:id",
  ),
};
