import Card from "@components/ui/Card";
import { WysiwygText } from "@components/WysiwygText";
import { useProgram } from "@contexts/ProgramContext";
import { formatDateTimeToNow } from "@utils/formatDate";
import React from "react";

import { ChallengeData } from "../../types";

export function CardContent({
  data,
  completedCondition,
  children,
}: {
  data: ChallengeData;
  completedCondition: boolean;
  children?: React.ReactNode;
}) {
  const emptyWysiwyg = "<p><br></p>";
  const program = useProgram();

  const award =
    data.reward_type === "points"
      ? `${data.points} ${program.term_points_as}`
      : `a reward`;
  const awardText = `Earn ${award} for completing this challenge.`;

  const hasAward =
    (data.reward_type === "points" && data.points > 0) ||
    data.reward_type === "prize";

  return (
    <>
      {completedCondition ? (
        <>
          <Card.Title className="pl-0 text-xl text-center">
            Good news, {data?.current_participant_name}!
          </Card.Title>
          <Card.Description className="text-base text-center">
            You successfully completed this{" "}
            {formatDateTimeToNow(data?.completed_by_current_participant_on)}
          </Card.Description>
        </>
      ) : (
        <Card.Content className="mx-4 pt-4">
          {hasAward && <p className="text-center font-bold">{awardText}</p>}
          <div className="flex flex-col gap-y-2">
            {data?.description != emptyWysiwyg && (
              <WysiwygText text={data.description} />
            )}

            {data?.content != emptyWysiwyg && (
              <WysiwygText text={data.content} />
            )}
            {children}
          </div>
        </Card.Content>
      )}
    </>
  );
}
