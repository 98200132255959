import { adminV2OffersReward } from "@api/all";
import React from "react";
import { json, useLoaderData, useParams } from "react-router-dom";

import { IntegrationConnect } from "./(components)/IntegrationConnect";

const loader = async ({ params }) => {
  const { program_id } = params;
  const offersRewards = await adminV2OffersReward.index({
    program_id: program_id,
  });

  return json(offersRewards);
};

function IntegrationOffersReward() {
  const { program_id } = useParams();
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { merchants_regex, active, redirect_magic_link } = useLoaderData();

  const fields = [
    {
      name: "active",
      type: "switch",
      label: "Is this integration active?",
      required: true,
    },
    {
      name: "redirect_magic_link",
      type: "switch",
      label: "Login with magic link redirect to My Offers",
      required: true,
    },
    {
      name: "merchants_regex",
      type: "input",
      label: "Regular expression to filter out merchants",
      placeholder: "\\btesting\\b",
      required: true,
    },
    {
      name: "_method",
      type: "hidden",
      value: "patch",
    },
    {
      name: "program_id",
      type: "hidden",
      value: program_id,
    },
  ];

  return (
    <>
      <div className="my-8 flex flex-col space-y-8">
        <IntegrationConnect
          defaultValues={{
            merchants_regex,
            active,
            redirect_magic_link,
          }}
          fields={fields}
          title={"Offers Reward"}
          action={{
            path: `/admin/v2/offers_reward/${program_id}`,
          }}
        />
      </div>
    </>
  );
}

IntegrationOffersReward.loader = loader;
export default IntegrationOffersReward;
