import toTitleCase from "@utils/toTitleCase";

const buttonName = {
  signup: "Sign up",
  video: "Watch Now",
  article: "Read Now",
  referral: "Recruit Friends",
  membership: "Become a Member",
  share: "Share",
  collect: "Take",
  link: "Visit Now",
  call_action: "Visit Now",
  engage: "Visit Social Media",
  unified_social: "Check out posts",
  donation: "Donate",
  location: "Visit",
};

export const getButtonText = (type, params) => {
  switch (type) {
    case "signup":
      return getSignupText(params);
    case "collect":
      return getCollectText(params);
    case "share":
      return getShareText(params);
    default:
      return buttonName[type];
  }
};

const getSignupText = (params) => {
  switch (params) {
    case "email":
      return "Signup Email";
    case null:
    case undefined:
      return "Signup";
    default:
      return `Join ${toTitleCase(params)}`;
  }
};

const getCollectText = (params) => {
  switch (params) {
    case "profile":
      return `Complete ${toTitleCase(params)}`;
    case null:
    case undefined:
      return "Take";
    default:
      return `Take ${toTitleCase(params)}`;
  }
};

const getShareText = (params) => {
  switch (params) {
    case "facebook":
      return "Share on Facebook";
    case "twitter":
      return "Share on Twitter";
    case null:
    case undefined:
      return "Share";
    default:
      return `Share on ${toTitleCase(params)}`;
  }
};
