import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
} from "@bleu.builders/ui";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { BrandingOutletContextType } from "./(components)/Layout";

export default function ProgramBrandingPage() {
  const { form } = useOutletContext<BrandingOutletContextType>();

  return (
    <div>
      <div className="pt-8">
        <h2 className="text-2xl font-bold tracking-tight">Theme</h2>
        <p className="text-muted-foreground">
          We will launch this feature soon. You'll be able to personalize the
          users experience with our Dark and Light Modes! Choose the theme that
          best fits your brand.
        </p>
      </div>
      <FormField
        control={form.control}
        // @ts-ignore
        name="element_css_style[theme]"
        render={({ field }) => (
          <FormItem className="space-y-1">
            <FormMessage />
            <input hidden {...field} value={field.value} />
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="grid max-w-md grid-cols-2 gap-8 pt-2"
            >
              <FormItem>
                <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                  <FormControl>
                    <RadioGroupItem value="light" className="sr-only" />
                  </FormControl>
                  <div className="border-muted hover:border-accent items-center rounded-md border-2 p-1">
                    <div className="space-y-2 rounded-sm bg-[#ecedef] p-2">
                      <div className="space-y-2 rounded-md bg-white p-2 shadow-sm">
                        <div className="h-2 w-[80px] rounded-lg bg-[#ecedef]" />
                        <div className="h-2 w-[100px] rounded-lg bg-[#ecedef]" />
                      </div>
                      <div className="flex items-center space-x-2 rounded-md bg-white p-2 shadow-sm">
                        <div className="size-4 rounded-full bg-[#ecedef]" />
                        <div className="h-2 w-[100px] rounded-lg bg-[#ecedef]" />
                      </div>
                      <div className="flex items-center space-x-2 rounded-md bg-white p-2 shadow-sm">
                        <div className="size-4 rounded-full bg-[#ecedef]" />
                        <div className="h-2 w-[100px] rounded-lg bg-[#ecedef]" />
                      </div>
                    </div>
                  </div>
                  <span className="block w-full p-2 text-center font-normal">
                    Light
                  </span>
                </FormLabel>
              </FormItem>
              <FormItem>
                <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                  <FormControl>
                    <RadioGroupItem value="dark" className="sr-only" />
                  </FormControl>
                  <div className="border-muted bg-popover hover:bg-accent hover:text-accent-foreground items-center rounded-md border-2 p-1">
                    <div className="space-y-2 rounded-sm bg-slate-950 p-2">
                      <div className="space-y-2 rounded-md bg-slate-800 p-2 shadow-sm">
                        <div className="h-2 w-[80px] rounded-lg bg-slate-400" />
                        <div className="h-2 w-[100px] rounded-lg bg-slate-400" />
                      </div>
                      <div className="flex items-center space-x-2 rounded-md bg-slate-800 p-2 shadow-sm">
                        <div className="size-4 rounded-full bg-slate-400" />
                        <div className="h-2 w-[100px] rounded-lg bg-slate-400" />
                      </div>
                      <div className="flex items-center space-x-2 rounded-md bg-slate-800 p-2 shadow-sm">
                        <div className="size-4 rounded-full bg-slate-400" />
                        <div className="h-2 w-[100px] rounded-lg bg-slate-400" />
                      </div>
                    </div>
                  </div>
                  <span className="block w-full p-2 text-center font-normal">
                    Dark
                  </span>
                </FormLabel>
              </FormItem>
            </RadioGroup>
          </FormItem>
        )}
      />
    </div>
  );
}
