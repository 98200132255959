import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

interface Options {
  keepId?: boolean;
}

export function getObjectsDifference<T, U>(
  obj1: T,
  obj2: U,
  options: Options = {},
): Partial<T & U> {
  const { keepId = true } = options;

  function changes(object, base) {
    return transform(object, (result, value, key) => {
      // Special handling for 'id' attribute
      if (key === "id" && keepId) {
        result[key] = value;
      } else if (isArray(value)) {
        // If it's an array, check if it's different from the corresponding value in the base
        if (!isEqual(value, base[key])) {
          // If it's different, assign the entire new array
          result[key] = value;
        }
      } else if (!isEqual(value, base[key])) {
        // For other attributes, perform the deep comparison
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(obj2, obj1);
}
