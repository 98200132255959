import React, { createContext, useContext } from "react";

export const FlipperContext = createContext(null);

export const FlipperProvider = ({ children, flipperFeatures }) => {
  return (
    <FlipperContext.Provider value={flipperFeatures}>
      {children}
    </FlipperContext.Provider>
  );
};

export const useFlipper = () => {
  return useContext(FlipperContext);
};
