import Card from "@components/ui/Card";
import React from "react";

import { ChallengeData } from "../../types";
import { CloseButton } from "./CloseButton";
import { TimeUntilEnd } from "./TimeUntilEnd";

export function CardHeaderContent({
  data,
  completedCondition,
}: {
  data: ChallengeData;
  completedCondition: boolean;
}) {
  return (
    <div className="grid grid-cols-3 items-center w-full">
      <div className="justify-self-start">
        {!completedCondition && <TimeUntilEnd data={data} />}
      </div>
      <div className="justify-self-center text-center">
        {!completedCondition && (
          <Card.Title className="pl-0 text-xl">{data?.caption}</Card.Title>
        )}
      </div>
      <div className="justify-self-end">
        <CloseButton />
      </div>
    </div>
  );
}
