import {
  Avatar,
  AvatarFallback,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Form,
} from "@bleu.builders/ui";
import Link from "@components/Link";
import { useUser } from "@contexts/UserContext";
import React from "react";
import { useForm } from "react-hook-form";

function abbreviateName(firstName, lastName = "") {
  if (!firstName) return "ME";
  const parts = (firstName + " " + lastName).split(" ");
  return `${parts[0].charAt(0)}${parts[1].charAt(0)}`.toUpperCase();
}

export function UserNav({ programUrl }: { programUrl?: string }) {
  const { user, isAdmin } = useUser();
  const form = useForm();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative size-8 rounded-full">
          <Avatar className="size-8">
            <AvatarFallback>
              {abbreviateName(user.first_name, user.last_name)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-40" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user.first_name}
            </p>
            <p className="text-muted-foreground text-xs leading-none">
              {user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {isAdmin && (
            <Link to={"/admin/v2/organization/programs"}>
              <DropdownMenuItem>Organization</DropdownMenuItem>
            </Link>
          )}
          {programUrl && (
            <a href={programUrl} target="blank" rel="noopener">
              <DropdownMenuItem>Go to Program Page</DropdownMenuItem>
            </a>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <Form action={"/admin/v2/auth/sign_out"} method="post" {...form}>
          <input type="hidden" name="_method" value="delete" />
          <button
            type="submit"
            className="hover:bg-accent hover:text-accent-foreground transition-color relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none"
          >
            Log out
          </button>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
