import { WithSidebarLayout } from "@pages/admin/Layout";
import React from "react";
import { useParams } from "react-router-dom";

const programSettingsSidebar = (program_id) => {
  return [
    {
      title: "Integrations",
      items: [
        {
          title: "Social Network Pages",
          href: `/admin/v2/programs/${program_id}/settings/integrations/social-network-pages`,
          items: [],
        },
        {
          title: "Offers Reward",
          href: `/admin/v2/programs/${program_id}/settings/integrations/offers-reward`,
          items: [],
        },
        {
          title: "Photo Contests",
          href: `/admin/v2/programs/${program_id}/settings/integrations/photo-contests`,
          items: [],
        },
        {
          title: "Aptos",
          href: `/admin/v2/programs/${program_id}/settings/integrations/aptos`,
          items: [],
        },
        {
          title: "API Auth",
          href: `/admin/v2/programs/${program_id}/settings/integrations/api_auths`,
          items: [],
        },
        {
          title: "Webhooks",
          href: `/admin/v2/programs/${program_id}/settings/integrations/webhooks`,
          items: [],
        },
      ],
    },
  ];
};

export function IntegrationsSidebar() {
  const { program_id } = useParams();

  const sidebarItems = programSettingsSidebar(program_id);

  return <WithSidebarLayout items={sidebarItems} />;
}
