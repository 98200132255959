import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { EndUserLayout } from "../Layout";

export function Photos() {
  const program = useProgram();
  const participant = useParticipant();
  const qrati_program_id = program?.qrati_config?.qrati_program_id;

  useEffect(() => {
    if (participant) {
      const usp = new URLSearchParams();
      usp.append("uid", participant.id);
      usp.append("fname", participant.first_name);
      usp.append("lname", participant.last_name);

      const iframe = document.querySelector(
        `.qio-${qrati_program_id}`,
      ) as HTMLIFrameElement;
      if (iframe) {
        iframe.src = `https://qrati.com/organization/${qrati_program_id}?${usp.toString()}`;
      }
    }
  }, [participant]);

  if (!participant) {
    return <Navigate to="/signin" replace />;
  }

  if (!program?.qrati_config) {
    return <Navigate to="/" replace />;
  }

  return (
    <EndUserLayout>
      <div className="w-full flex flex-col items-center h-screen">
        <iframe
          className={`qio-${qrati_program_id}`}
          style={{ border: "none" }}
          width="100%"
          height="100%"
          allow="clipboard-read; clipboard-write; web-share"
          title="Qrati Content"
        />
      </div>
    </EndUserLayout>
  );
}
