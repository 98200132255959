// JsFromRoutes CacheKey 2218f8392254c0be6f5f48b8c76b617d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/navigation_menus",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/navigation_menus",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/navigation_menus/new",
  ),
  edit: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/navigation_menus/:id/edit",
  ),
  show: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/navigation_menus/:id",
  ),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/navigation_menus/:id",
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/navigation_menus/:id",
  ),
};
