import React from "react";

import { EndUserLayout } from "./Layout";

export function ProgramPage({ page }) {
  return (
    <EndUserLayout className="my-10">
      <div
        className="mx-3 sm:mx-10 lg:mx-60"
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
    </EndUserLayout>
  );
}
