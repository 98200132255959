import { SubmitButton } from "@bleu.builders/ui";
import { useProgram } from "@contexts/ProgramContext";
import { formatDate } from "@utils/formatDate";
import React from "react";

import { Reward } from "../types";
import { RewardDate } from "./RewardDate";
import { RewardLayout } from "./RewardLayout";

export function RedeemReward({
  data,
  handleSubmit,
}: {
  data: { reward: Reward };
  handleSubmit: (customData?: FormData) => void;
}) {
  const { reward } = data;
  const program = useProgram();
  const earnedOn = reward.earned_by_current_participant_on;

  const renderFooter = () => (
    <div className="flex flex-col gap-y-2 w-full justify-between px-6 items-center mt-2 md:flex-row">
      {reward?.selection === "redeem" && !earnedOn && (
        <SubmitButton
          onClick={() => {
            handleSubmit();
          }}
          className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button w-fit py-3 px-6 focus:outline-none"
        >
          Use {reward?.threshold} {program.term_points_as} to Claim
        </SubmitButton>
      )}
      <div></div>
      <RewardDate earnedOn={earnedOn} endDate={reward.finish} />
    </div>
  );

  return <RewardLayout reward={reward} renderFooter={renderFooter} />;
}
