import { adminV2NavigationMenus } from "@api/index";
import { buildForm, Form, SectionTitle, toast } from "@bleu.builders/ui";
import { SubmitButton } from "@components/SubmitButton";
import React from "react";
import { useForm } from "react-hook-form";
import { json, useLoaderData, useSubmit } from "react-router-dom";

const loader = async ({ params }) => {
  const { program_id } = params;
  const data = await adminV2NavigationMenus.new({ program_id });
  return json({ data });
};

const action = async ({ request, params }) => {
  const { program_id } = params;
  const body = await request.json();

  const data = await adminV2NavigationMenus.create({
    params: { program_id },
    data: { ...body.data },
  });

  toast({
    title: data?.success ? "Success!" : "Error!!!",
    description: data?.success
      ? "Navigation menus updated successfully."
      : data?.errors,
    variant: data?.success ? "default" : "destructive",
  });

  return json({ success: true });
};

function ProgramHeaderSetting() {
  // @ts-expect-error
  const { data } = useLoaderData();
  const submit = useSubmit();
  const defaultValues = data.data.defaultValues;

  const form = useForm({
    mode: "all",
    defaultValues,
  });

  async function onSubmit() {
    const isValid = await form.trigger();
    if (!isValid) return;

    submit(
      { data: { ...form.getValues() } },
      {
        method: "post",
        encType: "application/json",
      },
    );
  }

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Header Navigation Menus</SectionTitle>
        </div>
      </div>
      <div>
        <Form
          {...form}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8"
        >
          <div className="flex flex-wrap gap-5 p-2">
            {buildForm(data.data.fields, form)}
            <SubmitButton type="submit">Save</SubmitButton>
          </div>
        </Form>
      </div>
    </>
  );
}

ProgramHeaderSetting.loader = loader;
ProgramHeaderSetting.action = action;
export default ProgramHeaderSetting;
