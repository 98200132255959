import React, { createContext, useContext } from "react";

export const ParticipantContext = createContext(null);

export const ParticipantProvider = ({ children, participant }) => {
  return (
    <ParticipantContext.Provider value={participant}>
      {children}
    </ParticipantContext.Provider>
  );
};

export const useParticipant = () => {
  return useContext(ParticipantContext);
};
