import { buildForm, Button, Form, SubmitButton } from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import Dialog, { useDialog } from "@components/ui/Dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import { serialize } from "object-to-formdata";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useActionData, useParams, useSubmit } from "react-router-dom";

const FIELDS = [
  {
    name: "email",
    label: "Email",
    type: "radio_item",
    options: [
      {
        label: "Replace user information if the email already exists.",
        value: "replace",
      },
      { label: "Ignore user if email already exists.", value: "ignore" },
    ],
    layout: "stack",
    required: true,
  },
  {
    name: "points",
    label: "Points",
    type: "radio_item",
    options: [
      { label: "Replace user points balance.", value: "replace" },
      { label: "Add points to user points balance.", value: "append" },
      {
        label: "Do not apply any change to user points balance.",
        value: "ignore",
      },
    ],
    layout: "stack",
    required: true,
  },
  {
    name: "tags",
    label: "Tags",
    type: "radio_item",
    tooltip: "Tags must be separated by comma e.g. tag1,tag2,tag3",
    layout: "stack",
    options: [
      { label: "Replace user tag list.", value: "replace" },
      { label: "Add tags to the user tag list.", value: "append" },
      { label: "Do not apply any change to user tag list.", value: "ignore" },
    ],
  },
  {
    name: "naming",
    label: "First and Last name",
    type: "radio_item",
    layout: "stack",
    options: [
      {
        label: "Replace first and last user name.",
        value: "replace",
      },
      { label: "Do not apply any change to user names.", value: "ignore" },
    ],
  },
  {
    name: "csv_file",
    label: "CSV File",
    tooltip: "The CSV column separator must be a comma",
    type: "file",
    style: {
      size: "small-tight",
    },
    accept: ".csv",
    required: true,
  },
];

export function ImportCsvForm() {
  const form = useForm({
    defaultValues: {
      email: "replace",
      points: "replace",
      tags: "replace",
      naming: "replace",
    },
  });
  const { program_id } = useParams();
  const submit = useSubmit();
  const [wasSubmitted, setWasSubmitted] = React.useState(false);
  const actionData = useActionData() as { ok: boolean } | null;
  // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type '{}'.
  const { close } = useDialog();

  useEffect(() => {
    if (actionData?.ok && wasSubmitted) {
      close();
      setWasSubmitted(false);
    }
  }, [actionData, close, wasSubmitted]);

  const handleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;
    const values = form.getValues();
    const formData = serialize(values);

    submit(formData, { method: "post", encType: "multipart/form-data" });
    setWasSubmitted(true);
  };

  return (
    <Card.Root className="max-w-none bg-background max-h-[740px] overflow-y-auto">
      <Card.Header>
        <Card.Title className="pl-2 text-xl">Import Users</Card.Title>
        <Card.Description className="py-0 text-sm">
          Import users to your Program by uploading a CSV file.
        </Card.Description>
      </Card.Header>
      <Card.Content className="mx-4 gap-10">
        <span className="text-red-600 text-sm">
          Here you will configure the strategy to import the participants.
          Please check our documentation for more information about the
          strategies and the CSV file format.
        </span>
        <Form className="flex flex-col gap-4 w-full mt-3" {...form}>
          <div className="grid grid-cols-2 gap-2">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message */}
            {buildForm(FIELDS, form)}
          </div>
          <span className="text-sm text-muted-foreground">
            Download an user{" "}
            <a
              href={`/admin/v2/programs/${program_id}/participants/import_csv_template.csv`}
              target="_blank"
              className="w-full text-blue-600"
            >
              CSV template
            </a>{" "}
            to see an example of the format required. The CSV column separator
            must be a comma.
          </span>
          <SubmitButton type="button" onClick={handleSubmit}>
            Import
          </SubmitButton>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}

export function ImportCsvDialog({ defaultOpen = true }) {
  return (
    //  @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={defaultOpen} className="max-w-none">
      <Dialog.Trigger asChild>
        <Button size="sm" className="ml-auto hidden h-8 lg:flex">
          <PlusIcon className="mr-2 size-4" />
          Import User Data
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className="sm:max-w-[1000px] max-w-none">
        <ImportCsvForm />
      </Dialog.Content>
    </Dialog.Root>
  );
}
