import { useProgram } from "@contexts/ProgramContext";
import { formatDate } from "@utils/formatDate";
import React from "react";

import { DonationForm } from "./DonationBanner";

export default function DonationModal({ challenge, completed }) {
  const {
    donation_challenge: {
      active_donation_challenge_amount_options: amountOptions,
      active_donation_challenge_entity_options: entityOptions,
      entity_label,
    },
    caption,
    description,
    id,
  } = challenge;

  const program = useProgram();

  const headerContent = completed ? (
    <span className="text-xl font-light text-white">
      <i className="fa fa-check-circle mr-2 text-white" aria-hidden="true"></i>
      Completed on {formatDate(challenge.updated_at)}
    </span>
  ) : (
    <span className="text-xl font-light text-white">
      <i className="fa fa-clock-o mr-2 text-white" aria-hidden="true"></i>
      {challenge.finish && `Ends on ${formatDate(challenge.finish)}`}
    </span>
  );

  return (
    <div className="modal-dialog modal-lg">
      <div className="pointer-events-auto relative flex max-h-[calc(100vh_-_3.5rem)] w-full flex-col rounded-lg bg-gray-800">
        <div className="flex items-start justify-between rounded-t-[0.3rem] p-4">
          {headerContent}
          <button
            className="float-right text-2xl font-bold leading-none text-white"
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            onClick={() => (window.location = "/")}
          >
            &times;
          </button>
        </div>
        <div className="max-h-[calc(100vh_-_210px)] overflow-y-auto pb-3">
          <div className="rounded-xl px-5 py-2 text-left font-light">
            <h2 className="text-perk-old-accent mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none">
              {caption}
            </h2>
            <div
              className="mb-4 max-w-xl text-base text-gray-400 md:text-lg"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <style>{`#image_link img { width: 300px; }`}</style>
            <div className="row">
              <div className="flex w-full justify-center text-center">
                <DonationForm
                  className="xl:w-10/12"
                  entities={entityOptions}
                  amounts={amountOptions}
                  challengeId={id}
                  pointsLabel={program?.term_points_as}
                  entityLabel={entity_label}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
