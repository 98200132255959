import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@bleu.builders/ui";
import Card from "@components/ui/Card";
import Dialog from "@components/ui/Dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const accountFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
});

export function AddNewProgramForm({ description }) {
  const form = useForm({
    resolver: zodResolver(accountFormSchema),
    defaultValues: {},
  });

  return (
    <Card.Root className="w-full max-w-none bg-background">
      <Card.Header>
        <Card.Title className="pl-2 text-xl">Create New Program</Card.Title>
        <Card.Description className="py-0 text-sm">
          {description}
        </Card.Description>
      </Card.Header>
      <Card.Content className="mx-4">
        <Form
          action={"/admin/v2/programs"}
          method="post"
          className="space-y-8"
          {...form}
        >
          <FormField
            control={form.control}
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Your name" {...field} />
                </FormControl>
                <FormDescription>
                  This is the name of your program. Will be visible to your
                  program users.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Create</Button>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}

export function AddNewProgramDialog({ description, defaultOpen = true }) {
  return (
    //  @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={defaultOpen}>
      <Dialog.Trigger asChild>
        <Button size="sm" className="ml-auto hidden h-8 lg:flex">
          <PlusIcon className="mr-2 size-4" />
          Add Program
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className="sm:max-w-[550px]">
        <AddNewProgramForm description={description} />
      </Dialog.Content>
    </Dialog.Root>
  );
}
